.hui-illustrator-modal-root {
  .modal-body {
    display: flex;
    flex-direction: column;

    .hui-illustrator-modal-actionable {
      text-align: center;
      margin-top: 20px;
    }

    .hui-illustrator-modal-img {
      margin-top: auto;
      align-self: center;
    }
  }
}

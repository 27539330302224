@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.gated-view-root {
  .gated-view-heading {
    @include typography.hui-heading4;
    padding-top: $pg-grid-space-unit-2x;
    padding-bottom: $pg-grid-space-unit-4x;
    margin: 0;
    @include media-breakpoint-down(sm) {
      padding-bottom: 20px;
    }
  }

  .action-strip {
    justify-content: center;
    align-items: center;
    @include typography.hui-body-default-bold;

    .btn-primary {
      color: var(--pg-white);
    }

    .btn-outline-secondary {
      color: var(--pg-grey-darker);
      border: 1px solid var(--pg-grey-darker);
    }

    > * {
      margin-right: 15px;
    }
  }
}

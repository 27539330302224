.password-login-view-root {
  .login-with-otp-btn {
    align-self: center;
    margin-top: 20px;
  }

  .divider-root {
    margin-top: 20px;
  }

  .generic-input-group-root {
    margin-top: 15px;
  }

  .forgot-password-btn {
    margin-top: 15px;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.search-panel-root,
.search-fullscreen-root {
  .typeahead {
    height: 100%;
    border: 0;
    input.rbt-input {
      @include typography.hui-body-default-regular;
      padding: 0;
      border-radius: 0;
      transition: border-radius 0.15s;
    }

    &:not(.typehead--no-result) {
      .rbt-input.rbt-input-main.form-control[aria-expanded='true']:not(.ai-enabled) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .rbt-input-hint {
      @include typography.hui-body-default-regular;
    }
  }

  .search-panel-group-text .btn {
    display: flex;
    align-items: center;
  }
}

.search-panel-root {
  flex-grow: 1;
  margin-left: unset;
  .input-group {
    .search-panel-dropdown-filter {
      position: relative;

      &-label {
        display: flex;
        gap: $pg-grid-space-unit;
        justify-content: center;
      }

      .btn-search-panel-dropdown {
        border: 0;
      }

      .dropdown-toggle {
        @include typography.hui-body-default-regular;
        border-radius: $pg-grid-space-unit-4x 0 0 $pg-grid-space-unit-4x;
        color: var(--pg-black);
      }

      .dropdown-menu {
        min-width: auto;
        width: 100%;
        /**
         * on desktop bootstrap adds additional spacing between input and dropdown menu.
         * Adding negative margin to remove spacing
         */
        margin-top: -1.6px;
        .dropdown-item {
          @include typography.hui-body-default-regular;
          padding: $pg-grid-space-unit-4x;
          margin-top: unset;
          width: auto;
          color: var(--pg-black);
        }
      }
    }
  }

  .search-input-root .input-group {
    overflow: visible;
    .input-group-text {
      margin-left: 0 !important;
    }

    .form-control {
      border-radius: $pg-grid-space-unit-4x;
      padding: 0;

      .rbt-input {
        padding: $pg-grid-space-unit-4x $pg-grid-space-unit-10x $pg-grid-space-unit-4x $pg-grid-space-unit-4x;
      }
    }

    .close-icon {
      height: 24px;
      width: 24px;
      padding: 0;
      position: absolute;
      right: $pg-grid-space-unit-4x;
      top: calc(50% - 14px);
      cursor: pointer;

      &::before {
        margin: 0;
      }
    }
  }

  .rbt-menu {
    width: 100%;
    left: 0;
    top: 100%;
  }

  .dropdown-menu {
    border-radius: 0 0 8px 8px;
  }

  &.vertical {
    padding: 0 $pg-grid-space-unit-3x;

    .input-group {
      height: 54px;
      border: none;

      > .form-control {
        @include typography.hui-body-default-regular;
      }

      ::placeholder {
        color: var(--pg-grey-dark);
      }

      .input-group-text .btn {
        border-radius: 0 $pg-grid-space-unit-4x $pg-grid-space-unit-4x 0;
      }

      button {
        height: 54px;
        padding: $pg-grid-space-unit-4x $pg-grid-space-unit-5x;
        .text {
          display: none;
        }
        .pgicon-search {
          display: block;
          padding: 0;
          font-size: 24px;
        }
      }

      > .form-control:focus {
        z-index: unset;
      }

      .form-control:disabled,
      .form-control[readonly] {
        background-color: var(--pg-white);
      }

      .search-panel-dropdown-filter {
        .dropdown-toggle {
          min-width: 95px;
          padding: $pg-grid-space-unit-4x $pg-grid-space-unit-2x;

          &.show {
            border-bottom-left-radius: 0;
          }
        }
        .btn-search-panel-dropdown {
          border-right: 1px solid var(--pg-blue-lighter);
          background: var(--pg-blue-lightest);
          color: var(--pg-black);

          &:active {
            border-right: 1px solid var(--pg-blue-lighter);
            background: var(--pg-blue-lightest);
            color: var(--pg-black);
          }
        }
      }
    }
  }

  &.horizontal {
    max-width: 325px;
    margin-top: auto;
    margin-right: 9px;
    .input-group {
      height: 36px;

      > .form-control {
        @include typography.hui-body-default-regular;
        border-radius: inherit;

        .rbt-input {
          padding-top: $pg-grid-space-unit-2x;
          padding-bottom: $pg-grid-space-unit-2x;
          border-radius: $pg-grid-space-unit-2x;
        }
      }

      ::placeholder {
        color: var(--pg-grey-dark);
      }

      .form-control:disabled,
      .form-control[readonly] {
        background-color: var(--pg-white);
      }

      .search-panel-dropdown-filter {
        &-label {
          gap: $pg-grid-space-unit-2x;
        }

        .btn-search-panel-dropdown {
          border-right: 1px solid var(--pg-grey-medium);
          background: var(--pg-grey-lighter);
          color: var(--pg-grey-darker);
          padding: var(--pg-grid-space-unit-2x) var(--pg-grid-space-unit-5x);
          height: 34px;

          &:active {
            border-right: 1px solid var(--pg-grey-medium);
            background: var(--pg-grey-lighter);
            color: var(--pg-grey-darker);
          }
        }

        & + .rbt.form-control {
          .rbt-input {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .search-panel-root {
    &.vertical {
      padding: 0;

      .input-group {
        button {
          .text {
            display: block;
          }
          .pgicon-search {
            display: none;
          }
        }
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.error-card-root {
  background-color: var(--fill-neutral-secondary);

  .error-card-title {
    @include typography.hui-heading4;
    color: var(--text-active-primary);
    font-weight: $pg-font-weight-experiment-heading;
    margin-top: var(--pg-grid-space-unit-5x);
    margin-bottom: var(--pg-grid-space-unit-2x);
  }

  .error-card-body {
    @include typography.hui-body-default-regular;
    font-family: var(--pg-font-body-bold);
    color: var(--text-active-secondary);
    margin-bottom: var(--pg-grid-space-unit-5x);
  }
}

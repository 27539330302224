@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@use 'root/styles/foundation/common/navbar.scss' as navbar;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.hui-nav-tabs-root {
  flex-wrap: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &.navbar {
    padding: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    margin-right: $pg-grid-space-unit-4x;
  }

  .nav-link:hover,
  .nav-link:focus {
    --bs-nav-link-hover-color: var(--text-inactive-primary);
  }

  .nav-list-item {
    @include navbar.underline-on-hover;
    @include typographyV3.pg-font-label-m;
    white-space: nowrap;
    padding: $pg-grid-space-unit-2x 0;
    position: relative;
    display: flex;
    gap: $pg-grid-space-unit;
    align-items: center;
    color: var(--text-inactive-primary);

    &::before {
      border-bottom: none;
    }

    & .pgicon {
      color: var(--pg-grey-medium);

      &::before {
        margin: 0;
      }

      &.svg-icon-wrapper {
        width: 14px;
      }
    }

    & span {
      color: var(--pg-grey-dark);
    }

    &.active {
      color: var(--text-active-primary);

      &:before {
        width: 100%;
        overflow: hidden;
        border-bottom: 3px solid var(--pg-brand-primary);
        border-top-right-radius: 100px;
        border-top-left-radius: 100px;
      }
    }
    @include media-breakpoint-up(lg) {
      &.nav-list-item {
        &:hover {
          color: var(--text-active-primary);

          &::before {
            border-bottom: 3px solid var(--pg-brand-primary);
            border-top-right-radius: 100px;
            border-top-left-radius: 100px;
          }
        }
      }
    }
  }
}

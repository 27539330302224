@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.search-fullscreen-root {
  .modal-dialog {
    &.modal-fullscreen {
      width: 100%;
    }
  }
  .rbt-menu {
    display: none !important;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding: $pg-grid-space-unit-3x;

    .search-accessory-root {
      border-top: 1px solid var(--border-active-secondary);
      padding: $pg-grid-space-unit-3x $pg-grid-space-unit-2x;
    }

    .input-wrap {
      display: flex;
      align-items: center;
      flex-grow: 1;

      i {
        margin-left: -5px;
        cursor: pointer;
      }
    }

    form.search-input-root {
      width: 100%;

      .input-group {
        width: 100%;
        padding: 0;
        margin-bottom: $pg-grid-space-unit-3x;

        .rbt.form-control {
          padding: 0;
        }

        input.form-control {
          padding-right: $pg-grid-space-unit-6x;
        }

        .pgicon-cancel-circled {
          position: absolute;
          right: $pg-grid-space-unit-4x;
          top: calc(50% - 7px);
          font-size: 14px;
          line-height: 14px;
          cursor: pointer;

          &::before {
            margin: 0;
          }
        }
      }
    }

    .items-container {
      margin: 0 calc($pg-grid-space-unit-3x * -1);
    }
  }

  .modal-footer {
    padding: 16px;
    button {
      width: 100%;
    }
  }

  .button-wrap {
    width: 100%;
    padding: 16px 0;
    border: 1px solid var(--pg-grey-medium);
  }
}

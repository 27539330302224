@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.pg-login-masthead-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .pg-login-masthead-icon {
    font-size: 32px;
    color: var(--pg-brand-primary);
    min-width: 32px;
    min-height: 32px;
  }

  .pg-login-masthead-title {
    @include typography.hui-heading2;
    margin: 15px 0 0;
  }

  .pg-login-masthead-description {
    @include typography.hui-body-default-regular;
    margin: 15px 0 0;
  }
}

@include media-breakpoint-up(md) {
  .pg-login-masthead-root {
    .pg-login-masthead-title {
      font-size: var(--pg-font-size-heading-2-mobile);
      line-height: var(--pg-line-height-heading-2-mobile);
    }
  }
}

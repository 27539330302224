@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/themes/pg-v2/mixins.scss' as mixinsV2;
@import 'root/styles/core/default-theme';

.listing-hidden-card {
  border-radius: $pg-grid-space-unit-2x;
  border: 1px solid var(--pg-grey-medium);
  background: var(--pg-white);

  &__alert-container {
    padding: 0 $pg-grid-space-unit-6x;
    width: 100%;

    &--margin {
      margin-bottom: $pg-grid-space-unit-6x;
      padding: 0 $pg-grid-space-unit-6x;
    }
  }

  &__alert {
    width: 100%;
    display: flex;
    margin-bottom: 0;
  }

  .pgicon {
    font-size: 24px;
  }

  .pg-icon-cancel {
    color: var(--pg-grey-darker);
  }

  &__header {
    display: flex;
    padding: $pg-grid-space-unit-6x;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $pg-grid-space-unit-4x;

    &-title {
      @include typography.hui-heading2;
    }

    border-bottom: 1px solid var(--pg-grey);
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: $pg-grid-space-unit-6x 0;

    &-title {
      @include typography.hui-body;
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      margin-bottom: $pg-grid-space-unit-4x;
      padding: 0 $pg-grid-space-unit-6x;
    }
  }

  &__reasons {
    margin-bottom: $pg-grid-space-unit-6x;
    width: 100%;
    .pgicon {
      font-size: 17px;
    }
  }

  &__reason {
    display: flex;
    align-items: center;
    align-self: stretch;
    padding: 0 $pg-grid-space-unit-6x;

    &__children {
      display: flex;
      align-items: center;
      align-self: stretch;
      width: 100%;
      padding: $pg-grid-space-unit 0;
      position: relative;
      border-bottom: 1px solid var(--pg-grey);
    }

    &-text {
      @include typography.hui-button;
      height: $pg-grid-space-unit-6x;
      flex: 1;
    }

    &-icon {
      padding: $pg-grid-space-unit-2x;
      visibility: hidden;
    }

    &--loading {
      background: var(--pg-black);

      .listing-hidden-card {
        &__reason {
          &-text {
            color: var(--pg-white);
          }

          &-icon {
            color: var(--pg-white);
            visibility: visible;
          }

          &__children {
            border-bottom: 1px solid var(--pg-black);
          }
        }
      }

      &:hover {
        &.listing-hidden-card__reason--loading {
          background: var(--pg-black);
        }
      }
    }

    &-text--white {
      flex: 1;
      color: var(--pg-white, #fff);
    }

    &:hover {
      background: var(--pg-grey-lighter);
      .listing-hidden-card__reason-icon {
        visibility: visible;
      }
    }

    &:active {
      background: var(--pg-black);

      .listing-hidden-card {
        &__reason {
          &-text {
            color: var(--pg-white);
          }

          &-icon {
            color: var(--pg-white);
            visibility: visible;
          }

          &__children {
            border-bottom: 1px solid var(--pg-black);
          }
        }
      }
    }
  }

  &__action-button.btn.btn-outline-secondary {
    @include typography.hui-button;
    @include mixinsV2.hui-button-secondary-outline-light;
    padding: $pg-grid-space-unit-2x $pg-grid-space-unit-4x;
    align-self: flex-start;
  }

  &__action-button.btn.btn-secondary {
    @include typography.hui-button;
    background: var(--pg-black);
    color: var(--pg-white);
    padding: $pg-grid-space-unit-2x $pg-grid-space-unit-4x;
    align-self: flex-start;
    &:hover {
      border-color: var(--pg-black);
    }
  }

  &__footer {
    display: flex;
    padding: 0 $pg-grid-space-unit-6x;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .ghost-button {
    padding: $pg-grid-space-unit-2x;
    border: unset;
  }

  &__undo {
    &:active {
      path {
        fill: var(--pg-white);
      }
    }
  }
}

@import './default-theme';

/*
    * Figma Ref: https://www.figma.com/file/rH6oAtzQ0RHYDa94Z4Huqx/Variable-%26-Style-library?type=design&node-id=37-229&mode=design&t=jzNtkSuOid7fzkHj-0
*/
// sm is no longer used on the new design. Only adding it back for backward compatibility
$grid-breakpoints: (
  xxs: 0,
  xs: 360px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

// sm is no longer used on the new design. Only adding it back for backward compatibility
$container-max-widths: (
  xs: 344px,
  // Remain untouched Mobile Landscape
  sm: 544px,
  md: 696px,
  // Remain untouched Tablet Landscape
  lg: 960px,
  xl: 1128px,
);

// Spacings
$spacers: (
  0: 0,
  1: $pg-grid-space-unit,
  2: $pg-grid-space-unit-2x,
  3: $pg-grid-space-unit-3x,
  4: $pg-grid-space-unit-4x,
  5: $pg-grid-space-unit-5x,
);

/* button */
$btn-line-height: 1.4;
$btn-font-family: $pg-font-body;
$btn-font-weight: 600;
$btn-box-shadow: $pg-elevation-down-medium;
$btn-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out;
$btn-focus-box-shadow: none;

$btn-border-radius: $pg-border-radius-full;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

$btn-padding-x: $pg-grid-space-unit-4x;
$btn-padding-x-sm: $btn-padding-x;
$btn-padding-x-lg: $btn-padding-x;

$btn-padding-y: $pg-grid-space-unit-3x;
$btn-padding-y-sm: $pg-grid-space-unit-2x;
$btn-padding-y-lg: $btn-padding-y;

$input-btn-font-size: 16px;
$input-btn-font-size-sm: 14px;
$input-btn-font-size-lg: $input-btn-font-size;

/* Card */
$card-border-radius: $pg-border-radius-large;

/* Badge */
$badge-font-size: 14px;
$badge-padding-y: $pg-grid-space-unit-2x;
$badge-padding-x: $pg-grid-space-unit-2x;
$badge-border-radius: $pg-border-radius-small;

/* Progress Bar */
$progress-height: 4px;
$progress-border-radius: 5px;
$progress-bar-bg: $pg-green-light;
$progress-bg: $pg-grey;

/* Dropdown */
$dropdown-padding-y: 0;
$dropdown-item-padding-y: 5px;
$dropdown-item-padding-x: 10px;
$dropdown-link-color: $pg-black;
$dropdown-link-hover-color: $pg-black;
$dropdown-link-hover-bg: $pg-grey;
$dropdown-link-active-color: $dropdown-link-hover-color;
$dropdown-link-active-bg: $dropdown-link-hover-bg;
$dropdown-divider-margin-y: 10px;

/* Pagination */
$pagination-color: $pg-grey-darker;
$pagination-border-width: 0;

$pagination-hover-color: $pg-grey-darker;
$pagination-hover-bg: $pg-grey;

$pagination-active-color: $pg-white;
$pagination-active-bg: $pg-grey-darker;

$pagination-focus-color: $pg-white;
$pagination-focus-bg: $pg-grey-darker;
$pagination-focus-box-shadow: none;

/* Nav */
$nav-link-padding-x: 3rem;
$nav-link-font-size: 18px;
$nav-link-font-weight: 600;
$nav-link-color: $pg-grey-dark;
$nav-link-hover-color: $pg-black;

$nav-tabs-border-color: $pg-grey-medium;
$nav-tabs-border-width: 1px;
$nav-tabs-link-active-color: $pg-black;
$nav-tabs-link-active-border-color: $pg-white $pg-white $pg-brand-primary;
$nav-tabs-link-hover-border-color: $pg-white $pg-white $pg-brand-primary;
$nav-pills-border-radius: var(--pg-border-radius-full);
$nav-pills-link-active-bg: var(--fill-active-tertiary);

/* Accordian */

$accordion-padding-y: 10px;
$accordion-padding-x: 0;

$accordion-color: $pg-black;
$accordion-border-width: 0;
$accordion-border-radius: 0;
$accordion-inner-border-radius: 0;

$accordion-button-active-bg: $pg-white;
$accordion-button-active-color: $pg-black;

$accordion-button-focus-border-color: $pg-grey-medium;

$accordion-body-padding-x: $pg-grid-space-unit-3x;
$accordion-body-padding-y: $pg-grid-space-unit-2x;

$accordion-button-padding-y: $pg-grid-space-unit-3x;
$accordion-button-padding-x: $pg-grid-space-unit-3x;

$accordion-icon-transition: none;
$accordion-icon-color: $pg-brand-primary;
$accordion-icon-active-color: $pg-brand-primary;
$accordion-icon-width: 16px;

$accordion-button-icon: none;
$accordion-button-active-icon: none;

/* input */
$input-padding-y: $pg-grid-space-unit-3x;
$input-padding-x: $pg-grid-space-unit-3x;
$input-padding-y-sm: $input-padding-y;
$input-padding-x-sm: $input-padding-x;
$input-padding-y-lg: $input-padding-y;
$input-padding-x-lg: $input-padding-x;
$input-border-radius: $pg-border-radius-full;
$input-border-radius-sm: $input-border-radius;
$input-border-radius-lg: $input-border-radius;
$input-placeholder-color: $text-inactive-primary;
$input-focus-width: 0;
$input-focus-box-shadow: none;
$input-focus-border-color: $pg-black;
$form-text-margin-top: 0;
$form-text-color: $text-active-secondary;

/* https://getbootstrap.com/docs/5.0/forms/validation/#sass */
$form-feedback-icon-valid-color: $pg-green;
$form-feedback-icon-valid: url('data:image/svg+xml,<svg width="18" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.528 1.238c.168-.192.378-.288.63-.288.264 0 .486.09.666.27.18.168.27.384.27.648.012.252-.072.474-.252.666L6.25798 13.784c-.18.192-.396.288-.648.288-.252.012-.468-.072-.648-.252L.875976 9.788c-.18-.18-.276-.396-.288-.648 0-.264.09-.486.27-.666.180004-.192.396004-.288.648004-.288.252-.012.468.072.648.252l3.42 3.366L15.528 1.238Z" fill="#{$form-feedback-icon-valid-color}"/></svg>');
$form-feedback-icon-invalid-color: $pg-orange;
$form-feedback-icon-invalid: url('data:img/svg+xml,<svg width="19" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.01 1.032c.156-.276.384-.456001.684-.540001.3-.084.582-.047999.846.108001.192.108.342.252.45.432l7.866 14.256c.156.264.192.546.108.846-.084.3-.264.528-.54.684-.168.084-.354.126-.558.126H1.134c-.312 0-.582-.108-.81-.324-.216-.216-.324-.48-.324-.792 0-.192.048-.372.144-.54L8.01 1.032Zm8.226 14.418L9 2.364 1.764 15.45h14.472ZM9 14.316c-.3 0-.564-.108-.792-.324-.216-.228-.324-.492-.324-.792 0-.312.108-.576.324-.792.216-.228.48-.342.792-.342.312 0 .576.114.792.342.228.216.342.48.342.792 0 .312-.114.576-.342.792-.216.216-.48.324-.792.324Zm1.134-3.924c0 .252-.114.474-.342.666-.216.18-.48.27-.792.27-.3 0-.564-.09-.792-.27-.216-.192-.324-.414-.324-.666V6.63c0-.252.108-.468.324-.648.228-.192.492-.288.792-.288.312 0 .576.096.792.288.228.18.342.396.342.648v3.762Z" fill="#{$form-feedback-icon-valid-color}"/></svg>');

/* Input check & radio */
$form-check-input-width: 24px;
$form-check-padding-start: $form-check-input-width;
$form-check-transition: 0s;

$form-check-input-active-filter: none;

$form-check-input-border: var(--bs-border-width) solid var(--border-active-primary);
$form-check-input-border-radius: var(--pg-border-radius-small);
$form-check-input-focus-border: var(--border-active-primary);
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-bg-color: var(--fill-active-tertiary);
$form-check-input-checked-border-color: var(--border-active-primary);
$form-check-input-checked-bg-image: none;

$form-check-input-indeterminate-bg-color: var(--fill-active-tertiary);
$form-check-input-indeterminate-border-color: var(--border-active-primary);
$form-check-input-indeterminate-bg-image: none;

/* Alert */
$alert-padding-y: $pg-grid-space-unit-4x;
$alert-padding-x: $pg-grid-space-unit-4x;
$alert-border-radius: $pg-border-radius-medium;

/* Modal */
$modal-inner-padding: $pg-grid-space-unit-4x;

$modal-footer-margin-between: 0;

$modal-content-border-width: 0;
$modal-content-border-radius: $pg-border-radius-large;
$modal-content-box-shadow-xs: $pg-soft-down-medium;
$modal-content-box-shadow-sm-up: $pg-soft-down-medium;

$modal-backdrop-bg: $pg-black;
$modal-backdrop-opacity: 0.6;

$modal-header-border-width: 0;
$modal-header-padding: $pg-grid-space-unit-4x;

$modal-footer-border-color: $border-active-secondary;
$modal-footer-border-width: 1px;

$modal-sm: 336px;
$modal-md: 500px;
$modal-lg: 800px;

/* Popover */
$popover-bg: $fill-static-primary;
$popover-border-color: $border-active-primary;
$popover-border-radius: $pg-border-radius-medium;
$popover-inner-border-radius: $pg-border-radius-medium;

$popover-header-bg: $fill-static-primary;
$popover-header-color: $text-active-primary;

$popover-body-color: $text-active-primary;

/* Tooltip */
$tooltip-bg: $pg-black;
$tooltip-opacity: 0.8;
$tooltip-font-size: 11px;
$tooltip-padding-y: 7px;
$tooltip-padding-x: 10px;
$tooltip-border-radius: 6px;

/* Toast */
$toast-box-shadow: none;
$toast-padding-x: $pg-grid-space-unit-4x;
$toast-padding-y: $pg-grid-space-unit-4x;
$toast-border-radius: $pg-border-radius-medium;
$toast-max-width: 336px;

$carousel-transition-duration: 0.3s;

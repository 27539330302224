@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.filter-panel-root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: $pg-grid-space-unit-2x $pg-grid-space-unit-3x 0;
  height: 48px;

  &.ai-filter-included {
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: flex-start;
  }

  &.horizontal {
    width: max-content;
  }

  &.vertical {
    .btn-link {
      &.primary {
        color: var(--pg-white);
      }
    }
    [class*='-filter-root'] {
      display: flex;
      align-items: center;
    }

    [class*='-filter-root'] + [class*='-filter-root'] {
      margin-left: 24px;
    }

    .scrollable-filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      &.ai-filter-included {
        justify-content: space-around;
        flex-wrap: nowrap;
        overflow-y: visible;
        overflow-x: auto;
        scrollbar-width: none; // Hide scrollbar in Firefox
        -ms-overflow-style: none; // Hide scrollbar in IE and Edge
        white-space: nowrap;
        margin-right: -$pg-grid-space-unit-9x;
        padding-right: $pg-grid-space-unit-9x;

        .filter-root {
          position: unset;
        }
      }
    }
  }

  &.horizontal {
    & > * {
      margin-right: 9px;
      margin-top: 12px;
    }

    .btn-link {
      &.primary {
        color: var(--pg-black);
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .filter-panel-root {
    height: 56px;
    padding: 16px 0 0;
    .vertical {
      [class*='-filter-root'] + [class*='-filter-root'] {
        margin-left: 32px;
      }
    }
  }
}

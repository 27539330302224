@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.hui-modal-root {
  .hui-modal-header {
    justify-content: space-between;

    .hui-btn-back {
      margin-right: $pg-grid-space-unit-4x;
    }

    .svg-icon-wrapper {
      path {
        fill: $text-active-tertiary;
      }
    }
  }

  .hui-modal-heading {
    @include typographyV3.pg-font-label-l;
    margin-bottom: 0;
    color: var(--text-active-tertiary);
    display: flex;
    flex-grow: 1;
  }

  [class*='modal-fullscreen'] {
    .hui-modal-header {
      background-color: $pg-white;
      border-bottom: 1px solid var(--bs-border-color);
    }

    .hui-modal-heading {
      @include typographyV3.pg-font-title-xs;
      color: $text-active-primary;
      order: 2;
    }

    .hui-modal-header-action-btn {
      width: 40px;
      height: 40px;
      border: 1px solid $border-active-primary;
      box-shadow: $pg-box-shadow-small;
      display: flex;
      align-items: center;
      justify-content: center;
      order: 1;

      .svg-icon-wrapper {
        path {
          fill: $text-active-primary;
        }
      }
    }

    .hui-btn-close {
      margin-right: $pg-grid-space-unit-4x;
    }
  }
}

$breakpoints: sm, md, lg, xl;

@each $breakpoint in $breakpoints {
  @include media-breakpoint-up($breakpoint) {
    .hui-modal-root {
      .modal-fullscreen-#{$breakpoint}-down {
        .hui-modal-header {
          background-color: $pg-black;
        }

        .hui-modal-heading {
          @include typographyV3.pg-font-label-l;
          color: $pg-white;
          order: unset;
        }

        .hui-modal-header-action-btn {
          width: unset;
          height: unset;
          border: unset;
          box-shadow: unset;
          display: inline-block;
          order: unset;

          .svg-icon-wrapper {
            path {
              fill: $text-active-tertiary;
            }
          }
        }

        .hui-btn-close {
          margin-right: unset;
        }
      }
    }
  }
}

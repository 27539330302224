@use 'root/styles/core/typography-mixins.scss' as typography;

.range-filter-root {
  .search-input-root .input-group {
    .input-group-text {
      padding: 5px 10px;
      background-color: var(--pg-grey);
    }

    input {
      @include typography.hui-body-default-regular;
      border-right: 1px solid var(--pg-grey-medium);
      padding: 5px 10px;
    }

    ::placeholder {
      color: var(--pg-grey-dark);
    }
  }

  .filter-root {
    .dropdown-menu {
      width: max-content;
      max-width: 384px;
      .dropdown-actions {
        margin-top: 16px;
      }
    }
    &.horizontal {
      .dropdown-menu {
        margin-left: 0;
      }
    }
  }

  .error {
    .input-group {
      border: 1px solid var(--pg-brand-primary);
    }
  }

  .range-item-root {
    div {
      &.list-group {
        max-height: 0;
        transition: 0.35s ease-in-out;
        overflow-y: auto;

        &.show {
          max-height: 220px;
        }

        button {
          @include typography.hui-body-default-regular;
          padding-left: 0;
          border: none;

          &.active {
            background-color: var(--pg-grey);
            color: var(--pg-black);
          }
        }
      }
    }

    .search-input-root {
      padding-bottom: 10px;
      .form-text {
        @include typography.hui-caption;
      }

      &.unit-in-front {
        input {
          border-left: 1px solid var(--pg-grey-medium);
          border-right: none;
        }
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.agent-login-prompt-root {
  @include typography.hui-body-default-regular;
  text-align: center;
  margin-top: 25px;

  .label {
    color: var(--pg-grey-darker);
  }

  a {
    color: var(--pg-brand-primary);
    text-decoration: none;

    &:hover {
      color: var(--pg-brand-primary-light);
    }

    &:active {
      color: var(--pg-brand-primary-dark);
    }
  }
}

@include media-breakpoint-up(md) {
  .agent-login-prompt-root {
    margin-top: 15px;
  }
}

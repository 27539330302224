@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.search-with-filter-root {
  margin: auto;
  &.vertical {
    border-radius: 8px;
    padding-bottom: $pg-grid-space-unit-4x;
  }
  &.horizontal {
    padding: 12px;
    display: flex;
    box-shadow: $pg-box-shadow-subtle;
    background: var(--pg-white);

    button {
      height: 36px;
    }

    .wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: auto;
    }
  }
}

@include media-breakpoint-up(sm) {
  .search-with-filter-root {
    &.vertical {
      padding: 24px $pg-grid-space-unit-9x;
    }
  }
}

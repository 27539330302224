@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.content-card-root {
  @include typography.hui-body-default-regular;
  background-color: var(--pg-white);
  color: var(--pg-black);
  min-height: 95px;
  height: fit-content;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  display: flex;
  position: relative;

  &.unviewed {
    border-bottom: 2px solid #ff837a;
  }

  &.dismissed {
    display: none;
  }

  .card-image {
    width: 60px;
    height: 60px;
  }

  .card-info {
    margin-left: 15px;

    .card-title {
      font-weight: 500;
      margin-bottom: 5px;
      text-decoration: none;
      color: var(--pg-black);

      &:hover {
        text-decoration: underline;
      }
    }

    .card-description {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 5px;
    }
  }

  .view-link {
    color: var(--pg-brand-primary);
    font-weight: 500;
    text-decoration: none;

    &:hover {
      opacity: 0.6;
    }
  }

  .pgicon-cancel {
    margin-left: auto;
    color: var(--pg-grey-dark);
    opacity: 0;
    height: 25px;
    font-size: 15px;
    transition: opacity 0.5s ease;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;

    &:hover {
      opacity: 1;
      transition: opacity 0.5s ease;
    }
  }
} // 3 variant cards styling - default style is Classic Card

.content-card-root.captioned {
  flex-direction: column;

  .card-image {
    width: 100%;
    height: 100%;
  }

  .card-info {
    padding-top: 15px;
  }
} // Captioned Image Card

.content-card-root.banner {
  display: block;

  &.dismissed {
    display: none;
  }

  .card-image {
    width: 100%;
    height: 100%;
  }
} // Banner Card

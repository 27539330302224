@import 'root/styles/core/default-theme';

.input-component {
  &--content {
    padding: 0 $pg-grid-space-unit-6x $pg-grid-space-unit-6x $pg-grid-space-unit-6x;
    display: flex;
    flex-direction: column;
    gap: $pg-grid-space-unit;

    &-input {
      font-size: 16px;
      font-family: $pg-font-body;
      font-weight: 400;
      height: 96px;
      width: 100%;
      border-color: var(--pg-grey-medium);
      padding: 5px $pg-grid-space-unit-3x 5px $pg-grid-space-unit-3x;
      resize: none;
      border-radius: 8px;

      &:focus {
        outline: unset;
      }
    }
    &-text {
      font-size: 12px;
      font-family: $pg-font-body;
    }
  }
  &--footer {
    padding: $pg-grid-space-unit-4x $pg-grid-space-unit-6x;
    border-top: 1px solid var(--pg-grey);
    &-content {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import './default-theme';

@mixin heading-common-font-family {
  font-family: $pg-font-heading, var(--bs-font-sans-serif);
}

@mixin body-common-font-family {
  font-family: $pg-font-body, var(--bs-font-sans-serif);
}

@mixin heading-common {
  font-family: $pg-font-heading, var(--bs-font-sans-serif);
  color: var(--pg-font-color-heading);
  font-weight: $pg-font-weight-heading;
}

@mixin heading-experiment-common {
  @include heading-common;
  font-weight: $pg-font-weight-experiment-heading;
}

@mixin hui-heading1 {
  @include heading-common;
  font-size: var(--pg-font-size-heading-1-mobile);
  line-height: var(--pg-line-height-heading-1-mobile);
  @include media-breakpoint-up(md) {
    font-size: var(--pg-font-size-heading-1);
    line-height: var(--pg-line-height-heading-1);
  }
}

@mixin hui-heading2 {
  @include heading-common;
  font-size: var(--pg-font-size-heading-2-mobile);
  line-height: var(--pg-line-height-heading-2-mobile);
  @include media-breakpoint-up(md) {
    font-size: var(--pg-font-size-heading-2);
    line-height: var(--pg-line-height-heading-2);
  }
}

@mixin hui-heading3 {
  @include heading-common;
  font-size: var(--pg-font-size-heading-3-mobile);
  line-height: var(--pg-line-height-heading-3-mobile);
  @include media-breakpoint-up(md) {
    font-size: var(--pg-font-size-heading-3);
    line-height: var(--pg-line-height-heading-3);
  }
}

/* (EXPERIMENT)/Headline 3 */
@mixin hui-experimental-heading3 {
  @include heading-experiment-common;
  font-size: $pg-font-size-experiment-heading-3-mobile;
  line-height: $pg-line-height-experiment-heading-3-mobile;
  @include media-breakpoint-up(md) {
    font-size: $pg-font-size-experiment-heading-3;
    line-height: $pg-line-height-experiment-heading-3;
  }
}

@mixin hui-heading4 {
  @include heading-common;
  font-size: var(--pg-font-size-heading-4-mobile);
  line-height: var(--pg-line-height-heading-4-mobile);
  @include media-breakpoint-up(md) {
    font-size: var(--pg-font-size-heading-4);
    line-height: var(--pg-line-height-heading-4);
  }
}

@mixin hui-heading5 {
  @include heading-common;
  font-size: var(--pg-font-size-heading-5-mobile);
  line-height: var(--pg-line-height-heading-5-mobile);
  @include media-breakpoint-up(md) {
    font-size: var(--pg-font-size-heading-5);
    line-height: var(--pg-line-height-heading-5);
  }
}

@mixin hui-heading-label {
  @include heading-common;
  font-size: $pg-font-size-caption;
  line-height: $pg-line-height-caption;
}

@mixin body-common {
  font-family: $pg-font-body, var(--bs-font-sans-serif);
  font-weight: $pg-font-weight-body-regular;
  color: var(--pg-font-color-body);
}

@mixin hui-body-default-regular {
  @include body-common;
  font-size: $pg-font-size-body-regular;
  line-height: $pg-line-height-body-regular;
}

/* Body Default Bold: action Buttons, Links, Dropdowns */
@mixin hui-body-default-bold {
  @include body-common;
  font-weight: $pg-font-weight-body-bold;
  font-size: $pg-font-size-body-regular;
  line-height: $pg-line-height-body-regular;
}
/* Body Big Regular: form inputs, form selects  */
@mixin hui-body-big-regular {
  @include body-common;
  font-size: $pg-font-size-body-big;
  line-height: $pg-line-height-body-big-mobile;
  @include media-breakpoint-up(md) {
    line-height: $pg-line-height-body-big;
  }
}
/* Body Big Bold: rarely used, listing cards (reduced) where we highlight the price */
@mixin hui-body-big-bold {
  @include body-common;
  font-weight: $pg-font-weight-body-bold;
  font-size: $pg-font-size-body-big;
  line-height: $pg-line-height-body-big-mobile;
  @include media-breakpoint-up(md) {
    line-height: $pg-line-height-body-big;
  }
}

// Caption: use for captions, Label All Cap
@mixin hui-caption {
  @include body-common;
  font-size: $pg-font-size-caption;
  line-height: $pg-line-height-caption;
}

@mixin hui-caption-xs-normal {
  @include heading-common;
  font-size: $pg-font-size-caption;
  line-height: $pg-line-height-caption;
  color: var(--text-neutral-primary);
  font-weight: var(--pg-font-weight-neutral);
}

@mixin hui-caption-m-normal {
  @include heading-common;
  font-size: var(--pg-font-size-caption-m-normal);
  line-height: var(--pg-line-height-caption-m-normal);
  font-weight: var(--pg-font-weight-normal);
  color: var(--text-active-primary);
}

@mixin hui-text-links {
  @include body-common;
  font-size: $pg-font-size-text-link;
  line-height: $pg-line-height-text-link;
  color: var(--pg-font-color-text-link);
  text-decoration: none;
  &:hover {
    color: var(--pg-font-color-text-link-hover);
  }
}

@mixin hui-button {
  font-family: var(--pg-font-button);
  font-size: $pg-font-size-button;
  line-height: var(--pg-line-height-button);
  font-weight: $pg-font-weight-body-bold;
  color: var(--pg-font-color-body);
}

@mixin hui-ghost-button {
  @include hui-button;
  line-height: var(--pg-line-height-ghost-button);
  color: var(--pg-black);
  gap: $pg-grid-space-unit;
  margin-right: $pg-grid-space-unit-2x;
  border-radius: $pg-border-radius-ghost-button;
}

/* experiment: pg-ldp */
@mixin body-bold-common {
  font-family: $pg-font-body-bold, var(--bs-font-sans-serif);
  font-weight: $pg-font-weight-body-bold;
  color: var(--pg-font-color-body);
}

@mixin hui-body {
  @include body-common;
  font-size: var(--pg-font-size-body);
  line-height: var(--pg-line-height-body);
}

@mixin hui-body-bold {
  @include body-bold-common;
  font-size: var(--pg-font-size-body);
  line-height: var(--pg-line-height-body);
}

@mixin hui-body-small {
  @include body-common;
  font-size: var(--pg-font-size-body-small);
  line-height: var(--pg-line-height-body-small);
}

@mixin hui-body-small-bold {
  @include body-bold-common;
  font-size: var(--pg-font-size-body-small);
  line-height: var(--pg-line-height-body-small);
}

@mixin hui-label {
  @include heading-common;
  font-size: var(--pg-font-size-label);
  line-height: var(--pg-line-height-label);
}

@mixin hui-label-l-normal {
  @include heading-common;
  font-size: var(--pg-font-size-label-l-normal);
  line-height: var(--pg-line-height-label-l-normal);
}

@mixin hui-icon {
  font-size: var(--pg-line-height-label);
  line-height: var(--pg-line-height-label);
}

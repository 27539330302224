/* 
    * Refer to https://propertyguru.atlassian.net/wiki/spaces/XD/pages/33665714879/HIVE+2.0+3.0+Token+mapping
    * Figma https://www.figma.com/file/rH6oAtzQ0RHYDa94Z4Huqx/Variable-%26-Style-library?type=design&node-id=37-229&mode=design&t=U5wInNZLMyx65z8S-0
*/

// Primitives
// Primitives - Gray
$pg-gray-0: #ffffff;
$pg-gray-10: #eff1f5;
$pg-gray-20: #c1c9d2;
$pg-gray-30: #a7b0b8;
$pg-gray-40: #737d87;
$pg-gray-50: #5a6067;
$pg-gray-60: #3e4246;
$pg-gray-70: #282c2e;
$pg-gray-80: #1c1f22;
$pg-gray-90: #0d1011;
$pg-gray-100: #040506;

// Primitives - Red
$pg-red-0: #ffffff;
$pg-red-10: #fff0f0;
$pg-red-20: #ffb2b2;
$pg-red-30: #ff8080;
$pg-red-40: #ff4242;
$pg-red-50: #ff0f0f;
$pg-red-60: #c20000;
$pg-red-70: #940000;
$pg-red-80: #660000;
$pg-red-90: #330000;
$pg-red-100: #1a0000;

// Primitives - Teal
$pg-teal-0: #ffffff;
$pg-teal-10: #e5ffff;
$pg-teal-20: #85fdff;
$pg-teal-30: #29fbff;
$pg-teal-40: #00c4c7;
$pg-teal-50: #009194;
$pg-teal-60: #005f61;
$pg-teal-70: #004142;
$pg-teal-80: #002d2e;
$pg-teal-90: #001414;
$pg-teal-100: #000a0a;

// Primitives - Purple
$pg-purple-0: #ffffff;
$pg-purple-10: #f8f0ff;
$pg-purple-20: #e4c4ff;
$pg-purple-30: #cd8fff;
$pg-purple-40: #b357ff;
$pg-purple-50: #9c24ff;
$pg-purple-60: #7e00e5;
$pg-purple-70: #5a00a3;
$pg-purple-80: #3e0070;
$pg-purple-90: #1f0038;
$pg-purple-100: #11001f;

// Primitives - Orange
$pg-orange-0: #ffffff;
$pg-orange-10: #ffeee5;
$pg-orange-20: #ffb694;
$pg-orange-30: #ff9361;
$pg-orange-40: #ff5405;
$pg-orange-50: #cc4100;
$pg-orange-60: #942f00;
$pg-orange-70: #611f00;
$pg-orange-80: #421500;
$pg-orange-90: #1f0a00;
$pg-orange-100: #0a0300;

// Primitives - Blue
$pg-blue-0: #ffffff;
$pg-blue-10: #e5ecff;
$pg-blue-20: #94b2ff;
$pg-blue-30: #5786ff;
$pg-blue-40: #336dff;
$pg-blue-50: #1f5dff;
$pg-blue-60: #0c59bc;
$pg-blue-70: #001a5c;
$pg-blue-80: #00113d;
$pg-blue-90: #00071a;
$pg-blue-100: #00030a;

// Primitives - Green
$pg-green-0: #ffffff;
$pg-green-10: #e5fff4;
$pg-green-20: #94ffcf;
$pg-green-30: #57ffb3;
$pg-green-40: #05ff8f;
$pg-green-50: #00cc70;
$pg-green-60: #008549;
$pg-green-70: #005730;
$pg-green-80: #003d22;
$pg-green-90: #001a0e;
$pg-green-100: #000a06;

// Primitives - Gold
$pg-gold-0: #ffffff;
$pg-gold-10: #fff6e5;
$pg-gold-20: #ffd894;
$pg-gold-30: #ffc157;
$pg-gold-40: #ffa305;
$pg-gold-50: #cc8100;
$pg-gold-60: #8f5a00;
$pg-gold-70: #573700;
$pg-gold-80: #3d2700;
$pg-gold-90: #1a1000;
$pg-gold-100: #0a0600;

// Primitives - Black
$pg-black-80: #1c1f22;
$pg-black-100: #000000;

// Primitives - White
$pg-white-100: #ffffff;

// Primitives - Transparency White
$pg-transparency-white-10: #ffffff12;
$pg-transparency-white-20: #ffffff2b;
$pg-transparency-white-30: #ffffff66;
$pg-transparency-white-40: #ffffff99;

// Primitives - Transparency Black
$pg-transparency-black-10: #00000012;
$pg-transparency-black-20: #0000002b;
$pg-transparency-black-30: #00000066;
$pg-transparency-black-40: #00000099;

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.actionable {
  &.social-login-button-root {
    display: flex;
    align-items: center;
    justify-content: center;

    &.btn-outline-secondary:hover {
      background-color: var(--pg-grey);
    }

    img {
      width: 15px;
      height: 15px;
    }

    &.without-title {
      padding: 12px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 0;
      }
    }

    .title {
      @include typography.hui-body-default-bold;
      color: var(--pg-grey-darker);
      margin-right: 15px;
      flex-grow: 1;
      text-align: center;
    }
  }
}

@include media-breakpoint-up(md) {
  .actionable {
    &.social-login-button-root {
      img {
        width: 18px;
        height: 18px;
        margin-right: 3px;
      }

      .title {
        flex-grow: unset;
      }
    }
  }
}

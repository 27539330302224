@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.room-type-filter-root {
  @include typography.hui-body-default-bold;

  .filter-root {
    .dropdown-menu {
      min-width: 344px;
    }

    .dropdown-toggle:active {
      color: var(--pg-white);
    }

    .checkbox-label {
      @include typographyV3.pg-font-label-xs;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: var(--pg-grid-space-unit-3x) !important;
    }
  }
}

@include media-breakpoint-up(sm) {
  .room-type-filter-root {
    .filter-root {
      .dropdown-menu {
        max-width: 360px;

        .room-rental-select-body {
          justify-content: space-between;

          .room-rental-item {
            padding-right: 0;
          }
        }
      }

      .checkbox-label {
        flex-direction: initial;
        justify-content: initial;
      }
    }
  }
}

@use 'sass:map';
@use './typography-mixins' as typography;
@use './typography-mixins-v3' as typographyV3;
@use './utils-mixin.scss' as utils;

@mixin pgicon-bg {
  font-family: 'pgicons';
  font-size: 14px;
  position: relative;
  top: -0.25em;
  color: $pg-black;
}

.btn {
  i {
    &,
    &::before {
      margin: 0;
    }
  }
  img {
    height: 16px;
    width: 16px;
  }
  &.icon-prefix {
    i,
    svg {
      margin-right: 5px;
    }
  }
  &.icon-suffix {
    i,
    svg {
      margin-left: 5px;
    }
  }
  &.icon-square {
    padding: 11px;
    line-height: 14px;
    &.btn-lg {
      padding: 13px;
    }
    &.btn-sm {
      padding: 8px;
    }
  }
  &.btn-round {
    padding: $pg-grid-space-unit-3x;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.btn-sm {
      padding: $pg-grid-space-unit-2x;
      width: 40px;
      height: 40px;
    }
  }
  .badge {
    margin-left: 4px;
  }
}

.btn-link {
  font-weight: bold;
  text-decoration: none;
  padding: 0;
}

.btn-link.white {
  color: $pg-white;
}

/* Dropdown */
.dropdown {
  > button {
    display: flex;
    justify-content: space-between;
    padding: 7px 15px 7px 20px;
    &.dropdown-toggle-split {
      padding: 7px 13px;
      flex: 0;
    }
  }
  &.show {
    > button i.pgicon-arrow-down:before {
      transform: rotate(180deg);
    }
  }
}

.dropdown-header {
  padding: 5px 10px;
  @include typography.hui-caption;
  font-family: $pg-font-family-heading;
  color: $pg-grey-dark;
  & + .dropdown-item {
    margin-top: 9px;
  }
}

.dropdown-menu {
  border-radius: 8px;
  border-width: 0;
  box-shadow: $pg-box-shadow-soft;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  @include typography.hui-body-default-regular;
  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  & + & {
    margin-top: 10px;
  }
}

/* Form */
.form-label {
  @include typography.hui-caption;
}

input.form-control {
  @include typography.hui-body-big-regular;
  @include media-breakpoint-up(md) {
    line-height: $pg-line-height-body-big-mobile;
  }
}

textarea.form-control {
  resize: none;
}

.form-text.text-muted {
  @include typography.hui-caption;
  margin-left: 2px;
}

.invalid-feedback {
  @include typography.hui-caption;
  color: $pg-orange-dark;
  margin-top: 5px;
}

.input-group {
  .form-control.is-invalid {
    background-image: none;
  }
}

.btn-form-select {
  i {
    color: $pg-black;
  }
  color: $pg-grey-medium;
  border-color: $pg-grey-medium;
  &:hover,
  &:active,
  &:focus-within {
    border-color: $pg-black;
    color: $pg-black;
  }
}

.counter {
  input {
    text-align: center;
  }
  .btn-outline-grey {
    @include typography.hui-body-big-regular;
    padding: 7px 15px;
    border-color: $pg-grey-medium;
    color: $pg-black;
    background-color: $pg-grey;
    &:disabled {
      background-color: $pg-white;
      color: $pg-grey-medium;
      opacity: 1;
    }
  }
  input:disabled {
    background: $pg-white;
    color: $pg-grey-medium;
    opacity: 1;
  }
}
.form-check:not(.form-switch) {
  .form-check-input[type='radio'] {
    &:before {
      @include pgicon-bg;
      content: $pg-icon-radio-code;
      color: $pg-grey-dark;
    }
    &:checked:before {
      @include pgicon-bg;
      content: $pg-icon-radio-check-code;
    }
  }
}

.form-check-input {
  cursor: pointer;
  background: transparent;

  &[type='checkbox'] {
    margin-top: 0;

    &::after {
      content: '';
      position: absolute;
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: 6px 6px;
      inset: 0;
    }

    &:checked,
    &:indeterminate {
      box-shadow: $pg-elevation-down-small;
      &:hover {
        background: linear-gradient(var(--fill-hover-primary), var(--fill-hover-primary)), var(--fill-active-tertiary);
      }

      &:active {
        background: linear-gradient(var(--fill-pressed-primary), var(--fill-pressed-primary)),
          var(--fill-active-tertiary);
      }
    }

    &:checked {
      &::after {
        background-image: url(https://cdn.pgimgs.com/hive-ui/static/v0.1.141/pg-icons/font/v3/checkmark-white.svg);
      }
    }

    &:indeterminate {
      &::after {
        background-image: url(https://cdn.pgimgs.com/hive-ui/static/v0.1.141/pg-icons/font/v3/minus_large_white.svg);
      }
    }

    @include media-breakpoint-up(md) {
      &:hover {
        background: linear-gradient(var(--fill-hover-primary), var(--fill-hover-primary)), transparent;
      }
    }

    &:active {
      background: linear-gradient(var(--fill-pressed-primary), var(--fill-pressed-primary)), transparent;
    }

    &:focus-visible {
      border: 1px solid var(--border-focus-primary);
    }
  }
}

.form-switch {
  .form-check-input[type='checkbox'] {
    border: 1px solid $pg-grey-darker;
    &:checked {
      background-color: $pg-grey-darker;
    }
  }
}

/* Accordion */
.accordion-header {
  padding: 0;

  .accordion-button {
    @include typographyV3.pg-font-label-m;
  }
}

button.accordion-button {
  border-bottom: 1px solid $pg-grey-medium;
  @include typography.hui-heading4;
  &[aria-expanded='true'] {
    border-bottom: 1px solid $pg-brand-primary;
  }
}

.accordion-button::after {
  @include pgicon-bg;
  content: $pg-icon-arrow-down-code;
  color: $pg-brand-primary;
}

.accordion-body {
  @include typography.hui-body-default-regular;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid $pg-brand-primary;
}

.nav-tabs .nav-link {
  @include typography.heading-common-font-family;
  width: 100%;
}

.nav-tabs .nav-item {
  flex: 1;
}

/* Alert */
.alert {
  @include typography.hui-body-default-regular;
  .alert-icon {
    margin-right: 10px;
  }
  .alert-link {
    @include typography.hui-text-links;
  }
  .btn-close {
    background: none;
    display: flex;
    align-items: center;
    box-shadow: none;
    &:before {
      @include pgicon-bg;
      content: $pg-icon-cancel-code;
    }
  }
}

/* Modal */
.modal {
  &-header {
    background: $fill-neutral-quaternary;
    @include typography.hui-heading4;
    color: $text-active-tertiary;

    .actionable {
      i {
        color: $text-active-tertiary;
      }
      svg {
        fill: $text-active-tertiary;
      }
    }
  }
  &-footer {
    padding: $pg-grid-space-unit-4x;
  }
}

/* Popover */
.popover-header {
  @include typography.hui-body-big-bold;
  color: var(--text-active-primary);
  padding-bottom: 0;
  border-bottom: 0;
}
.popover-body {
  @include typography.hui-body-default-regular;
  color: var(--text-active-primary);
}
.popover {
  box-shadow: var(--pg-soft-down-medium);
  &-close {
    fill: var(--icon-active-primary);
  }
}
.popover-with-close {
  display: grid;
  grid-template-areas:
    'header close'
    'body close';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  align-items: flex-start;

  .popover-close {
    grid-area: close;
    margin-top: $pg-grid-space-unit-3x;
    margin-right: $pg-grid-space-unit-4x;
    width: $pg-grid-space-unit-6x;
    height: $pg-grid-space-unit-6x;
  }

  .popover-header {
    grid-area: header;
  }

  .popover-body {
    grid-area: body;
  }

  .popover-header + .popover-body {
    margin-right: -$pg-grid-space-unit-10x;
  }
}

.popover.dark {
  background-color: var(--fill-active-tertiary);
  border-color: var(--fill-active-tertiary);

  .popover-header {
    background-color: var(--fill-active-tertiary);
    color: var(--text-active-tertiary);
  }
  .popover-body {
    color: var(--text-active-tertiary);
  }
  .popover-close {
    fill: var(--icon-active-tertiary);
  }
  &.bs-popover-end > .popover-arrow:after {
    border-right-color: var(--fill-active-tertiary);
  }
  &.bs-popover-start > .popover-arrow:after {
    border-left-color: var(--fill-active-tertiary);
  }
  &.bs-popover-top > .popover-arrow:after {
    border-top-color: var(--fill-active-tertiary);
  }
  &.bs-popover-bottom > .popover-arrow:after {
    border-bottom-color: var(--fill-active-tertiary);
  }
  &.bs-popover-bottom .popover-header::before {
    border-bottom: 0;
  }
}

/* Tooltip */
.tooltip {
  @include typography.body-common-font-family;
  line-height: 16px;
}

/* Toast */
.toast {
  .toast-header {
    padding-right: 55px;
    position: relative;
  }
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    padding: 0;
    background-image: none;
    opacity: 1;
    background-color: unset;
    &:before {
      @include pgicon-bg;
      content: $pg-icon-cancel-code;
    }
  }
  .toast-icon {
    margin-right: 10px;
  }
  .toast-actionable {
    margin-top: 10px;
    .btn-link {
      font-weight: 400;
    }
    .btn-link + .btn-link {
      margin-left: 20px;
    }
  }
}

/* Theme-based styles */
@each $color, $value in $theme-colors {
  /* Button */
  .btn-#{$color} {
    box-shadow: $pg-elevation-down-small;

    &:disabled {
      background-color: $fill-inactive-primary;
      color: $text-inactive-primary;
      border: none;
      box-shadow: none;

      svg path,
      circle {
        fill: $icon-inactive-primary !important;
      }
    }

    &:focus-visible {
      border-color: $border-focus-primary;
      box-shadow: $pg-elevation-down-small;
    }

    &:hover {
      background: linear-gradient($fill-hover-primary, $fill-hover-primary), $value;
    }

    &:active {
      background: linear-gradient($fill-pressed-primary, $fill-pressed-primary), $value;
    }

    @if $color == 'primary' {
      @include button-variant(
        $background: $value,
        $border: $border-active-quaternary,
        $color: $text-active-tertiary,
        $hover-color: $text-static-primary
      );

      &:active {
        box-shadow: none;
      }

      svg path,
      circle {
        fill: $icon-static-primary !important;
      }
    } @else if $color == 'secondary' {
      box-shadow: $pg-soft-down-small;

      @include button-variant($background: $value, $border: $border-active-tertiary, $color: $text-active-primary);

      &:hover {
        background: $fill-hover-primary;
      }

      &:active {
        background: $fill-pressed-primary;
      }

      svg path,
      circle {
        fill: $icon-active-primary !important;
      }

      &:disabled {
        background-color: unset;
        border: 1px solid $border-inactive-primary;
      }
    } @else if $color == 'tertiary' {
      @include button-variant($background: $value, $border: $border-error-secondary, $color: $text-static-secondary);

      &:active {
        box-shadow: none;
      }

      svg path,
      circle {
        fill: $icon-active-primary !important;
      }
    } @else if $color == 'light' {
      @include button-variant($background: $value, $border: $border-active-primary, $color: $text-static-secondary);

      &:active {
        box-shadow: none;
      }

      svg path,
      circle {
        fill: $icon-static-secondary !important;
      }
    } @else if $color == 'dark' {
      @include button-variant($background: $value, $border: $border-active-tertiary, $color: $text-static-primary);

      &:active {
        box-shadow: $pg-soft-down-small;
      }

      svg path,
      circle {
        fill: $icon-static-primary !important;
      }
    }
  }

  .btn-link.#{$color} {
    color: $value;
    &:focus:not(:focus-visible) {
      color: $value;
    }
    &:hover {
      color: map.get($theme-colors-light, $color);
    }
    &:focus:active {
      color: map.get($theme-colors-dark, $color);
    }
  }

  @if ($color != 'tertiary' and $color != 'light' and $color != 'dark') {
    /* Dropdown */
    .show .btn-#{$color}.dropdown-toggle {
      color: $pg-white;
    }
    /* Alert */
    .alert-#{$color} {
      --#{$prefix}alert-color: #{map.get($theme-colors-dark, $color)};
      --#{$prefix}alert-bg: #{map.get($theme-colors-lightest, $color)};
      --#{$prefix}alert-border-color: #{map.get($theme-colors-lighter, $color)};
      --#{$prefix}alert-link-color: var(--pg-brand-primary);

      color: map.get($theme-colors-dark, $color);
      .alert-title {
        @include typography.hui-body-default-bold;
        color: map.get($theme-colors-dark, $color);
      }
      .btn-close {
        color: map.get($theme-colors-dark, $color);
      }
    }
    /* Toast */
    .toast.#{$color} {
      border-color: $value;
      .toast-header {
        color: map.get($theme-colors-dark, $color);
        .btn-close:before {
          color: map.get($theme-colors-dark, $color);
        }
      }
      .toast-icon {
        color: map.get($theme-colors-dark, $color);
      }
      .toast-title {
        @include typography.hui-body-default-bold;
        color: map.get($theme-colors-dark, $color);
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  /* Theme-based styles */
  @each $color, $value in $theme-colors {
    .btn-#{$color} {
      &:hover {
        background: $value;
      }
    }
  }
}

.form-control {
  @include form-validation-state-selector('valid') {
    border-color: $pg-grey-dark;
    &:focus {
      border-color: $pg-black;
    }
  }
  @include form-validation-state-selector('invalid') {
    border-color: $pg-orange;
    background-color: $pg-orange-lightest;
    &:focus {
      border-color: $pg-orange;
    }
  }
}

// Divider
.divider-root {
  position: relative;
  height: 32px;
  display: flex;
  align-items: center;

  .line {
    width: 100%;
    height: 1px;
    background-color: $border-active-primary;
  }

  .title-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    .title {
      @include typography.hui-body-default-bold;
      background-color: var(--pg-white);
      padding: 5px;
      color: var(--pg-grey-medium);
    }
  }
}

.clip-one-line {
  @include utils.text-clip(1);
}

.clip-two-line {
  @include utils.text-clip(2);
}

.clip-three-line {
  @include utils.text-clip(3);
}

.clip-four-line {
  @include utils.text-clip(4);
}

// Nav pills
.tab-root {
  display: flex;
  width: fit-content;
  overflow: hidden;
  flex-wrap: unset;
  white-space: nowrap;

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: $pg-grid-space-unit-2x;
  }

  &.nav.nav-pills {
    border-radius: var(--pg-border-radius-full);
    padding: $pg-grid-space-unit;
    background: var(--fill-neutral-primary);

    & > .nav-item {
      & > .nav-link {
        @include typography.hui-heading5;
        min-width: 80px;
        border: 1px solid transparent;
        padding-inline: $pg-grid-space-unit-3x;
        text-align: center;
        font-weight: 400;
        color: var(--text-inactive-primary);

        &:hover {
          color: var(--text-inactive-primary);
        }

        &.active {
          border: 1px solid var(--border-active-tertiary);
          box-shadow: var(--elevation-down-small);
          color: var(--text-active-tertiary);

          svg,
          .svg-icon-wrapper {
            // Change the color of the icon to white when the tab is active
            filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(213deg) brightness(105%) contrast(101%);
          }
        }
      }
    }
  }

  &.nav.nav-tabs {
    gap: $pg-grid-space-unit-4x;
    border-bottom: 0;

    & > .nav-item {
      & > .nav-link {
        @include typography.hui-heading4;
        margin-bottom: 0;
        padding-inline: 0;
        border-bottom-width: 3px;
      }
    }
  }
}

.btn-primary {
  &:disabled {
    color: var(--text-inactive-primary);
  }
}

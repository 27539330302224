@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.sitemap-block-root {
  background: var(--pg-grey);
  color: var(--pg-black);
  a,
  a:hover,
  a:active {
    color: var(--pg-black);
  }

  .footer-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
  }

  .sitemap-links-wrapper {
    flex: 1 1 100%;
    border-bottom: 2px solid var(--pg-white);
    padding: 0;

    .link-stack-root {
      padding-top: 0;
      padding-bottom: 0;

      h5 > a,
      .link-stack-items > div {
        margin-right: calc(var(--bs-gutter-x) * 0.5);
        margin-left: calc(var(--bs-gutter-x) * 0.5);
      }
      a {
        @include typography.hui-heading5;
      }
      h5 > a {
        padding: $pg-grid-space-unit-3x;
      }

      .link-stack-items {
        padding-inline: $pg-grid-space-unit-3x;
        background-color: var(--pg-grey-lighter);
        display: block;
        transition: 0.4s ease-in-out;
        max-height: 0;
        overflow: hidden;
        padding-bottom: 0;

        & .link-stack-item:last-child {
          padding-bottom: 15px;
        }

        &.expand-stack {
          display: block;
          max-height: unset;
        }

        .only-desktop {
          display: none;
        }

        .only-mobile {
          display: block;
        }
        .view-more-links {
          padding-top: 4px;
          @include typography.hui-body-default-bold;
          color: var(--pg-brand-primary);
        }
        a {
          @include typography.hui-caption;
        }
        .expandable-item-stack {
          @include typography.hui-body-default-bold;
          &::after {
            transition: 0.4s ease-in-out;
          }
        }
      }
    }

    .item-stack-root {
      padding-top: 15px;

      .link-action-strip-list-item {
        display: block;
        max-height: 0;
        transition: 0.4s ease-in-out;

        &.expand {
          display: block;
          max-height: unset;
        }
      }
      .not-expandable:hover {
        color: var(--pg-brand-primary);
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .sitemap-block-root {
    .footer-container {
      padding-inline: $pg-grid-space-unit-3x;
    }

    .sitemap-links-wrapper {
      flex: 0 1 calc(100% / 4);
      min-width: 0;
      gap: $pg-grid-space-unit-6x;
      border-bottom: none;

      .item-stack-root {
        padding-top: 5px;

        .link-action-strip-list-item {
          margin-left: 5px;
        }
      }
      .link-stack-root {
        padding-top: 10px;
        padding-bottom: 10px;
        max-width: 100px;

        a:hover {
          color: var(--pg-brand-primary);
        }
        h5 {
          @include typography.hui-heading5;
          & > a {
            padding: 0;
          }
        }

        .link-stack-items {
          padding-inline: 0;
          background: var(--pg-grey);
          max-height: unset;
          overflow: visible;

          .only-desktop {
            display: block;
          }

          .only-mobile {
            display: none;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .sitemap-block-root {
    .sitemap-links-wrapper {
      .link-stack-root {
        max-width: 156px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .sitemap-block-root {
    .sitemap-links-wrapper {
      .link-stack-root {
        max-width: unset;
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.copyright-strip-root {
  display: flex;
  flex-direction: column;
  background: var(--pg-black);
  text-align: center;
  padding-block: $pg-grid-space-unit-4x;
  position: relative;

  .term-link {
    @include typography.hui-body-default-regular;
    color: var(--pg-white);
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
  }

  .term-link + .term-link {
    margin-left: $pg-grid-space-unit-5x;
  }

  .copy-text-section {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .copy-text {
      @include typography.hui-caption;
      color: var(--pg-white);
    }
  }

  .links-section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

@include media-breakpoint-up(lg) {
  .copyright-strip-root {
    display: flex;
    flex-direction: row;
    text-align: inherit;

    .copy-text-section {
      padding-left: 0;
      justify-content: flex-end;
    }

    .links-section {
      flex: 1;
      justify-content: flex-start;
      padding-right: 0;
    }
  }
}

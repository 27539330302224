@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.filter-root {
  &.horizontal {
    .dropdown-toggle {
      color: var(--pg-black);

      &.btn-outline-secondary {
        &:hover {
          background-color: var(--pg-grey);
        }
        &:active {
          background-color: transparent;
          color: var(--pg-black);
        }
      }

      i {
        margin-left: 6px;
        flex-grow: 0;
      }
    }
  }

  &.vertical {
    .dropdown-toggle {
      @include typography.hui-button;
      border: none;
      background: transparent;
      color: var(--pg-white);
      max-width: 110px;
      padding: 0;
      display: flex;
      &:hover,
      &:focus,
      &:active,
      &:focus:active {
        background: none;
      }
      span.toggle-btn-text {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      i {
        margin-left: 4px;
        flex-grow: 0;
      }

      &.btn-outline-secondary {
        &:hover {
          background-color: transparent;
        }
      }
    }

    .dropdown-menu {
      width: 100vw;
    }
  }

  .dropdown-menu {
    width: 100%;
    background-color: var(--pg-white);
    box-shadow: none;

    .dropdown-title {
      font-size: $pg-font-size-body-regular;
      padding: 16px;
      padding-bottom: 0;
      font-weight: 500;
    }

    .dropdown-body {
      padding: 16px;
      padding-bottom: 0;
    }

    .dropdown-actions {
      display: flex;
      justify-content: space-between;
      padding: 18px 16px;
      border-top: 1px solid var(--pg-grey);
    }
  }
}

@include media-breakpoint-up(sm) {
  .filter-root {
    &.vertical {
      .dropdown-toggle {
        max-width: 185px;
      }
    }
    .dropdown-menu {
      box-shadow: $pg-box-shadow-soft;
      width: calc(100% - 40px);
    }
  }
}

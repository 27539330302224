@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.property-summary {
  &-root {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.space-between {
      justify-content: space-between;
      flex-grow: 1;
    }
  }

  &-count {
    @include typography.hui-heading3;
    color: var(--pg-black);
    margin: 0;
  }

  &-label {
    @include typography.hui-heading5;
    color: var(--pg-black);
    margin: 0;
  }

  @include media-breakpoint-up(sm) {
    &-root {
      justify-content: flex-start;
      width: auto;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'root/styles/core/default-theme';

.badge {
  &.badge-root {
    align-items: center;
    padding-inline: var(--pg-grid-space-unit-2x);

    &.badge-round {
      @include typography.hui-body-default-bold;
      display: inline-flex;
      justify-content: center;
      position: relative;
      aspect-ratio: 1 / 1;
      background-color: var(--fill-active-primary);
      border-radius: 50%;

      &.action {
        &--count {
          height: var(--pg-grid-space-unit-4x);
          min-width: var(--pg-grid-space-unit-4x);
          border-radius: var(--pg-border-radius-small);
          @include typographyV3.pg-font-caption-s;
          padding-inline: var(--pg-grid-space-unit);
          aspect-ratio: auto;

          &::before {
            display: none;
          }
        }

        &--indicator {
          min-width: 8px;
        }
      }

      &::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: $pg-z-index-neg-1;
      }

      line-height: 0 !important;
      color: var(--text-static-primary);
    }

    &.badge-default {
      @include typographyV3.pg-font-body-xs;
      background-color: var(--fill-neutral-primary);
      color: var(--text-active-primary);
      border-radius: $pg-border-radius-small;
      // Remove these both when typography changes are done
      line-height: 0;
      height: 36px;
      // Remove these both when typography changes are done
      display: inline-flex;
      border: 1px solid var(--border-active-primary);

      .badge-icon {
        path {
          // To cater for inline styling in svg file
          fill: var(--icon-active-secondary) !important;
        }
      }

      &.bg {
        &-primary {
          &-neutral {
            background-color: var(--fill-neutral-primary);

            .badge-icon {
              path {
                // To cater for inline styling in svg file
                fill: var(--icon-active-secondary) !important;
              }
            }
            color: var(--text-active-primary);
          }

          &-red {
            background-color: var(--fill-error-secondary);
            border-color: var(--border-error-primary);

            .badge-icon {
              path {
                // To cater for inline styling in svg file
                fill: var(--icon-error-primary) !important;
              }
            }
            color: var(--text-error-tertiary);
          }

          &-orange {
            background-color: var(--fill-warning-secondary);
            border-color: var(--border-warning-primary);

            .badge-icon {
              path {
                // To cater for inline styling in svg file
                fill: var(--icon-warning-primary) !important;
              }
            }
            color: var(--text-warning-primary);
          }

          &-green {
            background-color: var(--fill-success-secondary);
            border-color: var(--border-success-primary);

            .badge-icon {
              path {
                // To cater for inline styling in svg file
                fill: var(--icon-success-primary) !important;
              }
            }
            color: var(--text-success-tertiary);
          }
        }
      }

      &.bg {
        &-secondary {
          &-neutral,
          &-red,
          &-orange,
          &-brand-orange,
          &-brand-teal,
          &-green {
            background: transparent;
          }

          &-neutral {
            border-color: var(--border-active-primary);

            .badge-icon {
              path {
                // To cater for inline styling in svg file
                fill: var(--icon-active-secondary) !important;
              }
            }
            color: var(--text-active-secondary);
          }

          &-red {
            border-color: var(--border-error-secondary);

            .badge-icon {
              path {
                // To cater for inline styling in svg file
                fill: var(--icon-error-primary) !important;
              }
            }
            color: var(--text-error-tertiary);
          }

          &-orange {
            border-color: var(--border-warning-secondary);

            .badge-icon {
              path {
                // To cater for inline styling in svg file
                fill: var(--icon-warning-primary) !important;
              }
            }
            color: var(--text-warning-primary);
          }

          &-green {
            border-color: var(--border-success-secondary);

            .badge-icon {
              path {
                // To cater for inline styling in svg file
                fill: var(--icon-success-primary) !important;
              }
            }
            color: var(--text-success-tertiary);
          }
        }
      }

      &--disabled {
        &[class*='bg-primary-'] {
          background: var(--fill-inactive-primary);
        }
        &[class*='bg-secondary-'] {
          background: transparent;
        }
        &[class*='bg-primary-'],
        &[class*='bg-secondary-'] {
          border-color: var(--border-inactive-primary);
          cursor: not-allowed;

          .badge-icon {
            path {
              // To cater for inline styling in svg file
              fill: var(--icon-inactive-primary) !important;
            }
          }
          color: var(--text-inactive-primary);
        }
      }
    }

    &.badge-small {
      @include typographyV3.pg-font-caption-xs;
      border-radius: $pg-border-radius-small;
      background-color: var(--fill-neutral-tertiary);
      color: var(--text-active-secondary);

      &.bg {
        &-primary {
          &-neutral {
            background-color: var(--fill-neutral-tertiary);
            color: var(--text-active-secondary);
          }

          &-red {
            background-color: var(--fill-active-primary);
            color: var(--text-active-tertiary);
          }

          &-orange,
          /* @deprecated */
          &-brand-orange {
            background-color: var(--fill-brand-orange);
            color: var(--text-active-tertiary);
          }

          /* @deprecated */
          &-brand-teal {
            background-color: var(--fill-brand-teal);
            color: var(--text-active-tertiary);
          }

          &-green {
            background-color: var(--fill-success-primary);
            color: var(--text-active-tertiary);
          }

          &-light-neutral {
            background-color: var(--fill-neutral-primary);
            color: var(--text-inactive-primary);
          }

          &-light-red {
            background-color: var(--fill-active-secondary);
            color: var(--text-error-primary);
          }

          &-light-orange {
            background-color: var(--fill-warning-secondary);
            color: var(--text-warning-primary);
          }

          &-light-green {
            background-color: var(--fill-success-secondary);
            color: var(--text-success-primary);
          }
        }

        &-secondary {
          &-neutral,
          &-red,
          &-orange,
          &-green,
          &-light-neutral,
          &-light-red,
          &-light-orange,
          &-light-green {
            border: 1px solid;
            background: transparent;
          }

          &-neutral {
            border-color: var(--border-active-primary);
            color: var(--text-active-secondary);
          }

          &-red {
            border-color: var(--border-error-tertiary);
            color: var(--text-error-tertiary);
          }

          &-orange {
            border-color: var(--border-warning-tertiary);
            color: var(--text-warning-primary);
          }

          &-green {
            border-color: var(--border-success-tertiary);
            color: var(--text-success-primary);
          }
        }
      }

      &--disabled {
        &[class*='bg-primary-'] {
          background: var(--fill-inactive-primary);
        }
        &[class*='bg-secondary-'] {
          background: transparent;
        }
        &[class*='bg-primary-'],
        &[class*='bg-secondary-'] {
          border-color: var(--border-inactive-primary);
          cursor: not-allowed;
          color: var(--text-inactive-primary);
        }
      }
    }
  }
}

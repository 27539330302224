@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.hui-badges-root {
  margin-bottom: $pg-grid-space-unit-3x;
  white-space: nowrap;

  .hui-badges-label {
    @include typography.hui-caption;
    color: var(--pg-black);
    margin-bottom: $pg-grid-space-unit-2x;
  }

  .badges-group {
    display: flex;
    gap: $pg-grid-space-unit-2x;
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: $pg-grid-space-unit-3x;

    .actionable {
      @include typography.hui-heading5;
      border: 1px solid var(--pg-grey-medium);
      padding: $pg-grid-space-unit-2x $pg-grid-space-unit-4x;

      &:hover,
      &:active,
      &:focus {
        border-color: var(--pg-grey-dark);
        background: var(--pg-grey);
      }

      &.selected {
        background: var(--pg-black);
        border-color: var(--pg-black);
        color: var(--pg-white);
      }
    }
  }
}

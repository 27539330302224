@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.delete-account-modal-root {
  .modal-dialog {
    height: auto;
    max-width: 330px;
  }

  h4 {
    @include typography.hui-heading4;
  }

  p {
    margin-bottom: 0;
    @include typography.hui-body-default-regular;
  }

  .modal-header {
    h4 {
      margin-bottom: 0;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: $pg-grid-space-unit-2x;
    padding-top: $pg-grid-space-unit-10x;
    padding-bottom: $pg-grid-space-unit-5x;

    .hui-image-root {
      width: 115px;
      margin-bottom: $pg-grid-space-unit-4x;
    }

    .pgicon-ok-circled {
      color: var(--pg-green);

      &::before {
        font-size: 48px;
        margin: 0;
      }
    }

    h4 {
      margin-bottom: $pg-grid-space-unit-2x;
      color: var(--pg-green);
    }
  }

  .modal-footer {
    .btn-primary {
      width: 100%;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.ai-filter-root {
  display: flex;
  align-items: center;

  .title,
  .suggested-title {
    @include typographyV3.pg-font-label-xs;
    color: var(--text-active-tertiary);
  }

  &:has(.suggested-container) {
    overflow: hidden;
    margin-right: -$pg-grid-space-unit-9x;
  }

  .ai-filter,
  .suggested-container {
    display: flex;
    gap: $pg-grid-space-unit-3x;
    align-items: center;
  }

  .suggested-container {
    white-space: nowrap;
    overflow-y: visible;
    overflow-x: auto;
    scrollbar-width: none; // Hide scrollbar in Firefox
    -ms-overflow-style: none; // Hide scrollbar in IE and Edge
    padding-right: $pg-grid-space-unit-9x;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .toggle-switch-wrapper {
    display: flex;
  }

  .vertical-line {
    content: ' ';
    display: inline-block;
    width: 1px;
    height: $pg-grid-space-unit-8x;
    background-color: $border-active-primary;
    margin-right: $pg-grid-space-unit-3x;
  }

  .chip-group {
    background-color: transparent !important;

    label {
      background-color: $pg-white;
    }
  }

  .chip-group .btn-check:not(:checked) + .btn:hover,
  label:hover {
    background-color: $pg-gray-10;
  }
}

.ai-filter-popover {
  &.popover {
    top: $pg-grid-space-unit-2x !important;
    z-index: $pg-z-index-2;
  }

  &.bs-popover-bottom > .popover-arrow {
    &::before {
      border-bottom-color: unset;
    }
  }

  .popover-header {
    @include typographyV3.pg-font-label-s;
  }

  .popover-body {
    @include typographyV3.pg-font-label-xs;
    padding-top: $pg-grid-space-unit-2x;
  }
}

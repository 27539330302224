@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.user-review-with-replies-root {
  .user-review-root {
    display: grid;
    grid-column-gap: $pg-grid-space-unit-2x;
    grid-template-columns: $pg-grid-space-unit-10x auto;
    padding: $pg-grid-space-unit-5x;
    border: 1px solid var(--pg-grey-medium);
    border-radius: 8px;

    .user-avatar {
      @include typography.hui-heading4;
      color: var(--pg-grey-dark);
      background-color: var(--pg-grey);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 50%;

      &-img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }

    .user-review-description,
    .user-name {
      margin-bottom: 0;
    }

    .user-name {
      @include typography.hui-heading4;
    }

    .user-review-description {
      @include typography.hui-body-default-regular;
      grid-column: 2/3;
      margin-top: $pg-grid-space-unit-5x;
    }

    @include media-breakpoint-down(md) {
      .user-review-description {
        grid-column: 1/3;
      }
    }
  }

  &.has-reply {
    > .user-review-root {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.user-review-reply-root {
    .user-review-root {
      border-top: unset;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background: var(--pg-grey-neutral-40);
    }
  }
}

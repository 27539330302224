@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.agent-contact-info-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $pg-grid-space-unit-4x;
  width: 100%;
  padding: $pg-grid-space-unit-4x $pg-grid-space-unit-6x;

  @include media-breakpoint-down(md) {
    padding-top: 15px;
  }

  .agent-profile-name-root {
    display: flex;
    align-items: center;
    gap: $pg-grid-space-unit-3x;
    align-self: flex-start;
    min-width: 0;

    .agent-link {
      text-decoration: none;
    }

    @include media-breakpoint-down(md) {
      flex-direction: row;
      flex-grow: 1;
    }
  }

  .agent-name-rating-root {
    display: flex;
    flex-direction: column;
    gap: $pg-grid-space-unit-2x;

    .agent-rating {
      @include typography.hui-heading5;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      align-self: flex-start;
      padding: $pg-grid-space-unit $pg-grid-space-unit-3x;
      border: 1px solid var(--pg-grey-dark);
      border-radius: $pg-grid-space-unit-4x;
      text-decoration: none;

      .pgicon-star {
        font-size: 11px;
        line-height: 11px;
        color: var(--pg-gold);
        margin-right: $pg-grid-space-unit;
      }

      .rating-text {
        margin-left: $pg-grid-space-unit-2x;
        color: var(--pg-brand-primary);
      }

      .pgicon-arrow-right {
        margin-left: 6px;
        color: var(--pg-brand-primary);
      }
    }
  }

  .divider-root {
    height: 1px;
    margin: -2px 0;
    width: calc(100% + 48px);

    .line {
      background-color: var(--pg-grey);
    }
  }

  .avatar {
    width: 60px;
    height: 60px;
    object-fit: cover;
    font-size: 26px;
    border-radius: 50%;
    background-color: var(--pg-white);
  }

  .agent-name {
    @include typography.hui-heading4;
    color: var(--pg-black);
    margin: $pg-grid-space-unit 0 0;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-strip {
    flex-direction: column;
    gap: $pg-grid-space-unit-3x;
    width: 100%;

    .btn {
      color: var(--pg-black);
      margin-right: 0;
      padding: $pg-grid-space-unit-3x $pg-grid-space-unit-6x;
      border: 1px solid var(--pg-grey-medium);

      &.md-only {
        display: none;
      }

      &.btn-light {
        background-color: var(--pg-grey);
        font-weight: $pg-font-weight-body-regular;

        &:active {
          background-color: var(--pg-grey-medium);
        }
      }

      svg {
        width: 17px;
        height: 17px;
      }

      i {
        &.green {
          color: var(--pg-green-dark);
        }
      }
    }

    .contact-item {
      @include typography.hui-body-default-regular;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
}

@include media-breakpoint-down(md) {
  .agent-contact-info-root {
    flex-direction: row;
    justify-content: flex-start;
    gap: unset;
    padding: $pg-grid-space-unit-3x;

    .divider-root {
      display: none;
    }

    .avatar {
      width: 40px;
      height: 40px;
    }

    .agent-name-rating-root {
      gap: $pg-grid-space-unit;

      .agent-name {
        flex-grow: 1;
        max-width: 170px;
      }

      .agent-rating {
        padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
      }
    }

    .action-strip {
      margin-top: 0;
      display: flex;
      flex-direction: row;
      gap: $pg-grid-space-unit-2x;
      width: auto;

      .btn {
        padding: $pg-grid-space-unit-2x;
        font-size: 18px;

        &.md-only {
          display: block;
        }

        svg {
          width: 24px;
          height: 24px;
        }

        i {
          margin-right: 0;

          &::before {
            font-size: 24px;
          }
        }

        span {
          display: none;
        }
      }

      .contact-item {
        display: none;
      }
    }
  }
}

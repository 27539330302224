@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.complete-preferences-nudge-root {
  margin-bottom: $pg-grid-space-unit-4x;
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding: $pg-grid-space-unit-4x;
  border-radius: $pg-grid-space-unit-4x;
  background: $fill-neutral-primary;

  .nudge-icon {
    background: $fill-static-primary;
    border-radius: 100%;
    padding: $pg-grid-space-unit-2x;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .nudge-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .nudge-title {
      @include typographyV3.pg-font-label-m;
    }

    .nudge-description {
      @include typographyV3.pg-font-body-xs;
      margin-bottom: 0;
    }

    .btn-link {
      @include typographyV3.pg-font-body-xs;
      color: $text-active-primary;
      margin-top: $pg-grid-space-unit-2x;
      text-decoration: underline;
      margin-bottom: 0;
    }
    .btn-primary {
      @include typographyV3.pg-font-label-s;
      background-color: $pg-black;
      color: $fill-static-primary;
      border: none;
      margin-top: $pg-grid-space-unit-3x;
    }
    .active {
      background-color: $pg-black;
      color: $fill-static-primary;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.item-stack-root {
  a.expandable.expandable-item-stack,
  a.expandable.expandable-item-stack.hide-view-more {
    display: flex;

    .pgicon-arrow-down {
      transition: transform 0.15s ease-in-out;
      transform: none;
    }

    &.expand {
      .pgicon-arrow-down {
        transform: rotate(-180deg);
      }
    }
  }
  .link-action-strip-list-item {
    overflow: hidden;
    display: none;
    &.expand {
      display: block;
    }

    .actionable-link {
      &,
      &:hover {
        display: block; // anchor tag <a/> need this
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

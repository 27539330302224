@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.recent-search-root {
  padding: 0;
  gap: $pg-grid-space-unit-3x;

  .recent-search-headline {
    @include typographyV3.pg-font-title-xs;
    color: var(--text-active-primary);
    font-size: typographyV3.$pg-font-body-xs;
    padding: 0;
  }

  .recent-searches-item-root {
    gap: $pg-grid-space-unit-2x;

    &.dropdown-item {
      display: flex;
      color: var(--pg-grey-dark);
      padding: 0 $pg-grid-space-unit-2x;
      border-radius: $badge-border-radius;

      &:hover {
        background-color: var(--fill-hover-secondary);
      }

      i {
        margin-right: $pg-grid-space-unit-4x;
      }

      & + .dropdown-item {
        margin: 0;
      }

      .recent-searches-item__icon {
        margin-block: calc($pg-grid-space-unit / 2);
        flex-shrink: 0;
      }

      .recent-search-wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: $pg-grid-space-unit;

        .primary-title {
          @include typographyV3.pg-font-label-m;
          color: var(--text-active-primary);
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .secondary-title {
          @include typographyV3.pg-font-body-s;
          color: var(--text-active-secondary);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          -webkit-box-orient: vertical;
          white-space: normal;

          span::after {
            content: ''; /* represent `|` */
            height: 100%;
            margin: 0 $pg-grid-space-unit;
            border-right: 1px solid var(--border-active-primary);
          }

          span:last-child::after {
            display: none;
          }

          &:nth-child(n + 5) {
            display: none;
          }
        }
      }
    }
  }

  .recent-search__separator {
    width: 100%;
    border-top: 1px solid var(--border-active-secondary);
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'root/styles/core/default-theme';

.contact-agent-sheet-root {
  max-width: 100%;
  z-index: 1026 !important;
  .react-modal-sheet-backdrop {
    background-color: $pg-transparency-black-40 !important;
  }

  .hui-alert-root {
    position: absolute;
    bottom: calc(100% + $pg-grid-space-unit-3x);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: calc(100% - 2 * $pg-grid-space-unit-3x);

    .alert-title {
      @include typographyV3.pg-font-label-m;
    }

    .alert-description {
      @include typographyV3.pg-font-body-xs;
    }
  }

  .react-modal-sheet-drag-indicator {
    background-color: $fill-neutral-tertiary !important;
  }

  .react-modal-sheet-header {
    padding-bottom: $pg-grid-space-unit-3x;
    height: $pg-grid-space-unit-12x !important;
  }

  &.bottom-sheet {
    margin: 0;

    .bottom-sheet-container {
      height: unset !important;
      border-radius: $pg-grid-space-unit-6x $pg-grid-space-unit-6x 0 0 !important;
      box-shadow: $pg-soft-up-medium !important;

      .bottom-sheet-body {
        padding: 0;

        .share-profile-card {
          margin-top: 0;
          margin-right: $pg-grid-space-unit-6x;
          margin-left: $pg-grid-space-unit-6x;
        }

        .contact-agent-sheet-bottom {
          @include typography.hui-caption;
          padding: $pg-grid-space-unit-4x $pg-grid-space-unit-4x $pg-grid-space-unit-2x $pg-grid-space-unit-4x;
          border-radius: $pg-border-radius-small $pg-border-radius-small 0 0;

          a {
            text-decoration: none;
          }

          p {
            margin: 0;
          }
        }

        .contact-agent-sheet-body {
          padding: $pg-grid-space-unit-2x $pg-grid-space-unit-4x;

          .intent-alert {
            position: relative;
            margin: $pg-grid-space-unit-4x 0;
            width: 100%;
          }

          &.with-consent {
            padding: 0 $pg-grid-space-unit-4x $pg-grid-space-unit-2x $pg-grid-space-unit-4x;
          }

          .contact-agent-actions {
            display: flex;
            flex-direction: column;
            padding-top: $pg-grid-space-unit-4x;
            padding-bottom: $pg-grid-space-unit-4x;
            gap: $pg-grid-space-unit-3x;
            > * {
              @include typographyV3.pg-font-label-m;
              color: $text-static-secondary;
              box-shadow: $pg-soft-down-small;
              border: 1px solid $border-active-primary;
              width: 100%;

              i,
              img {
                margin-right: $pg-grid-space-unit;
              }

              img {
                font-size: 16px;
                line-height: 16px;
                width: 16px;
                height: auto;
              }
            }
          }

          .disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;

.search-with-filter-section {
  &-root {
    justify-content: center;
  }

  &-learn-more-link-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: var(--pg-grid-space-unit-4x);

    .actionable {
      @include typography.hui-body-default-regular;
      color: var(--pg-white);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: var(--pg-grid-space-unit);
      border: 0;

      &:hover,
      &:focus,
      &:active {
        color: var(--pg-white);
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;

.social-share-btn-group-root {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  column-gap: 30px;

  > .actionable-link {
    @include typography.hui-body-default-bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    width: 100%;
    border: none;
    color: var(--pg-white);
    border-radius: 8px;
    padding: 7px 10px;
    background-color: var(--pg-grey-darker);
    text-decoration: none;
    text-align: center;

    &::before {
      margin-right: 7px;
    }
  }

  .is-full-width {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .btn-default {
    background-color: var(--pg-grey-darker);

    &:hover {
      background-color: var(--pg-grey-dark);
    }

    &:active {
      background-color: var(--pg-black);
    }
  }

  .btn-line {
    background-color: var(--pg-btn-line-bg);

    &:hover {
      background-color: var(--pg-btn-line-bg-light);
    }

    &:active {
      background-color: var(--pg-btn-line-bg-dark);
    }
  }

  .btn-whatsapp {
    background-color: var(--pg-btn-whatsapp-bg);

    &:hover {
      background-color: var(--pg-btn-whatsapp-bg-light);
    }

    &:active {
      background-color: var(--pg-btn-whatsapp-bg-dark);
    }
  }

  .btn-facebook {
    background-color: var(--pg-btn-facebook-bg);

    &:hover {
      background-color: var(--pg-btn-facebook-bg-light);
    }

    &:active {
      background-color: var(--pg-btn-facebook-bg-dark);
    }
  }

  .btn-twitter {
    background-color: var(--pg-btn-twitter-bg);

    &:hover {
      background-color: var(--pg-btn-twitter-bg-light);
    }

    &:active {
      background-color: var(--pg-btn-twitter-bg-dark);
    }
  }
}

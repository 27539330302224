@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.contact-details-form-root {
  margin-top: 20px;

  .label-elaboration {
    @include typography.hui-body-default-regular;
    color: var(--pg-grey-dark);
  }

  .form-inputs {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 15px;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .input-field {
      width: 290px;
      align-items: flex-start;
    }

    .full-name-text,
    .mobile-number-text {
      &.form-control {
        background-image: none;
      }
    }

    .invalid-feedback {
      @include typography.hui-caption;
      color: var(--pg-orange-dark);
      padding: 0 0 10px 25px;
      text-indent: -1.5em;

      i {
        color: var(--pg-orange);
      }
    }
  }
}

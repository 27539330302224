@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.seo-segment-root {
  .seo-segment {
    a {
      display: inline-block;
      color: var(--pg-brand-primary);
      text-decoration: none;
    }
  }
  padding: 16px 0;
  @include typography.hui-body-default-regular;
  background: var(--pg-grey);

  &.visible {
    display: block;
  }
  .seo-segment-secondary {
    &.visible {
      display: block;
    }
    margin-top: 12px;
    display: none;

    &.read-more {
      cursor: pointer;
      display: block;
      @include typography.hui-body-default-bold;
      color: var(--pg-brand-primary);

      &.visible {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .seo-segment-root {
    padding-bottom: 32px;
    display: block;
  }
}

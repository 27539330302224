@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.rating-factor-container {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  flex-direction: column;
  align-items: baseline;
  border-bottom: 1px solid var(--pg-grey);
  padding: 10px 0 10px 0;

  .rating-title-row {
    gap: 5px;

    .title {
      @include typography.hui-heading4;
      padding-left: 5px;
      color: var(--pg-grey-darker);
    }

    .tooltip-icon {
      @include typography.hui-body-default-regular;
      width: 14px;
      height: 28px;
      color: var(--pg-grey-dark);
      padding: 2px 0;
      margin-left: inherit;
    }

    .tooltip-icon:hover {
      color: var(--pg-black);
    }
  }

  .rating-input-root {
    .rating-indicator-text {
      min-width: 80px;
    }
  }
}

@include media-breakpoint-up(md) {
  .rating-factor-container {
    flex-direction: row;

    .rating-title-row {
      .title {
        padding-left: 12px;
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.agent-review-modal-root {
  h3 {
    @include typography.hui-heading3;
  }

  h4 {
    @include typography.hui-heading4;
  }

  .modal-sub-title {
    @include typography.hui-body-big-regular;
    color: var(--pg-grey-dark);
    text-transform: unset;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .overall-rating-container {
      .experience-input-length {
        text-align: end;
      }

      .overall-experience {
        .overall-experience-input {
          border-radius: $pg-border-radius-medium;
          height: 84px;

          &.form-control {
            background-image: none;
          }
        }

        .experience-validation-text {
          margin-top: 5px;

          .valid-feedback {
            @include typography.hui-caption;
            display: inline;
            color: inherit;
          }

          .invalid-feedback {
            @include typography.hui-caption;
            display: inline;
            color: inherit;
          }
        }
      }

      .rating-input-root {
        padding: 0 10px 5px 10px;
      }
    }

    .rating-factors-container {
      .rating-factor-heading {
        padding-bottom: 8px;
        border-bottom: 1px solid var(--pg-grey);
      }

      .optional-text {
        @include typography.hui-heading4;
        color: var(--pg-grey-dark);
        margin-left: 10px;
      }

      .rating-factor-body {
        padding-left: 10px;
      }
    }

    .contact-details-root {
      padding-bottom: 20px;
    }
  }

  .modal-footer {
    border: 0;
    flex-direction: column-reverse;
    align-items: center;
    gap: $pg-grid-space-unit-4x;

    .terms-and-conditions {
      @include typography.hui-caption;
      color: var(--pg-grey-dark);
      text-align: center;

      a {
        text-decoration: none;
        color: var(--pg-brand-primary);
      }
    }

    .btn {
      align-self: stretch;
      text-wrap: nowrap;
    }
  }
}

@include media-breakpoint-up(md) {
  .agent-review-modal-root {
    .modal-dialog {
      min-width: 700px;
    }

    .modal-body {
      .rating-factors-container {
        .rating-factor-body {
          padding-left: 0;
        }
      }
    }

    .modal-footer {
      flex-direction: row;
      flex-wrap: nowrap;

      .terms-and-conditions {
        text-align: left;
      }
    }
  }
}

.contact-agent-modal-root {
  .modal-dialog {
    width: 100%;
    max-width: 450px;
    margin: auto;
    height: auto;
  }

  .contact-agent-card-root {
    border: none;

    .agent-info-root {
      padding: 0 16px 16px 16px;
    }

    .terms-and-policy {
      padding-bottom: 24px;
    }

    .card-body {
      border: 0;
      padding: 16px 0 0 0;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.hide-listing-popup-root {
  .modal-content {
    padding: 15px;
  }

  .hide-listing-popup-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      @include typography.hui-heading4;
    }

    .actionable {
      padding: 0;
      background-color: transparent;
      border: 0;
      color: var(--pg-black);
      font-size: 14px;

      &:active,
      &:focus {
        color: var(--pg-black);
        background-color: transparent;
      }
    }
  }

  .hide-listing-popup-body {
    margin-bottom: 10px;

    .error-message {
      @include typography.hui-body-default-regular;
      background-color: var(--pg-gold-lightest);
      color: var(--pg-gold-dark);
      margin-bottom: 20px;
      padding: 15px 20px;
    }

    > p {
      margin-bottom: 10px;
    }

    .title {
      @include typography.hui-body-default-regular;
      margin-bottom: 10px;
    }

    .badges-wrapper {
      display: flex;
      flex-wrap: wrap;

      .badge {
        text-transform: none;
        padding: 10px 16px;
        border-radius: 16px;
        cursor: pointer;
        margin-right: 5px;
        margin-bottom: 10px;
        color: var(--pg-black);
        background-color: var(--pg-grey);
        font-size: 12px;
        font-weight: normal;

        &.selected {
          background: var(--pg-brand-primary-lightest);
          color: var(--pg-brand-primary-dark);

          .pgicon-ok {
            display: inline;
          }
        }

        .pgicon-ok {
          color: var(--pg-brand-primary-dark);
          margin-right: 8px;
          display: none;
        }
      }
    }

    a {
      @include typography.hui-body-default-regular;
      color: var(--pg-brand-primary);
      text-decoration: none;
    }

    p {
      @include typography.hui-body-default-regular;
      margin: 0;
    }

    .text-area {
      @include typography.hui-body-default-regular;
      outline: none;
    }

    .optional-text {
      color: var(--pg-grey-dark);
      font-size: 12px;
      line-height: 18px;

      &.italic {
        font-style: italic;
      }
    }

    .character-limit-message {
      color: var(--pg-orange-dark);
    }
  }

  .hide-listing-popup-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .btn-primary.disabled,
    .btn-primary[disabled] {
      &:hover {
        background-color: var(--brand-primary);
        border-color: var(--brand-primary);
        color: var(--pg-logo-white);
      }
    }
  }
}

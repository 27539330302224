@import 'root/styles/core/default-theme';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.market-strip-root {
  .hui-image-root {
    // This is override from default width 100% to fix
    // issue on Safari browser that cause image to be stretched
    width: auto;
  }

  .market-strip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: $pg-grid-space-unit-4x auto;
  }

  .market-icons {
    display: flex;
    justify-content: center;
    padding: $pg-grid-space-unit-3x 0;
    margin-right: 0;
  }
}

@include media-breakpoint-up(sm) {
  .market-strip-root {
    .market-strip {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include media-breakpoint-up(lg) {
  .market-strip-root {
    .market-strip {
      display: flex;
      flex-direction: row;
    }

    .market-icons {
      margin-right: auto;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.nudge-root {
  display: flex;
  position: fixed;
  z-index: 100;
  left: 15px;
  margin-right: 15px;
  bottom: 60px;
  box-shadow: $pg-box-shadow-soft;
  border: 1px solid var(--pg-grey-dark);
  border-radius: 4px;
  background-color: var(--pg-white);
  padding: 20px 44px 20px 20px;

  .icon-left {
    margin-right: 10px;
    display: none;
  }

  .nudge-body {
    p {
      @include typography.hui-body-default-regular;
      color: var(--pg-black);
      margin-bottom: 5px;
    }

    a {
      @include typography.hui-body-default-regular;
      color: var(--pg-brand-primary);
      text-decoration: none;
    }
  }

  .icon-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  @include media-breakpoint-up(md) {
    position: relative;
    z-index: unset;
    left: unset;
    bottom: unset;
    padding: 15px 10px;
    background-color: var(--pg-grey);
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--pg-grey-medium);
    box-shadow: unset;
    margin-right: 0;

    .icon-left {
      display: block;
    }

    .icon-close {
      display: none;
    }
  }
}

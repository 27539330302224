@use 'root/styles/core/typography-mixins.scss' as typography;

.rating-input-root {
  .rating-div {
    display: flex;
    padding: 0 16px 0 0;
    gap: 10px;
    flex-grow: 0;

    .star {
      font-size: 20px;
      color: var(--pg-grey-medium);

      &.filled {
        color: var(--pg-gold);
      }
    }
  }

  .rating-indicator-text {
    @include typography.hui-body-default-regular;
    padding: 5px 0;

    &.placeholder-text {
      color: var(--pg-grey-dark);
    }
  }
}

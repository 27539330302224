@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';

.hui-phone-input-root {
  width: 100%;

  .react-tel-input {
    .form-control {
      width: calc(100% - 72px);
      margin-left: 72px;
      height: 100%;
      z-index: $pg-z-index-4;
      border-radius: 0 100px 100px 0;
      padding-left: 12px;
    }

    .flag-dropdown {
      background-color: var(--pg-white);
      width: 100%;
      height: 100%;
      padding: 12px;
      border-radius: 100px;

      .selected-flag {
        padding: 0;

        .arrow {
          display: none;
        }

        .flag {
          position: relative;
          margin: 0;
          top: 3px;
          left: 5px;

          &.my,
          &.th,
          &.sg,
          &.id,
          &.vn {
            top: 0;
            left: 0;
          }
        }

        &:after {
          position: absolute;
          left: 36px;
          top: 3px;
          color: var(--pg-black);
          content: $pg-icon-arrow-down-code;
          font-size: 12px;
          font-family: 'pgicons', 'guru-icons';
          line-height: 12px;
        }

        &:hover {
          background-color: inherit;
        }

        &[aria-expanded='true'] {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }

    %custom-flag {
      width: 28px;
      height: 20px;
      background-position: 0 0;
      transform: none;
      margin: 2px 7px 0 0;
    }

    .flag {
      transform: scale(1.7);
      margin: 5px 10px 0 4px;

      &.my {
        @extend %custom-flag;
        background-image: url('https://cdn.pgimgs.com/hive-ui/static/v0.1.5/flags/malaysia.svg');
      }
      &.th {
        @extend %custom-flag;
        background-image: url('https://cdn.pgimgs.com/hive-ui/static/v0.1.5/flags/thailand.svg');
      }
      &.sg {
        @extend %custom-flag;
        background-image: url('https://cdn.pgimgs.com/hive-ui/static/v0.1.5/flags/singapore.svg');
      }
      &.id {
        @extend %custom-flag;
        background-image: url('https://cdn.pgimgs.com/hive-ui/static/v0.1.5/flags/indonesia.svg');
      }
      &.vn {
        @extend %custom-flag;
        background-image: url('https://cdn.pgimgs.com/hive-ui/static/v0.1.5/flags/vietnam.svg');
      }
    }

    .country-list {
      width: 100%;
      max-height: 216px;
      box-shadow: 0 0 1px 1px var(--pg-grey-medium);
      margin-left: -12px;
      border: 1px solid var(--pg-grey);
      border-radius: 8px;

      .country {
        @include typography.hui-body-default-regular;

        display: flex;
        padding: 16px;
        border-bottom: 1px solid var(--pg-grey);

        .dial-code {
          color: var(--pg-black);

          &:before {
            content: '(';
          }

          &:after {
            content: ')';
          }
        }
      }
    }
  }

  &:disabled {
    .flag-dropdown {
      background-color: var(--pg-grey);
    }
  }
}

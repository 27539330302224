@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.search-segment-root {
  display: flex;
  justify-content: center;

  .search-segment-container {
    border-radius: 0;
    width: 100%;
    background: var(--pg-blue);
    margin: auto;
    min-height: 158px;
  }

  .search-segment-nav {
    display: flex;
    justify-content: center;
    margin: auto;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: $pg-grid-space-unit-4x 0;

    .nav {
      position: relative;
      display: flex;
      background: var(--pg-white);
      border-radius: $pg-grid-space-unit-15x;

      .search-segment-nav-item {
        @include typography.hui-experimental-heading3;
        color: var(--pg-black);
        background: var(--pg-white);
        border-radius: $pg-grid-space-unit-15x;
        border: 1px solid var(--pg-white);
        padding: $pg-grid-space-unit $pg-grid-space-unit-6x;
        white-space: nowrap;
        transition:
          color 0.3s,
          background-color 0.3s;
        position: relative;

        @keyframes move {
          0% {
            transform: translateX(var(--translate-x-value));
            width: var(--active-element-width);
          }
          100% {
            transform: translateX(0);
            width: 100%;
          }
        }

        &.active {
          color: var(--pg-white);
          z-index: $pg-z-index-1;

          &::after {
            content: '';
            position: absolute;
            display: inline-block;
            color: var(--pg-white);
            background-color: var(--pg-brand-primary);
            border-radius: $pg-grid-space-unit-15x;
            width: 100%;
            height: 100%;
            animation-name: move;
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
            top: 0;
            left: 0;
            z-index: $pg-z-index-neg-1;
            transition: 0.25s ease-out;
          }
        }
      }
    }
  }

  .search-with-filter-root {
    padding-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@include media-breakpoint-up(sm) {
  .search-segment-root {
    .search-segment-nav {
      padding: $pg-grid-space-unit-6x 0 $pg-grid-space-unit-4x;
    }

    .search-segment-container {
      min-height: 180px;
    }
  }
}

@include media-breakpoint-up(md) {
  .search-segment-root {
    .search-segment-container {
      min-height: 190px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .search-segment-root {
    .search-segment-container {
      width: 546px;
      background: var(--pg-blue-80);
      border-radius: $pg-grid-space-unit-4x;
    }
  }
}

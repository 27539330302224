@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.listing-enquiry-info-root {
  width: 100%;
  padding: $pg-grid-space-unit-6x;
  display: flex;
  flex-direction: column;
  gap: $pg-grid-space-unit-3x;

  @include media-breakpoint-down(md) {
    padding: $pg-grid-space-unit-3x;
    gap: $pg-grid-space-unit-2x;
  }

  .listing-info-root {
    display: grid;
    grid-template-columns: auto 1fr;

    img {
      width: 120px;
      height: 90px;
      object-fit: cover;
      border-radius: 5px;
      grid-row-start: 1;
      grid-row-end: 3;

      @include media-breakpoint-down(md) {
        width: 73px;
        height: 55px;
        align-self: center;
        grid-row-end: 2;
      }
    }

    .info-wrapper {
      padding-left: 15px;

      h4 {
        @include typography.hui-heading4;
        color: var(--pg-black);
        margin: 0;
      }

      h5 {
        @include typography.hui-heading5;
        color: var(--pg-black);
        margin: 0;
      }

      .info-price-availability {
        display: flex;
        align-items: center;
        gap: $pg-grid-space-unit-2x;

        &.mobile-column {
          @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: flex-start;
            gap: 0;
          }
        }
      }

      p {
        @include typography.hui-body-default-regular;
        color: var(--pg-grey-darker);
        margin: 0;

        span {
          margin-right: $pg-grid-space-unit-2x;
        }
      }

      &.md-full {
        @include media-breakpoint-down(md) {
          grid-column-start: 1;
          grid-column-end: 3;
          margin-top: 10px;
          padding-left: 0;
        }
      }
    }

    .list-type-icon {
      background-color: var(--pg-grey-medium);
      border-radius: 50%;
      display: inline-block;
      height: 4px;
      vertical-align: middle;
      width: 4px;
    }

    .finance-ipa-link {
      @include typography.hui-body-default-bold;
      color: var(--pg-brand-primary);

      @include media-breakpoint-down(sm) {
        font-size: $pg-font-size-caption;
        line-height: $pg-line-height-caption;
      }
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: $pg-grid-space-unit-3x;

    @include media-breakpoint-down(md) {
      padding-bottom: 0;
    }

    .listing-link {
      display: contents;
      text-decoration: none;
    }

    .actions-wrapper {
      position: relative;
      display: inline-grid;
      width: 100%;
    }

    .left-arrow-button {
      position: absolute;
      padding: 5px;
      cursor: pointer;
      width: 20px;
      border: none;
      &:active,
      &:hover {
        background: unset;
      }
      .pgicon {
        font-size: 24px;
      }
    }

    .right-arrow-button {
      position: absolute;
      padding: 5px;
      cursor: pointer;
      width: 20px;
      right: 0;
      border: none;
      &:active,
      &:hover {
        background: unset;
      }
      .pgicon {
        font-size: 24px;
      }
    }

    .action-strip {
      flex-wrap: nowrap;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin-left: calc($pg-grid-space-unit-2x * -1);

      .listing-enquiry-info-actionable {
        padding: $pg-grid-space-unit-2x;
        margin-right: unset;

        .pgicon {
          margin-right: $pg-grid-space-unit;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .btn {
        @include typography.hui-heading5;
        color: var(--pg-black);

        &.btn-light {
          color: var(--pg-black);
        }

        &.btn-secondary {
          color: var(--pg-white);
        }

        svg {
          width: 14px;
          height: 14px;
          margin-right: $pg-grid-space-unit;
        }
      }
    }
  }

  .card-footer {
    @include typography.hui-caption;
    color: var(--pg-grey-darker);
    padding-top: $pg-grid-space-unit-4x;

    @include media-breakpoint-down(md) {
      padding-top: $pg-grid-space-unit-2x;
    }

    .status-title {
      display: flex;

      // No design for v3 yet, will use in guruland. Will update it with Typography mixin once have v3 design
      // Figma ref: https://www.figma.com/design/C7AcI81wdwfwiwgN9DrD46/Enquiry-2.0?node-id=1452-145999&t=3wLG3T61ExMYbb3n-0
      color: var(--pg-black);
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }

    .status-icon {
      margin-right: $pg-grid-space-unit-2x;
      // No design for v3 yet, will use in guruland. Will update it with Typography mixin once have v3 design
      color: var(--pg-grey-darker);
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }

    .status-description {
      padding-top: $pg-grid-space-unit-3x;

      @include media-breakpoint-up(md) {
        padding-top: $pg-grid-space-unit-2x;
      }
    }
  }
}

.popover {
  &.onboarding-popover-root {
    background-color: var(--pg-purple-dark);
    max-width: 255px;
    width: 255px;

    &[data-popper-placement='right'] {
      .popover-arrow {
        &::after {
          border-right-color: var(--pg-purple-dark);
        }
      }
    }

    &[data-popper-placement='top'] {
      .popover-arrow {
        bottom: -6.6px;

        &::after {
          border-top-color: var(--pg-purple-dark);
        }
      }
    }

    &[data-popper-placement='bottom'] {
      .popover-arrow {
        top: -6.6px;

        &::after {
          border-bottom-color: var(--pg-purple-dark);
        }
      }
    }

    .popover-header {
      @include typography.hui-body-big-bold;
      color: var(--pg-white);
      background-color: transparent;
      padding: 15px 15px 0 15px;
      border-bottom: 0;

      &::before {
        border-bottom: 0;
      }
    }

    .popover-body {
      @include typography.hui-body-default-regular;
      color: var(--pg-white);
      padding: 15px;
    }

    .btn-notify {
      @include typography.hui-body-default-bold;
      background-color: var(--pg-white);
      color: var(--pg-black);
      border: 1px solid var(--pg-black);
      margin-top: 10px;

      &:focus {
        color: var(--pg-black);
      }

      &:active {
        background-color: var(--pg-grey-medium);
        border: 1px solid var(--pg-black);
      }

      i {
        margin-right: 5px;
      }
    }
  }
}

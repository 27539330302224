@use 'root/styles/core/typography-mixins.scss' as typography;

.top-location-list {
  &-heading {
    @include typography.hui-heading5;
    color: var(--pg-black);
  }

  &-wrap {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  &-item {
    @include typography.hui-body-default-regular;
    display: flex;
    align-items: flex-start;
    gap: 4px;
    color: var(--pg-grey-darker);

    & .pgicon {
      font-size: 8px;
      color: var(--pg-black);
    }
  }
  &-empty {
    @include typography.hui-body-default-regular;
    color: var(--pg-grey-dark);
    margin-bottom: 0;
  }
}

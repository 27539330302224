@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.checkbox-list-root {
  &.hidden {
    display: none;
  }

  .no-filters-found {
    @include typographyV3.pg-font-body-xs;
    color: var(--pg-text-active-primary, #0d1011);
    padding: 10px;
    margin-left: 10px;
  }

  display: flex;
  flex-direction: column;

  .hui-input-root {
    i {
      font-size: 24px;
    }

    .form-control {
      @include typographyV3.pg-font-body-xs;
    }
  }

  .checkbox-label {
    color: var(--pg-text-active-primary, #0d1011);
    justify-content: space-between;
    cursor: pointer;
    width: 100%;

    .checkbox-wrapper {
      order: 1;
    }
  }
}

@include media-breakpoint-up(sm) {
  .checkbox-list-root {
    .checkbox-label {
      width: auto;
      justify-content: flex-start;

      .checkbox-wrapper {
        order: 0;
      }
    }
  }
}

@use './default-theme' as *;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Override scss for V3 design
/* Typography */
$pg-font-body: 'Inter', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$pg-line-height: 1.4;
$pg-font-weight-heading: 600;
$pg-letter-spacing: -0.4px;

// Sizing
$pg-font-display: 4.25rem;
$pg-font-headline: 3.5rem;
$pg-font-title-xl: 3rem;
$pg-font-title-l: 2.5rem;
$pg-font-title-m: 2.125rem;
$pg-font-title-s: 1.75rem;
$pg-font-title-xs: 1.5rem;
$pg-font-body-l: 1.5rem;
$pg-font-body-m: 1.125rem;
$pg-font-body-s: 1rem;
$pg-font-body-xs: 0.875rem;
$pg-font-label-l: 1.25rem;
$pg-font-label-m: 1rem;
$pg-font-label-s: 0.875rem;
$pg-font-label-xs: 0.875rem;
$pg-font-caption: 0.875rem;
$pg-font-caption-s: 0.75rem;
$pg-font-caption-xs: 0.75rem;
$pg-font-caption-m: 0.875rem;

// Mixins
@mixin decoration {
  &--strikethrough {
    text-decoration: line-through;
  }

  &--underline {
    text-decoration: underline;
  }
}

@mixin heading-common {
  font-family:
    #{$pg-font-heading},
    var(--bs-font-sans-serif);
  font-weight: #{$pg-font-weight-heading};
  line-height: #{$pg-line-height};
  letter-spacing: #{$pg-letter-spacing};
}

@mixin pg-font-display {
  @include heading-common;
  font-size: #{$pg-font-display};
}

@mixin pg-font-headline {
  @include heading-common;
  font-size: #{$pg-font-headline};
}

@mixin pg-font-title-xl {
  @include heading-common;
  font-size: #{$pg-font-title-xl};
}

@mixin pg-font-title-l {
  @include heading-common;
  font-size: #{$pg-font-title-l};
}

@mixin pg-font-title-m {
  @include heading-common;
  font-size: #{$pg-font-title-m};
}

@mixin pg-font-title-s {
  @include heading-common;
  font-size: #{$pg-font-title-s};
}

@mixin pg-font-title-xs {
  @include heading-common;
  font-size: #{$pg-font-title-xs};
}

@mixin body-common {
  font-family:
    #{$pg-font-body},
    var(--bs-font-sans-serif);
  font-weight: #{$pg-font-weight-body-regular};
  line-height: #{$pg-line-height};
  letter-spacing: #{$pg-letter-spacing};
}

@mixin pg-font-body-l {
  @include body-common;
  font-size: #{$pg-font-body-l};
}

@mixin pg-font-body-m {
  @include body-common;
  font-size: #{$pg-font-body-m};
}

@mixin pg-font-body-s {
  @include body-common;
  font-size: #{$pg-font-body-s};
}

@mixin pg-font-body-xs {
  @include body-common;
  font-size: #{$pg-font-body-xs};
}

@mixin pg-font-label-l {
  @include heading-common;
  font-size: #{$pg-font-label-l};
}

@mixin pg-font-label-m {
  @include heading-common;
  font-size: #{$pg-font-label-m};
}

@mixin pg-font-label-s {
  @include heading-common;
  font-size: #{$pg-font-label-s};
}

@mixin pg-font-label-xs {
  @include heading-common;
  font-size: #{$pg-font-label-xs};
  font-weight: #{$pg-font-weight-body-regular};
}

@mixin pg-font-caption {
  @include heading-common;
  font-size: #{$pg-font-caption};
}

@mixin pg-font-caption-s {
  @include heading-common;
  font-size: #{$pg-font-caption-s};
}

@mixin pg-font-caption-xs {
  @include heading-common;
  font-size: #{$pg-font-caption-xs};
  font-weight: #{$pg-font-weight-body-regular};
}

@mixin pg-font-caption-m {
  @include heading-common;
  font-size: #{$pg-font-caption-m};
}

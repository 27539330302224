@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.past-enquiry-view-root {
  .hide-listing-nudge-root {
    margin-top: 20px;
  }

  .profile-banner-root {
    margin-top: 20px;
  }

  .past-enquiry-finance-banner {
    margin-block: $pg-grid-space-unit-4x;
  }
}
.enquiry-card-root {
  width: 100%;
  position: relative;

  &.loading {
    .listing-info-image {
      width: 120px;
      height: 90px;
      border-radius: $pg-grid-space-unit;
      grid-row-start: 1;
      grid-row-end: 3;
    }

    .heading {
      width: 235px;
      height: 26px;
    }

    .details {
      width: 147px;
      height: 20px;
      margin-top: $pg-grid-space-unit;
    }

    .body {
      width: 217px;
      height: 20px;
      margin-top: $pg-grid-space-unit;
    }

    .description {
      display: none;
    }

    .btn-wrapper {
      display: flex;
      padding-left: $pg-grid-space-unit-2x;
    }

    .btn-share {
      width: 61px;
      height: 25px;
    }

    .btn-calendar {
      width: 79px;
      height: 25px;
      margin-left: $pg-grid-space-unit-4x;
    }

    .btn-add-note {
      width: 50px;
      height: 25px;
      margin-left: $pg-grid-space-unit-4x;
    }

    .history-text {
      width: 237px;
      height: 18px;
      max-width: 100%;
    }

    .agent-contact-info-body {
      display: flex;
      align-items: center;
    }

    .horizontal-divider {
      height: 1px;
      background-color: var(--pg-grey);
      width: 100%;
    }

    .avatar {
      margin-right: $pg-grid-space-unit-3x;
    }

    .agent-name {
      width: 217px;
      height: 24px;
      margin-top: $pg-grid-space-unit-4x;
    }

    .agent-description {
      width: 117px;
      height: 28px;
      margin-top: $pg-grid-space-unit-2x;
      border-radius: 100px;
    }

    .btn-whatsapp {
      height: 48px;
      padding: $pg-grid-space-unit-3x $pg-grid-space-unit-6x;
      display: flex;
      align-items: flex-start;
      align-self: stretch;
    }

    .btn-phone {
      height: 48px;
      padding: $pg-grid-space-unit-3x $pg-grid-space-unit-6x;
      display: flex;
      align-items: flex-start;
      align-self: stretch;
    }

    .btn-sms {
      display: none;
    }

    @include media-breakpoint-down(lg) {
      .horizontal-divider {
        display: none;
      }

      .agent-name {
        width: 100%;
        height: 24px;
        display: flex;
        align-items: flex-start;
        align-self: stretch;
      }
    }

    @include media-breakpoint-down(sm) {
      .listing-info-image {
        width: 73px;
        height: 55px;
        grid-row-end: 2;
      }

      .heading {
        width: 100%;
        height: 26px;
        align-self: stretch;
      }

      .body {
        display: none;
      }

      .description {
        display: block;
        width: 237px;
        height: 18px;
      }

      .btn-share {
        width: 61px;
        height: 25px;
      }

      .btn-calendar {
        width: 79px;
        height: 25px;
      }

      .btn-add-note {
        width: 50px;
        height: 25px;
      }

      .agent-name {
        width: 140px;
        height: 14px;
        margin-top: 0;
        flex-grow: unset;
      }

      .agent-description {
        width: 117px;
        height: 24px;
      }

      .horizontal-divider {
        display: none;
      }

      .agent-contact-info-root {
        .action-strip {
          margin-left: $pg-grid-space-unit-2x;
          flex-grow: 1;
          justify-content: flex-start;
        }
      }

      .btn-whatsapp {
        width: 40px;
        height: 40px;
        padding: 0;
      }

      .btn-phone {
        display: none;
      }

      .btn-sms {
        display: block;
        width: 40px;
        height: 40px;
        padding: 0;
      }
    }
  }
}

.past-enquiry-empty-wrapper {
  max-width: 772px;
  margin: 0 auto;
}

.enquiry-card-inactive-listing-root {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--pg-grey-80);
  border: 2px solid var(--pg-grey-medium);
  border-radius: 5px;
  z-index: $pg-z-index-1;

  .enquiry-card-inactive-listing-btn {
    color: var(--pg-grey-darker);
  }
}

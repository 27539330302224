@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.link-stack-root {
  margin-top: 0;
  h5 {
    margin: 0;
    .expandable {
      justify-content: space-between;

      .pgicon-arrow-down {
        transition: transform 0.15s linear;
        transform: none;
      }

      &.expand-stack {
        .pgicon-arrow-down {
          transform: rotate(180deg);
        }
      }
    }
  }
  .link-stack-items {
    display: none;
    .view-more-links {
      display: none;
    }
    .hide-view-more,
    &.expand,
    &.expand-stack {
      display: block;
    }
  }
}

@include media-breakpoint-up(sm) {
  .link-stack-root {
    h5 {
      .expandable {
        .pgicon-arrow-down {
          display: none;
        }
      }
    }
    overflow: hidden;
    .link-stack-items {
      display: flex;
      flex-direction: column;
      .view-more-links {
        display: block;
      }
      .hide-view-more {
        overflow: hidden;
        display: none;
      }
      .hide-view-more.expand-stack {
        display: block;
      }
    }
    .link-action-strip-list-item {
      overflow: hidden;
      display: none;
    }
  }
}

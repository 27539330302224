@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/themes/pg-v2/mixins.scss' as mixinsV2;
@import 'root/styles/core/default-theme';

.hide-listing-denial-root {
  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-dialog .modal-content {
    width: 330px;
    min-height: 254px;
    border-radius: $pg-grid-space-unit-2x;
  }

  .hide-listing-denial {
    &__close {
      font-size: var(--pg-font-size-heading-2);
      color: var(--pg-grey-darker);
      cursor: pointer;
      line-height: 32px;
    }

    &__header {
      display: flex;
      align-items: center;
      padding: $pg-grid-space-unit-4x $pg-grid-space-unit-6x;

      &-title {
        @include typography.hui-heading3;
        flex: 1;
      }
    }

    &__body {
      @include typography.hui-body;
      padding: $pg-grid-space-unit-4x $pg-grid-space-unit-6x $pg-grid-space-unit-6x;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: $pg-grid-space-unit-6x;
    }

    &__actionable.btn.btn-outline-secondary {
      @include typography.hui-button;
      @include mixinsV2.hui-button-secondary-outline-light;
      padding: $pg-grid-space-unit-2x $pg-grid-space-unit-4x;
      align-self: flex-start;
    }
  }
  &.hide-listing-denial--bottomsheet.offcanvas.offcanvas-bottom {
    height: 254px;
    border-radius: $pg-grid-space-unit-4x $pg-grid-space-unit-4x 0 0;
    padding: unset;
  }
}

@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.contact-agent-sticky-bar-root {
  position: fixed;
  z-index: $pg-z-index-1;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: var(--fill-neutral-secondary);
  box-shadow: $pg-soft-up-small;
  border-top: 1px solid var(--pg-grey-medium);

  .agent-info-root {
    .avatar-wrapper {
      margin-right: $pg-grid-space-unit-2x;
      width: 50px;
      height: 50px;
    }

    .agent-badge-root.verification.no-text {
      position: absolute;
      bottom: -4px;
      left: -4px;
    }

    .details-wrapper {
      .agent-name {
        @include typographyV3.pg-font-body-xs;
        color: var(--text-active-primary);
      }
    }

    .agent-rating {
      @include typographyV3.pg-font-caption-xs;
      color: var(--text-active-primary);
    }

    .agent-certificate {
      @include typographyV3.pg-font-caption-s;
      margin-top: 0;
    }
  }

  .actionable-links {
    display: flex;
    align-items: center;

    .actionable-link,
    .actionable-text {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      white-space: nowrap;

      &.btn-secondary {
        border-color: var(--pg-grey-medium);
        padding: $pg-grid-space-unit-2x;
      }

      &.btn-primary {
        @include typographyV3.pg-font-label-s;
        padding: $pg-grid-space-unit-2x $pg-grid-space-unit-4x;
      }

      i,
      img {
        width: 24px;
        height: auto;
        font-size: 24px;
        line-height: 24px;
      }

      .label {
        display: none;
      }

      &:active {
        color: var(--pg-white);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .contact-agent-sticky-bar-root {
    .actionable-links {
      .actionable-link,
      .actionable-text {
        padding: 8px 12px;

        i,
        img {
          margin-right: 4px;
        }

        .label {
          display: block;
        }
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;

.checklist-root {
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  .item {
    @include typography.hui-body-default-regular;
    color: var(--pg-grey-darker);
    display: flex;
    flex-direction: initial;

    .pgicon-ok {
      font-size: 13px;
      margin-right: 10px;
    }
  }
}

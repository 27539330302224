@use 'root/styles/core/typography-mixins.scss' as typography;

.otp-input-group-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .otp-input-group {
    width: 100%;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    justify-content: center;
    grid-auto-flow: column;
    grid-gap: 10px;

    .box-input {
      @include typography.hui-body-big-regular;
      color: var(--pg-black);
      border: 1px solid var(--pg-grey-medium);
      border-radius: 8px;
      outline: none;
      padding: 4px 0;
      text-align: center;
      -moz-appearance: textfield;

      &.not-empty {
        border-color: var(--pg-black);
      }

      &:focus {
        caret-color: var(--pg-brand-primary);
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &.error {
    .otp-input-group {
      .box-input {
        border-color: var(--pg-orange);
        background-color: var(--pg-orange-lightest);
      }
    }
  }

  .error-message {
    @include typography.hui-caption;
    color: var(--pg-orange-dark);
    padding: 0;
    margin-top: 5px;
    text-align: center;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.notification-panel-root.offcanvas {
  background-color: var(--pg-grey);
  width: 100%;

  .notification-panel-header {
    @include typography.hui-heading5;
    background-color: var(--pg-black);
    color: var(--pg-white);
    padding: 12px 15px;

    .offcanvas-title {
      font-size: 14px;
    }

    .drawer-close-btn,
    .drawer-referesh-btn {
      background-color: var(--pg-black);
      box-shadow: none;
      border: none;
      font-size: 18px;
      padding: 0;
    }

    .drawer-referesh-btn:disabled {
      opacity: 1;
    }
  }

  .notification-panel-body {
    @include typography.hui-body-default-regular;
    display: flex;

    .body-content {
      width: 100%;
      height: fit-content;
    }

    .empty-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      text-align: center;

      .hui-image-root {
        width: unset;
      }

      .title {
        @include typography.hui-heading4;
        margin: 20px 0 10px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .notification-panel-root.offcanvas {
    width: 360px;
  }
}

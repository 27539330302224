@import 'root/styles/core/default-theme';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.search-accessory-root {
  display: flex;
  flex-direction: column;
  gap: $pg-grid-space-unit-3x;
  padding: $pg-grid-space-unit-3x $pg-grid-space-unit-2x;

  &.search-accessory {
    &--no-padding {
      padding: 0;
    }
  }

  .search-accessory {
    &__separator {
      width: 100%;
      border-top: 1px solid var(--border-active-secondary);
    }
  }
}

@include media-breakpoint-up(sm) {
  .search-accessory-root {
    padding-block: $pg-grid-space-unit-4x;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.mobile-number-verification-root {
  &.hui-modal-root {
    .modal-dialog {
      .modal-body {
        padding-bottom: 40px;
      }
    }
  }

  .mobile-number-verification-header {
    border-bottom: none;
  }

  .mobile-number-verification-body {
    padding: 0 24px;
    align-items: center;

    .mobile-number-verification-image {
      width: 130px;
      height: 130px;
    }

    .mobile-number-verification-title {
      @include typography.hui-heading2;
      margin: 15px 0;
    }

    .mobile-number-verification-description {
      @include typography.hui-body-default-regular;
      max-width: 452px;
      margin-bottom: 20px;
    }

    .verify-mobile-input-title {
      @include typography.hui-caption;
      margin-bottom: $pg-grid-space-unit;
      text-align: start;
    }

    .hui-phone-input-root {
      &.error .hui-phone-input {
        border-color: var(--pg-orange);
      }
    }

    .verify-mobile-input-error {
      @include typography.hui-caption;
      margin-top: $pg-grid-space-unit;
      color: var(--pg-orange-dark);
      text-align: start;
    }

    .mobile-number-verification-button {
      display: block;
      width: 100%;
      margin-top: $pg-grid-space-unit-5x;
    }
  }
}

@include media-breakpoint-up(md) {
  .mobile-number-verification-root {
    &.hui-modal-root {
      .modal-dialog {
        height: auto;
      }
    }

    .mobile-number-verification-body {
      .mobile-number-verification-title {
        @include typography.hui-heading3;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .mobile-number-verification-root {
    .mobile-number-verification-body {
      .mobile-number-verification-title {
        margin: 16px 0;
      }

      .mobile-number-verification-description {
        margin-bottom: 16px;
      }

      .generic-input-group-root {
        .generic-input-btn {
          margin-top: 16px;
        }
      }
    }
  }
}

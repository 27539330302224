@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.empty-view-root {
  display: flex;
  flex-direction: column;

  .empty-view-info {
    background-color: var(--pg-grey-lighter);
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    padding-bottom: $pg-grid-space-unit-6x;

    .empty-view-heading {
      @include typography.hui-heading4;
      margin: 0;
    }

    .empty-view-body {
      @include typography.hui-body-default-regular;
      margin: $pg-grid-space-unit 0 0 0;
    }

    .empty-view-button {
      margin-top: $pg-grid-space-unit-5x;
    }
  }

  .empty-view-image {
    background-color: var(--pg-grey-lighter);
    min-height: 122px;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    img {
      height: fit-content;
    }
  }

  @include media-breakpoint-down(sm) {
    .empty-view-info {
      order: 2;
      margin-right: 0;
      padding: $pg-grid-space-unit-6x $pg-grid-space-unit-4x;

      .empty-view-button {
        margin-bottom: 0;
      }
    }
  }
}

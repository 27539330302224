@use 'root/styles/core/typography-mixins.scss' as typography;

.hui-loader-root {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-content {
    text-align: center;
  }

  .loader-text {
    @include typography.hui-label;
    margin: 8px 0 0 0;
    color: var(--pg-black);
  }

  .spinner {
    width: 24px;
    height: 24px;
  }

  &.light {
    .spinner {
      color: var(--pg-grey-darker);
    }

    &.backdrop {
      background-color: var(--pg-white-80);
    }
  }

  &.dark {
    .spinner,
    .loader-text {
      color: var(--pg-grey);
    }

    &.backdrop {
      background-color: var(--pg-grey-darker-80);
    }
  }
}

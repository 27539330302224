@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'root/styles/core/default-theme';

.imported-review-root {
  @include typography.hui-caption;
  display: flex;
  color: var(--pg-grey-dark);

  & .link-primary img {
    margin-left: $pg-grid-space-unit;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.pg-legal-info-root {
  @include typography.hui-body-default-regular;
  padding: 16px 0;

  .actionable.read-more {
    @include typography.hui-button;
    font-family: $pg-font-body;
    color: var(--pg-brand-primary);
    text-decoration: none;
    display: none;

    &.show {
      display: inline-block;
    }
  }

  .pg-legal-info-secondary {
    &.show {
      display: block;
    }
    margin-top: 12px;
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .pg-legal-info-root {
    padding-bottom: 32px;
    display: block;
  }
}

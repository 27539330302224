@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.offcanvas-backdrop:has(+ .hui-bottom-sheet).show {
  opacity: 0.6;
}

.hui-bottom-sheet {
  &.rounded-with-gutter {
    margin: $pg-grid-space-unit-3x;
    border-radius: $pg-border-radius-small;
  }

  &.offcanvas {
    .offcanvas-header {
      padding: $pg-grid-space-unit-4x;
      .offcanvas-title {
        @include typography.hui-heading4;
      }

      .hui-btn-close {
        color: var(--pg-grey-medium);

        i {
          font-size: 14px;
        }
      }
    }

    .offcanvas-body {
      @include typography.hui-body-default-regular;
      padding: $pg-grid-space-unit-4x;
      color: var(--pg-grey-darker);
    }
  }
}

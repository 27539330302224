@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

$list-item-height: 46px;

.property-type-filter-root {
  .dropdown-menu {
    min-width: 320px;
  }

  .filter-root {
    .dropdown-toggle:active {
      color: var(--pg-white);
    }
  }

  .types-container {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    flex-wrap: wrap;

    .type-item {
      padding: 5px 15px;
      background: var(--pg-grey);
      color: var(--pg-grey-darker);
      border-radius: 16px;
      cursor: pointer;
      font-size: 14px;

      &.selected {
        background: var(--pg-blue);
        color: var(--pg-brand-primary-dark);
        background-color: var(--pg-brand-primary-lightest);
      }
    }
  }
  .list-items-container {
    max-height: 3.5 * $list-item-height;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 -16px;
  }
  .list-item {
    position: relative;
    background-color: var(--pg-grey-lighter);
    @include typography.hui-body-default-bold;

    .form-check {
      display: flex;
      align-items: center;
      margin-left: 16px;
      width: 100%;
      margin-bottom: 0;

      .form-check-label {
        cursor: pointer;
        padding: 11px 16px;
        width: 100%;
        font-size: $pg-font-size-body-regular;
      }

      .form-check-input {
        margin-top: 0;
        width: 14px;
        height: 14px;
        background: inherit;
      }
    }

    .radio {
      input[type='radio'] {
        &:before {
          left: -1px;
          top: -5px !important;
        }

        &:checked {
          &::before {
            color: var(--pg-white);
          }
        }
      }
    }

    &.selected {
      background-color: var(--pg-grey-darker);
      color: var(--pg-white);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .mini-label {
        @include typography.hui-caption;
        color: var(--pg-white);
        margin-right: 16px;
      }
    }

    .sub-types-options {
      display: flex;
      background-color: var(--pg-grey);
      border-bottom: 1px solid var(--pg-grey);
    }
  }

  .list-sub-types {
    display: none;
    &.expanded {
      display: block;
    }
  }
}

@include media-breakpoint-up(sm) {
  .property-type-filter-root {
    .filter-root {
      .dropdown-menu {
        max-width: 320px;
      }
    }
  }
}

@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'root/styles/core/default-theme';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.found-locations-root {
  display: flex;
  flex-direction: column;
  gap: $pg-grid-space-unit-3x;
  padding: 0;

  .found-locations__separator {
    width: 100%;
    border-top: 1px solid var(--border-active-secondary);
  }

  .found-locations-view-item-root {
    &.dropdown-item {
      display: flex;
      color: var(--pg-grey-dark);
      padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
      border-radius: $badge-border-radius;

      i {
        margin-right: $pg-grid-space-unit-4x;
      }

      & + .dropdown-item {
        margin: 0;
      }

      &:hover {
        background-color: var(--fill-hover-secondary);
      }
    }

    .found-location-content {
      flex-grow: 1;
      overflow-x: hidden;
    }

    .found-location-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: $pg-grid-space-unit-2x;
    }

    .found-location-view {
      flex: 0 1 auto;
      min-width: 0;
      display: flex;
      flex-direction: column;
      gap: $pg-grid-space-unit-2x;
    }

    .found-location-view-title {
      @include typographyV3.pg-font-label-m;
      color: var(--text-active-primary);
      overflow-x: hidden;
      text-overflow: ellipsis;
    }

    .found-location-view-label {
      flex: 1 1 80px;
      min-width: 80px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .badge-root {
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }

    .found-location-view-meta {
      @include typographyV3.pg-font-body-s;
      overflow-x: hidden;
      text-overflow: ellipsis;
      display: block;

      .pgicon-circle {
        font-size: 8px;
        color: var(--pg-grey-medium);

        &.hide {
          display: none;
        }
      }
    }

    .highlight-search {
      color: var(--pg-brand-primary);
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'root/styles/core/default-theme';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.search-options-root {
  padding: 0;
}

.search-options-item-root {
  &.dropdown-item {
    @include typographyV3.pg-font-body-s;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $pg-grid-space-unit-3x;
    padding: $pg-grid-space-unit-3x $pg-grid-space-unit-2x;
    line-height: 1;
    border-radius: $badge-border-radius;

    &:hover {
      background-color: var(--fill-hover-secondary);
    }

    i {
      color: var(--icon-active-primary);
      font-size: 24px;

      &::before {
        margin: 0;
      }
    }

    & + .dropdown-item {
      margin: 0;
    }

    .search-option-title {
      color: var(--pg-black);
    }

    .search-option-label {
      margin-left: 8px;
      border: 1px solid var(--pg-orange-dark);
      border-radius: 5px;
      align-items: center;
      background: var(--pg-orange-dark);
      color: var(--pg-white);
      padding: 1px 10px;
    }
  }
}

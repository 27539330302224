@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';
@import '../styles';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.modal-backdrop {
  &.show {
    z-index: $pg-modal-backdrop-z-index; // Done to override guruland bootstrap styles
  }
}

.global-navbar-root {
  z-index: $pg-global-navbar-z-index;
  color: var(--pg-black);
  background-color: var(--pg-white);
  box-shadow: $pg-box-shadow-subtle;
  transition: transform 0.2s ease-in-out;
  width: 100%;
  position: sticky;
  top: 0;
  padding: 16px;

  &.hidden-on-scroll-down {
    transform: translateY(-100%);
  }

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    gap: 0;

    .side-nav-toggle {
      display: none;
    }

    .brand {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .logo-desktop,
      .logo-mobile {
        width: auto;
        object-fit: contain;
      }

      .logo-desktop {
        height: 40px;
        position: relative;
      }

      .logo-mobile {
        display: none;
        height: 30px;
      }
    }

    @include nav-link-list;

    .nav-link-list {
      margin-left: 24px;

      .nav-link-item {
        white-space: nowrap;

        .nav-link-item-text {
          @include hui-sliding-red-border;
          @include typography.hui-heading5;
          position: relative;

          &::after {
            position: absolute;
            bottom: 0;
          }
        }

        &:hover {
          .nav-link-item-text {
            &:after {
              width: 100%;
            }
          }
        }
      }

      .pgicon-link-ext:before {
        width: 14px;
        height: 22px;
        margin: 0 0 0 2px;
      }
    }

    // More links dropdown - with some customization.
    @include dropdown-link('.more-links-dropdown');

    .more-links-dropdown {
      .more-links-dropdown-menu {
        &.show {
          display: flex !important; // override guruland bootstrap
        }
      }

      .more-links-dropdown-button {
        line-height: 22px;

        .more-links-dropdown-button__text {
          @include hui-sliding-red-border;
          @include typography.hui-heading5;

          position: relative;

          &::after {
            position: absolute;
            bottom: 0;
          }
        }
      }
    }

    .right-button-set {
      display: flex;
      align-items: center;
      gap: $pg-grid-space-unit-3x;

      @include dropdown-button('.my-account-dropdown');

      .my-account-dropdown-menu {
        .my-account-dropdown-item {
          white-space: nowrap;

          .my-account-dropdown-item-badge {
            margin-left: auto;
          }
        }

        &.show {
          display: flex !important; // override guruland bootstrap
        }
      }

      @include dropdown-button('.locale-switch-dropdown');

      .dropdown-toggle {
        &.show {
          .my-account-dropdown-button,
          .locale-switch-dropdown-button {
            background-color: var(--pg-grey-darker);
            color: var(--pg-white);
          }
        }
      }

      @include local-switch-dropdown-desktop-overrides('.locale-switch-dropdown');

      .locale-switch-dropdown-button {
        .pgicon-arrow-down {
          margin-left: $pg-grid-space-unit;
        }
      }

      .notification-toggle {
        @include hui-nav-outline-button;
        @include hui-with-pulsar;
        width: 30px;
        height: 30px;
      }

      .my-activity-link {
        @include hui-nav-outline-button;
        padding: $pg-grid-space-unit $pg-grid-space-unit-4x;
        height: 30px;
      }

      .login-button {
        @include hui-nav-outline-button;
        height: 30px;
      }
    }
  }

  .top-bar.pg-finance-variant {
    .right-button-set {
      .my-activity-link {
        border: none;
        background-color: var(--pg-grey-neutral-40);
        padding: $pg-grid-space-unit;

        &:hover {
          background-color: var(--pg-grey-medium);
        }

        .my-activity-text {
          display: none;
        }

        .my-activity-icon {
          height: 20px;
          width: 20px;
        }
      }
    }

    .more-links-dropdown {
      .more-links-dropdown-menu {
        margin-top: 0;
        padding: 0;
        min-width: 184px;
        gap: 0;

        .more-links-dropdown-header {
          @include typography.hui-body-default-bold;
          font-size: $pg-font-size-caption;
          line-height: $pg-line-height-caption;
          padding: $pg-grid-space-unit-4x $pg-grid-space-unit-4x 0;
          color: $pg-grey-dark;
        }

        .more-links-dropdown-item {
          padding: $pg-grid-space-unit-4x;
        }

        .more-links-dropdown-divider {
          margin: 0;
        }

        .badge.bg-success {
          background-color: #07a35d !important; // variable not in available, override bootstrap
        }
      }

      .more-links-dropdown-menu-end {
        right: unset;
      }
    }

    .more-links-dropdown:last-child {
      .more-links-dropdown-header {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .side-nav-offcanvas,
  .side-nav-offcanvas-backdrop {
    &.show {
      display: none !important; // prevent guruland styles
    }
  }
}

@media (max-width: 1350px) {
  .global-navbar-root {
    .top-bar {
      gap: 0;

      .nav-link-list {
        margin-left: 24px;
        gap: $pg-grid-space-unit-4x;
      }

      .right-button-set {
        gap: $pg-grid-space-unit-2x;

        .my-activity-link {
          padding: 6px; // This is exception where box-sizing is not working here
          width: 30px;
          height: 30px;

          .my-activity-text {
            display: none;
          }
        }

        .my-account-dropdown {
          .my-account-dropdown-button {
            gap: 0;

            .my-account-dropdown-button-text {
              display: none;
            }
          }
        }
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.contact-agent-card-v3-root {
  border: 1px solid $pg-grey-medium;
  border-radius: $pg-border-radius-large;
  background-color: $pg-white;

  &.scrollable {
    // Always show the scrollbar, but hide it with transparent color,
    // to avoid width of scrollbar shift the element when appear.
    overflow-y: scroll;
    max-height: 100%;

    &::-webkit-scrollbar {
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin-top: $pg-grid-space-unit-6x;
      margin-bottom: $pg-grid-space-unit-6x;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: $pg-grey-medium;
        border: 4px solid $pg-white;
        background-clip: padding-box;
        border-radius: $pg-border-radius-small;
      }
    }

    .card-header {
      .agent-info-v3-root {
        padding: $pg-grid-space-unit-5x $pg-grid-space-unit $pg-grid-space-unit-4x $pg-grid-space-unit-5x;
      }
    }

    .card-body {
      padding: 0 $pg-grid-space-unit $pg-grid-space-unit-5x $pg-grid-space-unit-5x;
    }
  }

  .card-header {
    .agent-info-v3-root {
      padding: $pg-grid-space-unit-5x $pg-grid-space-unit-5x $pg-grid-space-unit-4x;

      .avatar-wrapper {
        align-self: flex-start;
        margin-right: 0;
      }
    }
  }

  .card-body {
    padding: 0 $pg-grid-space-unit-5x $pg-grid-space-unit-5x;

    .contact-button-root {
      &.actionable-link {
        gap: $pg-grid-space-unit;
      }
    }

    .enquiry-status {
      .content {
        @include typographyV3.pg-font-label-s;
        display: flex;
        align-items: center;

        .svg-icon-wrapper {
          margin-right: $pg-grid-space-unit-2x;
        }
      }

      .description {
        @include typographyV3.pg-font-body-xs;
        color: $text-active-secondary;
        margin-top: $pg-grid-space-unit-2x;
      }
    }

    .hui-badges-root {
      .hui-badges-label {
        @include typographyV3.pg-font-label-m;
        margin: $pg-grid-space-unit-4x 0 0;
      }

      .badges-group {
        @include typographyV3.pg-font-body-xs;
        margin-top: $pg-grid-space-unit-2x;
        white-space: initial;
        flex-wrap: wrap;
        overflow-x: initial;
      }
    }

    .extended-view-root {
      .extend-view-trigger-point {
        @include typographyV3.pg-font-body-s;
        color: $text-active-primary;
        padding: $pg-grid-space-unit-3x;
        margin-bottom: $pg-grid-space-unit;
        justify-content: space-between;

        &.extended {
          border-bottom: 1px solid $border-active-secondary;
        }
      }

      .contact-button-root {
        margin-top: $pg-grid-space-unit-3x;
        margin-bottom: 0;
        gap: $pg-grid-space-unit;
      }
    }

    .extended-view {
      transition: height 0.3s ease;
      height: var(--element-height);

      .extended-content {
        transition: opacity 0.2s ease-in-out 0.1s;
        opacity: 0;

        &.visible {
          opacity: 1;
        }
      }
    }

    .share-profile-card {
      margin-top: $pg-grid-space-unit-4x;
    }

    .terms-and-policy {
      @include typography.hui-caption;
      margin-bottom: $pg-grid-space-unit-4x;

      a {
        color: $pg-brand-primary;
        text-decoration: none;
      }
    }

    .intent-alert {
      margin-bottom: $pg-grid-space-unit-4x;
    }

    .complete-preferences-nudge-root {
      margin-top: $pg-grid-space-unit-4x;
      margin-bottom: 0;
    }
  }

  .card-footer {
    padding: $pg-grid-space-unit-4x;
    border-top: 1px solid $pg-grey;

    .live-tour {
      @include typography.hui-caption;
      color: $pg-grey-darker;
      display: flex;
      align-items: center;

      .live-tour-badge {
        @include typography.hui-label;
        color: $pg-blue;
        display: inline-flex;
        align-items: center;
        border-radius: $pg-border-radius-small;
        padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
        margin-right: $pg-grid-space-unit-2x;
        background-color: $pg-blue-lightest;
      }
    }
  }

  .hui-alert-root {
    a {
      color: inherit;
    }
  }
}

@include media-breakpoint-up(md) {
  .contact-agent-card-v3-root {
    .card-body {
      .profile-edit-btn.actionable {
        margin: 0;
      }
    }

    .card-footer {
      padding: $pg-grid-space-unit-6x;
    }
  }
}

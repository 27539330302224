@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';
@import '../../../styles/';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.contact-block-root {
  background: var(--pg-black);
  color: var(--pg-white);
  display: flex;
  flex-direction: column;
  padding: 0;

  .left-pane {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .right-pane {
    flex: 1;
    padding: $pg-grid-space-unit-4x $pg-grid-space-unit-3x;

    @include dropdown-button('.region-selector-dropdown');

    .mb-3 {
      margin-bottom: 0 !important; // done to override bootstrap
    }

    .region-selector-dropdown {
      display: flex;

      .region-selector-dropdown-button {
        display: flex;
        justify-content: space-between;
        flex: 1;
        background: var(--pg-black);
        border: 1px solid var(--pg-white);
        padding: $pg-grid-space-unit-2x $pg-grid-space-unit-5x;
        width: 100%;
        outline: none;
        margin-bottom: 16px;

        &.show {
          display: flex !important; // done to override guruland css
        }

        &:hover {
          background: var(--pg-black-80);
        }

        .region-selector-dropdown-button__text {
          display: flex;
          flex-direction: row;

          img {
            height: $pg-grid-space-unit-6x;
            width: $pg-grid-space-unit-6x;
          }
        }

        .pgicon-arrow-down {
          color: var(--pg-white);
        }
      }

      .region-selector-dropdown-menu {
        width: 100%;
        margin: 0;

        .region-selector-dropdown-item {
          padding-inline: $pg-grid-space-unit-5x;

          &,
          & .dropdown-button-text {
            color: var(--pg-black);
          }
        }
      }
    }

    .region-selector-dropdown-label {
      @include typography.hui-heading5;

      color: var(--pg-white);
      margin-bottom: $pg-grid-space-unit;
    }
  }

  .parent-column {
    border-bottom: 1px solid var(--pg-white);
    padding: 0 $pg-grid-space-unit-3x;
  }

  .dropdown-root {
    .form-label {
      @include typography.hui-heading5;
      color: var(--pg-white);
      margin-bottom: 5px;
    }
    .dropdown-toggle {
      border: 1px solid var(--pg-white);
      color: var(--pg-white);
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      &:focus {
        color: var(--pg-white);
      }

      &:hover {
        color: var(--pg-white);
      }

      img {
        background: var(--pg-white);
        margin-top: auto;
        margin-bottom: auto;
      }

      i {
        color: var(--pg-white);
        position: absolute;
        right: 20px;
      }
      &:after {
        display: none;
      }
    }

    .dropdown-button-text {
      @include typography.hui-body-default-regular;
      color: var(--pg-white);
      margin-left: 5px;
    }

    .dropdown-menu {
      width: 100%;
      padding: 5px $pg-grid-space-unit-3x;
      .dropdown-button-text {
        color: var(--pg-black);
      }
    }

    .dropdown-item {
      display: flex;
      align-items: center;
    }
  }

  .search-input-root {
    .input-group {
      display: flex;

      input::placeholder {
        @include typography.hui-body-default-regular;
        color: var(--pg-grey-dark);
      }
      .input-group-text {
        background: var(--pg-white);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        display: flex;
        color: var(--pg-black);

        .pgicon-search {
          margin: auto;

          &::before {
            font-size: 14px;
          }
        }
      }
    }
  }

  .link-stack-root {
    .link-stack-items {
      max-height: 0;
      transition: max-height 0.3s ease-in-out;
      display: block;
      overflow: hidden;
      margin-top: 0;

      &.expand-stack {
        display: block;
        max-height: 300px;
      }

      a {
        @include typography.hui-body-default-regular;
        color: var(--pg-white);
        text-decoration: none;
        margin: 4px 0;
        display: flex;
        justify-content: space-between;
      }
    }

    a {
      @include typography.hui-heading5;
      color: var(--pg-white);
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .contact-block-root {
    gap: $pg-grid-space-unit-4x;

    .left-pane {
      padding-block: $pg-grid-space-unit-4x 0;
      gap: $pg-grid-space-unit-3x;

      .parent-column {
        border-bottom: none;
      }
    }

    .right-pane {
      padding-block: 0 $pg-grid-space-unit-4x;
    }

    .link-stack-root {
      .link-stack-items {
        max-height: 300px;
        display: block;
        overflow: visible;
        margin-top: $pg-grid-space-unit-2x;
      }
      a {
        padding-top: 0;
        padding-bottom: 0;
      }

      h5 {
        margin-bottom: 0;
      }
    }

    .container > .row {
      padding: $pg-grid-space-unit-4x 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .contact-block-root {
    flex-direction: row;
    gap: $pg-grid-space-unit-3x;

    .left-pane {
      display: flex;
      flex-direction: row;
      gap: $pg-grid-space-unit-8x;
      padding-block: $pg-grid-space-unit-4x;
    }

    .right-pane {
      padding-block: $pg-grid-space-unit-4x;
      flex: 0 0 auto;
    }
  }
}

@include media-breakpoint-up(lg) {
  .contact-block-root {
    flex-direction: row;

    .left-pane {
      flex: 1 1 0;
    }

    .right-pane {
      flex: 0.5;
    }
  }
}

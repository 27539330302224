@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.location-filter-modal-body-root {
  &.modal-body {
    border-top: 1px solid var(--pg-grey);
    overflow-x: hidden;
  }

  .form-check {
    input,
    label {
      cursor: pointer;
    }
  }

  .filter-modal-search-section {
    .clickable {
      border-bottom: 1px solid var(--pg-grey);
      padding-bottom: 24px;
      &.hidden {
        display: none;
      }
      label {
        @include typography.hui-body-default-regular;
      }
    }
  }
  .filter-modal-content {
    div {
      display: flex;
      flex-wrap: wrap;
      .clickable,
      .form-check {
        width: 100%;
      }
      .form-check {
        padding-top: 12px;
        padding-bottom: 12px;
        flex-wrap: nowrap;

        label {
          padding-left: 10px;
          @include typography.hui-body-default-regular;
        }
        &.hidden {
          display: none;
        }
      }
    }
  }

  .single-level-variant-root {
    .clickable {
      margin: $pg-grid-space-unit-2x 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .location-filter-modal-body-root {
    .filter-modal-search-section {
      .clickable {
        padding-bottom: 28px;
      }
    }
    .single-level-variant-root {
      .filter-modal-content {
        div {
          .clickable {
            width: 50%;
          }
        }
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'root/styles/core/default-theme';

.agent-info-v3-root {
  &.actionable-link {
    display: grid;
    grid-template-columns: auto 1fr auto;
    text-decoration: none;
    gap: $pg-grid-space-unit-3x;
  }

  &.corporate {
    .avatar-wrapper {
      min-height: 66px;
      border-radius: $pg-grid-space-unit;

      .avatar {
        height: auto;
        border-radius: $pg-grid-space-unit;
      }
    }
  }

  .avatar-wrapper {
    width: 66px;
    height: 66px;
    position: relative;
    margin-right: $pg-grid-space-unit-4x;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--fill-inactive-primary);

    &.featured-agent {
      border: 2px solid var(--fill-brand-orange);
    }

    .crown-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: -4px;
      left: 40px;
      border-radius: 50%;
      background-color: var(--fill-brand-orange);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .pgicon-agent {
      font-size: 20px;
      line-height: 20px;
      color: $pg-grey-medium;
    }
  }

  .details-wrapper {
    display: grid;
    gap: $pg-grid-space-unit;

    .verification {
      width: fit-content;
      text-transform: none;
    }

    .agent-name {
      @include typographyV3.pg-font-label-m;
      text-decoration: none;
      color: var(--text-active-primary);

      &.truncate-line {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.no-pointer {
        cursor: default;
      }
    }

    .agent-description {
      @include typographyV3.pg-font-caption-xs;
      color: $pg-grey-dark;
    }

    .agent-rating {
      @include typographyV3.pg-font-caption-xs;
      display: inline-flex;
      justify-content: center;
      border-radius: $pg-border-radius-medium;
      color: $text-active-primary;
      text-decoration: none;
      gap: $pg-grid-space-unit;
      white-space: nowrap;
      justify-self: flex-start;

      &.has-review {
        .pgicon-star {
          color: $pg-gold;
        }
      }

      .pgicon-star {
        font-size: 16px;
        line-height: 11px;
        color: $icon-inactive-primary;

        &::before {
          margin: 0;
        }
      }

      .rating-text {
        color: $pg-grey-dark;
      }
    }

    .agency {
      @include typographyV3.pg-font-caption-xs;
      color: $text-active-primary;
      display: flex;
      align-items: center;
      justify-content: left;
      justify-self: flex-start;

      .agency-avatar {
        width: 16px;
        margin-right: $pg-grid-space-unit;
      }
    }

    .agent-certificate,
    .badge-text {
      @include typographyV3.pg-font-caption-xs;
      display: flex;
      align-items: center;
      justify-self: flex-start;
      font-weight: $pg-font-weight-heading;
      color: $pg-gold-dark;
      cursor: pointer;
      background-color: unset;

      .hui-image-root {
        width: $pg-grid-space-unit-3x;
        height: $pg-grid-space-unit-3x;
        margin-right: $pg-grid-space-unit;
      }
    }
  }

  > .pgicon {
    color: $text-active-primary;
    margin-left: $pg-grid-space-unit;
  }
}

.agent-info-popover {
  .popover-header {
    @include typography.hui-heading5;
    padding: $pg-grid-space-unit-3x $pg-grid-space-unit-4x;
    background-color: $pg-grey-lighter;
  }

  .popover-body {
    @include typography.hui-body-default-regular;
    padding: $pg-grid-space-unit-4x;

    .btn {
      margin-top: $pg-grid-space-unit-2x;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.toggle-switch-root {
  $width: 40px;
  $height: 16px;
  $border-radius: var(--pg-border-radius-full);

  width: $width;
  height: $height;
  border-radius: $border-radius;
  display: inline-block;
  cursor: pointer;

  &.primary {
    .toggle-input:checked ~ .toggle-fill {
      background-color: $icon-brand-green;
      border: 1px solid $border-success-primary;
    }

    .toggle-fill {
      width: 40px;
      background-color: $fill-neutral-tertiary;
      border: 1px solid $fill-neutral-tertiary;
    }

    .toggle-fill .toggle-circle {
      background-color: $fill-neutral-secondary;
      border: 1px solid $border-inactive-primary;
    }

    .toggle-input:disabled ~ .toggle-fill {
      background-color: $fill-inactive-primary;
      border: 1px solid $border-inactive-primary;

      .toggle-circle {
        background-color: $fill-inactive-primary;
        border: 1px solid $border-inactive-primary;
      }
    }

    .toggle-input:disabled:checked ~ .toggle-fill {
      background-color: $fill-inactive-tertiary;
      border: 1px solid $border-inactive-primary;

      .toggle-circle {
        background-color: var(--pg-white);

        .pgicon-cancel {
          opacity: 1;
        }

        .pgicon-ok {
          opacity: 0;
        }
      }
    }
  }

  .toggle-input,
  .toggle-text {
    display: none;
  }

  .toggle-fill {
    position: relative;
    width: $width;
    height: $height;
    border-radius: $border-radius;
    background: var(--pg-white);
    transition: background 0.4s;
  }

  .toggle-fill .toggle-circle {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -2px;
    margin-top: -5px;
    height: 24px;
    width: 24px;
    background: var(--pg-white);
    border-radius: 100%;
    transition: transform 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $pg-green;

    .pgicon-cancel {
      color: $border-inactive-primary;
      opacity: 0;
    }

    .pgicon-ok {
      opacity: 0;
    }

    .pgicon {
      position: absolute;
      top: -1px;
      left: 0;
    }
  }

  .toggle-input:checked ~ .toggle-fill .toggle-circle {
    transform: translateX(18px);

    .pgicon-ok {
      opacity: 1;
    }
  }
}

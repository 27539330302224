@use 'sass:string';
@import './primitives';

// Colors
// Colors - Semantic - Old - (This would eventually all removed after fully migrating to new tokens)
$pg-brand-primary: $pg-red-60;
$pg-brand-primary-light: $pg-red-50;
$pg-brand-primary-dark: $pg-gray-80;
$pg-brand-primary-lightest: $pg-red-10;
$pg-brand-primary-lighter: #ffc7c3;

$pg-brand-primary-high: #c20000;

$pg-orange: $pg-orange-50;
$pg-orange-light: $pg-orange-10;
$pg-orange-dark: $pg-orange-70;
$pg-orange-lightest: $pg-orange-10;
$pg-orange-lighter: #ffd1bc;

$pg-gold: $pg-gold-50;
$pg-gold-light: $pg-gold-10;
$pg-gold-dark: $pg-gold-60;
$pg-gold-lighter: $pg-gold-10;
$pg-gold-lightest: $pg-gold-10;

$pg-purple: $pg-purple-50;
$pg-purple-light: $pg-purple-10;
$pg-purple-dark: $pg-purple-70;
$pg-purple-lighter: #a99cb1;
$pg-purple-lightest: #f0eaf4;

$pg-green: $pg-green-60;
$pg-green-light: $pg-green-10;
$pg-green-dark: $pg-green-70;
$pg-green-lighter: $pg-green-10;
$pg-green-lightest: #e7fff4;

$pg-teal: $pg-teal-50;
$pg-teal-light: $pg-teal-10;
$pg-teal-dark: $pg-teal-70;
$pg-teal-lighter: #9ed8da;
$pg-teal-lightest: #e8feff;

$pg-blue: $pg-blue-80;
$pg-blue-dark: $pg-blue-90;
$pg-blue-light: $pg-blue-10;
$pg-blue-lighter: $pg-blue-10;
$pg-blue-lightest: #ecf7ff;

$pg-black: $pg-black-80;
$pg-white: $pg-gray-0;

$pg-grey-lighter: #fafafa;
$pg-grey: $pg-gray-10;
$pg-grey-light: #eff1f5;
$pg-grey-medium: #ccc;
$pg-grey-dark: $pg-gray-50;
$pg-grey-darker: #505050;
$pg-grey-darkest: #3e4246;

$pg-coral-red: #e03d31;

// Colors - Custom Fill - New (This would eventually replaced all the old color token)
$fill-active-primary: $pg-red-60;
$fill-active-secondary: $pg-red-10;
$fill-active-tertiary: $pg-gray-80;

$fill-inactive-primary: $pg-gray-10;
$fill-inactive-secondary: $pg-gray-70;
$fill-inactive-tertiary: $pg-gray-30;

$fill-pressed-primary: $pg-transparency-white-20;

$fill-hover-primary: $pg-transparency-black-20;
$fill-hover-secondary: $pg-transparency-black-10;

$fill-error-primary: $pg-red-60;
$fill-error-secondary: $pg-red-10;
$fill-error-tertiary: $pg-red-80;

$fill-warning-primary: $pg-orange-50;
$fill-warning-secondary: $pg-orange-10;
$fill-warning-tertiary: $pg-orange-70;

$fill-success-primary: $pg-green-60;
$fill-success-secondary: $pg-green-10;
$fill-success-tertiary: $pg-green-70;

$fill-neutral-primary: $pg-gray-10;
$fill-neutral-secondary: $pg-gray-0;
$fill-neutral-tertiary: $pg-gray-20;
$fill-neutral-quaternary: $pg-gray-80;

$fill-static-primary: $pg-white-100;
$fill-static-secondary: $pg-black-100;

$fill-focus-primary: $pg-blue-60;

$fill-brand-red: $pg-red-50;
$fill-brand-green: $pg-green-50;
$fill-brand-blue: $pg-blue-50;
$fill-brand-teal: $pg-teal-50;
$fill-brand-orange: $pg-orange-50;
$fill-brand-purple: $pg-purple-50;
$fill-brand-yellow: $pg-gold-50;

// Colors - Custom Border
$border-active-primary: $pg-gray-20;
$border-active-secondary: $pg-gray-10;
$border-active-tertiary: $pg-gray-70;
$border-active-quaternary: $pg-red-70;
$border-inactive-primary: $pg-gray-20;
$border-inactive-secondary: $pg-gray-70;
$border-inactive-tertiary: $pg-gray-30;
$border-pressed-primary: $pg-transparency-white-20;
$border-hover-primary: $pg-transparency-black-20;
$border-error-primary: $pg-red-60;
$border-error-secondary: $pg-red-20;
$border-error-tertiary: $pg-red-70;
$border-warning-primary: $pg-orange-60;
$border-warning-secondary: $pg-orange-10;
$border-warning-tertiary: $pg-orange-70;
$border-success-primary: $pg-green-60;
$border-success-secondary: $pg-green-10;
$border-success-tertiary: $pg-green-70;
$border-neutral-primary: $pg-gray-50;
$border-static-primary: $pg-white-100;
$border-static-secondary: $pg-black-100;
$border-focus-primary: $pg-blue-50;
$border-brand-red: $pg-red-50;
$border-brand-green: $pg-green-50;
$border-brand-blue: $pg-blue-50;
$border-brand-teal: $pg-teal-50;
$border-brand-orange: $pg-orange-50;
$border-brand-purple: $pg-purple-50;

// Colors - Custom Text
$text-active-primary: $pg-gray-90;
$text-active-secondary: $pg-gray-50;
$text-active-tertiary: $pg-gray-0;
$text-inactive-primary: $pg-gray-40;
$text-inactive-secondary: $pg-gray-70;
$text-inactive-tertiary: $pg-gray-20;
$text-pressed-primary: $pg-transparency-white-20;
$text-hover-primary: $pg-transparency-black-20;
$text-error-primary: $pg-red-60;
$text-error-secondary: $pg-red-10;
$text-error-tertiary: $pg-red-70;
$text-warning-primary: $pg-orange-60;
$text-warning-secondary: $pg-orange-10;
$text-warning-tertiary: $pg-orange-70;
$text-success-primary: $pg-green-60;
$text-success-secondary: $pg-green-10;
$text-success-tertiary: $pg-green-70;
$text-neutral-primary: $pg-gray-50;
$text-static-primary: $pg-white-100;
$text-static-secondary: $pg-black-100;
$text-focus-primary: $pg-blue-60;
$text-brand-red: $pg-red-50;
$text-brand-green: $pg-green-50;
$text-brand-blue: $pg-blue-50;
$text-brand-teal: $pg-teal-50;
$text-brand-orange: $pg-orange-50;
$text-brand-purple: $pg-purple-50;
$text-brand-gold: $pg-gold-50;

// Colors - Custom Icon
$icon-active-primary: $pg-gray-90;
$icon-active-secondary: $pg-gray-60;
$icon-active-tertiary: $pg-gray-0;
$icon-inactive-primary: $pg-gray-20;
$icon-inactive-secondary: $pg-gray-70;
$icon-inactive-tertiary: $pg-gray-30;
$icon-pressed-primary: $pg-transparency-white-20;
$icon-hover-primary: $pg-transparency-black-20;
$icon-error-primary: $pg-red-60;
$icon-error-secondary: $pg-red-10;
$icon-error-tertiary: $pg-red-80;
$icon-warning-primary: $pg-orange-50;
$icon-warning-secondary: $pg-orange-10;
$icon-warning-tertiary: $pg-orange-70;
$icon-success-primary: $pg-green-60;
$icon-success-secondary: $pg-green-10;
$icon-success-tertiary: $pg-green-70;
$icon-neutral-primary: $pg-gray-50;
$icon-static-primary: $pg-white-100;
$icon-static-secondary: $pg-black-100;
$icon-focus-primary: $pg-blue-60;
$icon-brand-red: $pg-red-50;
$icon-brand-green: $pg-green-50;
$icon-brand-blue: $pg-blue-50;
$icon-brand-teal: $pg-teal-50;
$icon-brand-orange: $pg-orange-50;
$icon-brand-purple: $pg-purple-50;

// Border - Radius
$pg-border-radius-small: 8px;
$pg-border-radius-medium: 16px;
$pg-border-radius-large: 24px;
$pg-border-radius-full: 100px;

/* Modal Backdrop */
$pg-modal-backdrop-z-index: 1050;

// Spacings
$pg-grid-space-unit: 4px;
$pg-grid-space-unit-2x: 8px;
$pg-grid-space-unit-3x: 12px;
$pg-grid-space-unit-4x: 16px;
$pg-grid-space-unit-5x: 20px;
$pg-grid-space-unit-6x: 24px;
$pg-grid-space-unit-7x: 28px;
$pg-grid-space-unit-8x: 32px;
$pg-grid-space-unit-9x: 36px;
$pg-grid-space-unit-10x: 40px;
$pg-grid-space-unit-11x: 44px;
$pg-grid-space-unit-12x: 48px;
$pg-grid-space-unit-14x: 56px;
$pg-grid-space-unit-15x: 60px;
$pg-grid-space-unit-16x: 64px;

// Default Value / Font Family
$pg-font-family-heading: 'Poppins';
$pg-font-heading: $pg-font-family-heading, 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$pg-font-body: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$pg-font-body-bold: 'Inter Medium', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;

$pg-box-shadow-hard: 0 2px 3px rgba(44, 44, 44, 0.5);
$pg-box-shadow-soft: 0 0 8px rgba(44, 44, 44, 0.2);
$pg-box-shadow-subtle: 0 2px 24px rgba(44, 44, 44, 0.1);
$pg-box-shadow-small: 0 2px 4px 0 rgba(40, 44, 46, 0.08);

$pg-filter-shadow-hard: string.unquote('drop-shadow(#{$pg-box-shadow-hard})');
$pg-filter-shadow-soft: string.unquote('drop-shadow(#{$pg-box-shadow-soft})');
$pg-filter-shadow-subtle: string.unquote('drop-shadow(#{$pg-box-shadow-subtle})');
$pg-filter-shadow-small: string.unquote('drop-shadow(#{$pg-box-shadow-small})');

/* Typography */
$pg-font-weight-heading: 500;
$pg-font-size-heading-1: 40px;
$pg-line-height-heading-1: 64px;
$pg-font-size-heading-2: 30px;
$pg-line-height-heading-2: 48px;
$pg-font-size-heading-3: 24px;
$pg-line-height-heading-3: 38px;
$pg-font-size-heading-4: 18px;
$pg-line-height-heading-4: 28px;
$pg-font-size-heading-5: 14px;
$pg-line-height-heading-5: 22px;
$pg-font-body: $pg-font-body;
$pg-font-color-body: $pg-black;
$pg-font-weight-body-regular: 400;
$pg-font-weight-body-bold: 500;
$pg-font-size-body-regular: 14px;
$pg-line-height-body-regular: 22px;
$pg-font-size-body-big: 16px;
$pg-line-height-body-big-mobile: 24px;
$pg-line-height-body-big: 26px;
$pg-font-size-caption: 12px;
$pg-line-height-caption: 18px;
$pg-font-size-text-link: 14px;
$pg-font-size-button: 14px;
$pg-line-height-button: 22px;

// Shadows
$pg-shadow-color: $pg-gray-70;
$pg-shadow-soft-alpha: 0.08;
$pg-shadow-medium-alpha: 0.12;
$pg-shadow-hard-alpha: 0.2;

// Shadows - Soft
$pg-soft-down-large: 0 8px 16px rgba($pg-shadow-color, $pg-shadow-medium-alpha);
$pg-soft-down-medium: 0 4px 8px rgba($pg-shadow-color, $pg-shadow-medium-alpha);
$pg-soft-down-small: 0 2px 4px rgba($pg-shadow-color, $pg-shadow-soft-alpha);
$pg-soft-up-large: 0 -8px 16px rgba($pg-shadow-color, $pg-shadow-medium-alpha);
$pg-soft-up-medium: 0 -4px 8px rgba($pg-shadow-color, $pg-shadow-medium-alpha);
$pg-soft-up-small: 0 -2px 14px rgba($pg-shadow-color, $pg-shadow-soft-alpha);

// Shadows - Hard
$pg-hard-down-large: 0 8px 16px rgba($pg-shadow-color, $pg-shadow-hard-alpha);
$pg-hard-down-medium: 0 4px 8px rgba($pg-shadow-color, $pg-shadow-hard-alpha);
$pg-hard-down-small: 0 2px 4px rgba($pg-shadow-color, $pg-shadow-hard-alpha);
$pg-hard-up-large: 0 -8px 16px rgba($pg-shadow-color, $pg-shadow-hard-alpha);
$pg-hard-up-medium: 0 -4px 8px rgba($pg-shadow-color, $pg-shadow-hard-alpha);
$pg-hard-up-small: 0 -2px 14px rgba($pg-shadow-color, $pg-shadow-hard-alpha);

// Elevation
$pg-elevation-soft-alpha: 0.12;
$pg-elevation-hard-alpha: 0.4;

$pg-elevation-down-small:
  0 2px 4px 0 rgba($pg-shadow-color, $pg-elevation-soft-alpha),
  0 2px 2px 0 rgba($pg-gray-0, $pg-elevation-hard-alpha) inset;
$pg-elevation-down-medium:
  0 4px 8px 0 rgba($pg-shadow-color, $pg-elevation-soft-alpha),
  0 2px 2px 0 rgba($pg-gray-0, $pg-elevation-hard-alpha) inset;

/* theme */
$theme-colors: (
  'primary': $fill-active-primary,
  'secondary': rgba(255, 255, 255, 0),
  'tertiary': $fill-active-secondary,
  'light': $fill-static-primary,
  'dark': $fill-static-secondary,
  'success': $pg-green,
  'warning': $pg-gold,
  'info': $pg-purple,
  'danger': $pg-orange,
);

$theme-colors-light: (
  'primary': $pg-brand-primary-light,
  'secondary': $pg-grey-dark,
  'success': $pg-green-light,
  'warning': $pg-gold-light,
  'info': $pg-purple-light,
  'danger': $pg-orange-light,
);

$theme-colors-dark: (
  'primary': $pg-brand-primary-dark,
  'secondary': $pg-black,
  'success': $pg-green-dark,
  'warning': $pg-gold-dark,
  'info': $pg-purple-dark,
  'danger': $pg-orange-dark,
);

$theme-colors-lighter: (
  'primary': $pg-brand-primary-lighter,
  'secondary': $pg-grey-lighter,
  'success': $pg-green-lighter,
  'warning': $pg-gold-lighter,
  'info': $pg-purple-lighter,
  'danger': $pg-orange-lighter,
);

$theme-colors-lightest: (
  'primary': $pg-brand-primary-lightest,
  'secondary': $pg-grey,
  'success': $pg-green-lightest,
  'warning': $pg-gold-lightest,
  'info': $pg-purple-lightest,
  'danger': $pg-orange-lightest,
);

$pg-z-index-neg-1: -1;
$pg-z-index-1: 1;
$pg-z-index-2: 2;
$pg-z-index-3: 3;
$pg-z-index-4: 4;
$pg-z-index-5: 5;
$pg-z-index-6: 6;
$pg-z-index-max: 9999;

/* Typography */
$pg-font-size-heading-5-mobile: 14px;
$pg-line-height-heading-5-mobile: 22px;
$pg-line-height-text-link: 22px;
$pg-border-radius-ghost-button: 8px;
$pg-line-height-badge-regular: 16px;

/* Experiment Typography */
$pg-font-weight-experiment-heading: 600;
$pg-font-size-experiment-heading-3-mobile: 16px;
$pg-line-height-experiment-heading-3-mobile: 24px;
$pg-font-size-experiment-heading-3: 16px;
$pg-line-height-experiment-heading-3: 24px;

/* PGIcon content code */
/* refer to file: _pgicons.scss */
$pg-icon-checkbox-code: '\e872';
$pg-icon-checked-o-code: '\e873';
$pg-icon-checked-code: '\e874';
$pg-icon-radio-code: '\e8f1';
$pg-icon-radio-check-code: '\e8f0';
$pg-icon-arrow-up-code: '\e85c';
$pg-icon-arrow-down-code: '\e859';
$pg-icon-arrow-right-code: '\e85b';
$pg-icon-arrow-left-code: '\e85a';
$pg-icon-cancel-code: '\e86f';
$pg-icon-minus-code: '\e8d3';
$pg-icon-calendar-code: '\e86c';
$pg-icon-furnished-code: '\e8d5';

/* GlobalNavbar */
$pg-global-navbar-z-index: 1024;
$pg-global-navbar-desktop-height: 72px;
$pg-global-navbar-mobile-height: 54px;
$pg-mega-menu-navbar-desktop-height: 88px;
$pg-mega-menu-navbar-mobile-height: 64px;

$pg-masthead-video-lg-width: 1350px;
$pg-masthead-video-lg-height: 400px;
$pg-masthead-video-md-width: 768px;
$pg-masthead-video-md-height: 360px;
$pg-masthead-video-sm-width: 414px;
$pg-masthead-video-sm-height: 414px;

/* Search-with-filter */
$pg-search-with-filter-home-height: 196px;

/* Companion-ad-block */
$pg-companion-ad-block-small-tablet-height: 120px;
$pg-companion-ad-block-small-mobile-height: 120px;

/* Input Box shadow */
$pg-input-box-shadow:
  0 2px 2px 0 rgba(255, 255, 255, 0.4) inset,
  0 2px 4px 0 rgba(40, 44, 46, 0.12);

$pg-modal-footer-shadow: 0 -2px 4px 0 rgba(40, 44, 46, 0.08);

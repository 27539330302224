@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.text-area-root {
  .text-area {
    border-radius: 8px;
    resize: none;
    padding: 5px 10px;
    width: 100%;
    border-color: var(--pg-grey-medium);
    color: var(--pg-black);

    &::placeholder {
      color: var(--pg-grey-dark);
    }

    &.error {
      background: var(--pg-orange-lightest);
      border-color: var(--pg-orange);
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.seller-leads-prequalification {
  &-modal-root {
    .modal-header {
      .hui-btn-close {
        border: none;
        line-height: unset;
        font-size: 0;
      }
    }

    .modal-body {
      .pg-login-masthead {
        &-icon {
          line-height: 0;
          font-size: unset;
        }

        &-title {
          margin-top: $pg-grid-space-unit-2x;
        }

        &-description {
          margin-top: $pg-grid-space-unit;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        gap: $pg-grid-space-unit-4x;
        margin: $pg-grid-space-unit-4x 0;

        .form-label {
          @include typography.hui-caption;
          margin-bottom: $pg-grid-space-unit;
        }

        .form-control {
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .invalid-feedback {
          @include typography.hui-caption;
          margin-top: $pg-grid-space-unit;
        }
      }

      .badge {
        @include typography.hui-body-default-regular;
        cursor: pointer;
        border-radius: 50rem;
        padding: $pg-grid-space-unit $pg-grid-space-unit-4x;

        &.bg-light {
          border: 1px solid var(--pg-grey-medium);
        }

        &.bg-dark {
          border: 1px solid var(--pg-black);
        }
      }

      .pgicon-ok-circled {
        position: absolute;
        right: 8px;
        top: calc(50% - 10px);
        z-index: $pg-z-index-6;
        font-size: 20px;
        width: 20;
        height: 20;
        color: var(--pg-green-dark);
        &::before {
          margin: 0;
        }
      }
    }

    .home-sellers-terms {
      @include typography.hui-caption;
      text-align: center;
      color: var(--pg-grey-darker);
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.agent-enquiry-modal-root {
  .modal-dialog {
    max-width: 610px;

    .modal-title {
      @include typographyV3.pg-font-title-xs;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      padding: $pg-grid-space-unit-4x 0 0;
      position: initial;

      .action-group {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: var(--pg-white);
        border-top: 1px solid var(--border-active-secondary);
        padding: var(--pg-grid-space-unit-4x) var(--pg-grid-space-unit-3x);

        .actionable {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .agent-enquiry-modal-root.hui-modal-root {
    .modal-dialog {
      height: auto;

      .modal-title {
        color: var(--text-active-tertiary);
      }
  
      .modal-content {
        min-height: unset;
        max-height: 722px;

        .modal-body {
          overflow-y: auto;
        }
      }

      .action-group {
        border-bottom-left-radius: var(--pg-border-radius-large);
        border-bottom-right-radius: var(--pg-border-radius-large);
      }
    }
  }
}

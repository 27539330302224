@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'root/styles/core/default-theme';

.hui-date-picker {
  width: fit-content;

  &.shouldShowIcon {
    .react-datepicker__input-container::after {
      top: 50%;
      transform: translateY(-50%);
      color: var(--pg-black);
      right: 15px;
      content: url('https://cdn-integration.pgimgs.com/hive-ui/static/v0.1.141/pg-icons/calendar.svg');
      position: absolute;
      height: 20px;
      width: 20px;
    }
  }

  .react-datepicker__input-container {
    position: relative;
    width: fit-content;

    input {
      @include typographyV3.pg-font-body-xs;
      caret-color: transparent;
      outline: none;
      border: none;
      color: var(--pg-black);
    }
  }

  &.disabled {
    .react-datepicker__input-container {
      &::after {
        color: var(--pg-grey-dark);
      }
    }
  }
}

.custom-hui-date-picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .month-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;

    .month-navigation {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
    }

    .month {
      padding: 0 $pg-grid-space-unit-3x;

      @include typographyV3.pg-font-body-xs;
      text-transform: capitalize;
    }
  }

  .year-wrapper {
    @include typographyV3.pg-font-body-xs;

    display: flex;
    flex-direction: row;
    padding: 14px $pg-grid-space-unit-4x;
  }
}

.hui-date-picker-popover {
  .react-datepicker {
    width: 310px;
    box-shadow: $pg-box-shadow-soft;
    border: none;
    border-radius: $pg-border-radius-large;

    .react-datepicker__triangle {
      &::before {
        border-bottom-color: var(--pg-grey);
      }

      &::after {
        border-bottom-color: var(--pg-white);
      }
    }

    .react-datepicker__header {
      background-color: var(--pg-white);
      padding: $pg-grid-space-unit-6x $pg-grid-space-unit-4x 0;
      margin-bottom: $pg-grid-space-unit-3x;
      border: none;
      border-radius: $pg-border-radius-large;

      .divider-root {
        margin: $pg-grid-space-unit-6x 0;
        height: 0;

        .line {
          box-sizing: content-box;
          border: 1px solid $pg-grey-light;
          margin: 0;
        }
      }

      .react-datepicker__header__dropdown {
        .react-datepicker__year-read-view--down-arrow {
          &::before {
            content: none;
          }
        }
      }
    }

    .react-datepicker__month,
    .react-datepicker__year {
      margin: 0;
      padding: 0 $pg-grid-space-unit-4x $pg-grid-space-unit-6x;
    }

    .react-datepicker__year {
      display: flex;
      justify-content: center;
      max-height: 300px;
      flex-wrap: wrap;
      overflow: scroll;

      .react-datepicker__year-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .react-datepicker__year-text {
          display: flex;
          justify-content: center;

          @include typographyV3.pg-font-body-m;
          margin: 0;
          padding: $pg-grid-space-unit-3x $pg-grid-space-unit-4x;
          border-radius: var(--pg-border-radius-full);
        }

        .react-datepicker__year-text--selected,
        .react-datepicker__year-text--keyboard-selected {
          border-radius: var(--pg-border-radius-full);
          color: var(--pg-white);
          background-color: var(--pg-brand-primary);
        }
      }
    }

    .react-datepicker__month-container {
      width: 100%;

      .react-datepicker__day-names {
        display: flex;
        justify-content: center;
      }

      .react-datepicker__day-name,
      .react-datepicker__day {
        @include typographyV3.pg-font-body-m;

        width: 40px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &.react-datepicker__day--disabled {
          color: var(--pg-grey-medium);
        }

        &:hover {
          background-color: var(--fill-active-secondary);
        }

        &.react-datepicker__day--keyboard-selected,
        &.react-datepicker__day--selected {
          color: var(--pg-white);
          background-color: var(--pg-brand-primary);
        }
      }

      .react-datepicker__week:not(:last-child) {
        margin-bottom: $pg-grid-space-unit-4x;
      }

      .react-datepicker__month {
        display: flex;
        flex-direction: column;
        align-items: center;

        .react-datepicker__week {
          display: flex;

          .react-datepicker__day {
            width: 40px;
            height: 40px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &.react-datepicker__day--outside-month {
              color: $pg-gray-40;
            }
          }
        }
      }

      .react-datepicker__day-name {
        @include typographyV3.pg-font-label-s;

        color: var(--pg-grey-darkest);
        margin-bottom: $pg-grid-space-unit-3x;
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';

.agent-meta-info {
  &-cta {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // to give this link more weightage than the link for the card
    z-index: $pg-z-index-2;
    position: relative;
    color: var(--text-active-primary);

    .pgicon {
      margin-right: $pg-grid-space-unit;

      &::before {
        margin: 0;
      }
    }

    &-caption {
      @include typography.hui-body-default-regular;
    }

    &-link {
      text-decoration-line: underline;
      text-decoration-color: var(--text-active-primary);
      color: var(--text-active-primary);
      margin-right: $pg-grid-space-unit;
      font-weight: 500;
    }
  }

  &-details {
    &-caption {
      @include typography.hui-caption;
      color: var(--text-active-secondary);
      margin-bottom: 0;
    }

    &-label {
      @include typography.hui-heading5;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .pgicon-circle {
        color: var(--pg-grey-medium);
        font-size: 8px;

        &::before {
          margin: 0 $pg-grid-space-unit;
        }
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

$modal-header-height: 58px;
$modal-footer-height: 72px;
$desktop-modal-header-height: 60px;
$desktop-modal-footer-height: 72px;

$modal-body-height: calc(100% - $modal-header-height - $modal-footer-height);
$desktop-modal-body-height: calc(100% - $desktop-modal-header-height - $desktop-modal-footer-height);

$desktop-map-container-height: calc(100vh - 5.5rem - $desktop-modal-header-height - $desktop-modal-footer-height);
$desktop-sidebar-container-height: calc(100vh - 4.5rem - $desktop-modal-header-height - $desktop-modal-footer-height);

@mixin keyframes($name) {
  @keyframes #{$name} {
    0% {
      opacity: if($name == fadeIn1 or $name == fadeIn2, 0, 1);
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: if($name == fadeIn1 or $name == fadeIn2, 1, 0);
    }
  }
}

@include keyframes(fadeOut1);
@include keyframes(fadeOut2);
@include keyframes(fadeIn1);
@include keyframes(fadeIn2);

.mrt-search-modal-root {
  &--second-level.mrt-search-modal-root {
    background: red;

    .mrt-search-modal-header {
      height: unset;

      .hui-btn-back {
        flex-shrink: 0;
        margin-top: calc(-1 * $pg-grid-space-unit-4x);
        transform: translateY(8px);
      }

      .hui-modal-heading {
        margin-top: calc(-1 * $pg-grid-space-unit-4x);
        transform: translateY(8px);

        .modal-title-current {
          animation: fadeIn2 0.3s ease-in-out forwards;
        }

        .modal-title-prev {
          opacity: 0;
          animation: fadeOut2 0.3s ease-in-out forwards;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    .mrt-search-modal-body {
      margin-top: calc(-1 * $pg-grid-space-unit-4x);
      transform: translateY(16px);
    }
  }

  .mrt-search-modal-header {
    border-bottom: none;
    height: $modal-header-height;
    padding: $pg-grid-space-unit-4x;

    .modal-title {
      color: var(--text-active-tertiary);
    }

    .hui-modal-heading {
      position: relative;
      overflow: visible;
      transition: transform 0.3s ease;

      .modal-title-current {
        animation: fadeIn1 0.3s ease-in-out forwards;
      }

      .modal-title-prev {
        animation: fadeOut1 0.3s ease-in-out forwards;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .hui-btn-back {
      transition: transform 0.3s ease;
    }

    .btn-close {
      padding: 0;
      margin: 0;
      width: 18px;
      height: 18px;
    }
  }

  .mrt-search-modal-footer {
    height: $modal-footer-height;
    background-color: var(--pg-white);
    padding: $pg-grid-space-unit-3x $pg-grid-space-unit-4x;
    z-index: $pg-z-index-1;
  }

  .modal-dialog-scrollable {
    .mrt-search-modal-body {
      transition: transform 0.3s ease;
      height: $modal-body-height;
      overflow-y: hidden;
    }
  }

  .panzoom-container {
    height: 100%;
  }

  .modal-title {
    @include typography.hui-heading4;
  }

  .mrt-search-content {
    display: flex;
    max-height: 100%;
    height: 100%;

    .sidebar {
      @include typography.hui-body-default-bold;
      width: 330px;
      flex: 0 1 330px;
    }

    .vertical-divider {
      border-left: 1px solid var(--pg-grey-medium);
      margin: 0 $pg-grid-space-unit-4x;
    }

    .accordion {
      overflow: hidden;
      overflow-y: auto;

      @include media-breakpoint-up(md) {
        margin-right: -$pg-grid-space-unit-4x;
        padding-right: $pg-grid-space-unit-4x;
      }

      &-header {
        width: 100%;
      }

      &-button {
        @include typographyV3.pg-font-body-s;
        color: var(--text-active-primary);
        align-items: center;
        border: none;
        padding: 0;

        .badge {
          margin-right: $pg-grid-space-unit-2x;
        }

        &:not(.collapsed)::after {
          transform: unset;
          content: url('https://cdn.pgimgs.com/hive-ui/static/v0.1.249/pg-icons/font/v3/chevron-top-small.svg');
        }

        &.collapsed::after {
          content: url('https://cdn.pgimgs.com/hive-ui/static/v0.1.249/pg-icons/font/v3/chevron-down-small.svg');
        }

        &::after {
          top: unset;
          margin-left: unset;
          color: var(--text-active-primary);
        }
      }

      &-body {
        padding: 0;
      }
    }

    .divider-root {
      height: auto;

      .line {
        margin: 0;
        background-color: var(--pg-grey-light);
      }
    }

    .station-row {
      position: relative;
      justify-content: space-between;
      cursor: pointer;

      .station-name {
        @include typographyV3.pg-font-body-s;
      }
    }

    .line-row,
    .station-row {
      padding: $pg-grid-space-unit-3x 0;
      display: flex;
      align-items: center;
    }

    .line-badge {
      min-width: 32px;
      height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      padding: 0 $pg-grid-space-unit;
      margin-right: $pg-grid-space-unit-3x;

      .line-name {
        @include typographyV3.pg-font-caption-xs;
        color: var(--pg-white);
        white-space: nowrap;
      }
    }

    .station-badge {
      width: 32px;
      height: 15px;
      border-radius: $pg-grid-space-unit;

      .color-badge-root {
        width: 36px;
        height: 17px;
        border-radius: 6px;
      }
    }

    .section-title {
      @include typographyV3.pg-font-label-s;
    }

    .search-bar {
      padding: $pg-grid-space-unit-4x;
      z-index: $pg-z-index-1;

      .input-group {
        border-radius: var(--pg-border-radius-full);
        border: 1px solid $pg-gray-20;
        color: var(--text-inactive-primary);
        box-shadow: $pg-input-box-shadow;

        input {
          color: var(--text-inactive-secondary);
        }

        &:focus-within {
          border-color: var(--border-active-tertiary);
        }

        .form-control {
          @include typographyV3.pg-font-body-xs;
          padding: $pg-grid-space-unit-2x;

          &::placeholder {
            @include typographyV3.pg-font-body-xs;
            color: var(--text-inactive-primary);
          }
        }

        .input-group-text {
          .icon-search {
            margin: $pg-grid-space-unit-2x 0 $pg-grid-space-unit-2x $pg-grid-space-unit-4x;

            &::before {
              font-size: $pg-grid-space-unit-6x;
              margin: 0;
            }
          }

          .hui-btn-close {
            &::before {
              margin: 0;
            }
          }
        }
      }

      position: relative;
    }

    .searched-station-group {
      border-bottom: 1px solid var(--pg-grey);
    }
  }

  .mobile-map,
  .mobile-list {
    width: 100%;
  }

  .mobile-map {
    display: flex;
    flex-direction: column;
  }

  .elevated-notch {
    position: relative;
    border-radius: var(--pg-border-radius-large) var(--pg-border-radius-large) 0 0;
    box-shadow: var(--pg-soft-up-medium);
  }

  .mobile-list {
    display: flex;
    flex-direction: column;
  }

  .mobile-lines-row {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: $pg-grid-space-unit-3x;
  }

  .mobile-line-btn {
    border: none;
    border-radius: var(--pg-border-radius-full);
    margin-right: $pg-grid-space-unit-2x;
    padding: $pg-grid-space-unit-2x $pg-grid-space-unit-4x;

    &:first-of-type {
      margin-left: $pg-grid-space-unit-4x;
    }

    &:last-of-type {
      margin-right: $pg-grid-space-unit-4x;
    }

    .typography-root {
      color: var(--text-active-tertiary);
    }

    .badge {
      background-color: var(--fill-neutral-secondary);
      color: var(--text-static-secondary);
    }
  }

  .title-row {
    padding: $pg-grid-space-unit-3x $pg-grid-space-unit-4x $pg-grid-space-unit-2x;
    display: flex;
    justify-content: space-between;
  }

  .map {
    flex: 1;
    background: var(--pg-grey);
    overflow: hidden;
    position: relative;
    touch-action: none;

    tspan,
    text {
      cursor: pointer;
    }

    .map-station {
      cursor: pointer;
      fill: var(--pg-white);
      stroke: var(--pg-black);

      &.checked {
        fill: var(--pg-brand-primary);
        stroke: var(--pg-white);
      }

      &:hover,
      &.checked {
        /** to add shadow on svg element, we need drop-shadow filter
        * this is the only exception we don't use pg-box-shadow variables
        * due to filter format is different from box-shadow (x, y, radius, color) vs (x, y, dx, dy, color)
        */
        filter: drop-shadow(0 2px 3px rgba(44, 44, 44, 0.5));
      }
    }

    &.hide-future-lines {
      g[id$='-future'] {
        display: none;
      }
    }

    .map-zoom-buttons {
      position: absolute;
      bottom: $pg-grid-space-unit-4x;
      right: $pg-grid-space-unit-4x;
      display: flex;
      flex-direction: column;

      .btn {
        padding: $pg-grid-space-unit-3x;
        box-shadow: $pg-input-box-shadow;
      }

      .btn + .btn {
        margin-top: $pg-grid-space-unit-4x;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .mrt-search-modal-root {
    .modal-dialog {
      width: 90%;
      max-width: none;
    }

    .mrt-search-modal-header {
      height: $desktop-modal-header-height;
    }

    .mrt-search-modal-footer {
      height: $desktop-modal-footer-height;

      .actionable {
        width: 156px;
      }
    }

    .modal-dialog-scrollable {
      .mrt-search-modal-body {
        height: $desktop-modal-body-height;
      }
    }

    .title-row {
      padding: $pg-grid-space-unit-4x 0 $pg-grid-space-unit-2x 0;
    }

    .mrt-search-content {
      padding: $pg-grid-space-unit-4x $pg-grid-space-unit-4x;

      .accordion-body {
        padding: 0;
      }

      .search-bar {
        padding: 0;
        box-shadow: none;
      }

      .station-row {
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap: $pg-grid-space-unit-3x;
      }
    }

    .map,
    .panzoom-container {
      height: $desktop-map-container-height;
    }

    .mrt-search-content .sidebar {
      height: $desktop-sidebar-container-height;
      display: flex;
      flex-direction: column;
    }
  }
}

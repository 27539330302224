@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';

.actionable-link {
  display: inline-block;
  align-items: center;
  gap: $pg-grid-space-unit-2x;
  cursor: pointer;

  & > span {
    vertical-align: middle;
  }

  .badge {
    @include typography.hui-caption;
    font-weight: $pg-font-weight-body-bold;
    border-radius: $pg-grid-space-unit;
    text-transform: uppercase;
    color: white;
    padding: 2px $pg-grid-space-unit;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3' as typographyV3;
@import 'root/styles/core/default-theme';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.agent-review-submit-modal {
  &-title,
  &-description {
    margin: 0;
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: $pg-grid-space-unit-6x;
  }

  &-title {
    @include typographyV3.pg-font-label-l;
  }

  &-description {
    @include typography.hui-body-big-regular;
    color: var(--pg-grey-dark);
  }

  &-close-btn {
    align-self: flex-end;
  }
}

@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.finance-enquiry-view-root {
  .finance-enquiry-view {
    margin: $pg-grid-space-unit-2x $pg-grid-space-unit-4x $pg-grid-space-unit-6x;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__error {
      @include typographyV3.pg-font-caption-xs;

      margin-top: var(--pg-grid-space-unit);
      color: var(--text-error-primary);
    }

    &__icon {
      filter: invert(20%) sepia(100%) saturate(3965%) hue-rotate(154deg) brightness(96%) contrast(103%);
      margin-bottom: var(--pg-grid-space-unit-4x);
    }

    &__title {
      @include typographyV3.pg-font-title-xs;

      text-align: center;
      color: var(--text-success-primary);
      margin-bottom: var(--pg-grid-space-unit-4x);
    }

    &__subtitle {
      @include typographyV3.pg-font-caption-m;

      text-align: center;
      color: var(--text-active-secondary);
      margin-bottom: var(--pg-grid-space-unit-8x);
    }

    &__body {
      display: flex;
      flex-direction: column;
      border-radius: var(--pg-border-radius-large);
      border: 1px solid var(--border-active-primary);
      padding: var(--pg-grid-space-unit-6x);
      width: 100%;
    }

    &__about {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--pg-grid-space-unit-6x);
      width: 100%;
    }

    &__about-title-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__about-title {
      @include typographyV3.pg-font-label-l;

      text-align: center;
    }

    &__about-btn-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--pg-grid-space-unit-4x);
      width: 100%;
    }

    &__about-btn {
      width: 100%;
    }

    &__logo {
      margin-top: var(--pg-grid-space-unit-6x);
      margin-left: auto;
    }

    &__input {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--pg-grid-space-unit-6x);
    }

    &__input-btn-logo {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__input-back-btn {
      @include typographyV3.pg-font-body-xs;
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      margin-right: auto;
      display: flex;
      align-items: center;

      .pgicon-arrow-left {
        font-size: 18px;
        margin-right: var(--pg-grid-space-unit-2x);
      }
    }

    &__input-title {
      @include typographyV3.pg-font-label-l;

      text-align: center;
    }

    &__input-policy {
      @include typographyV3.pg-font-caption-xs;

      margin-top: var(--pg-grid-space-unit-6x);
      margin-bottom: var(--pg-grid-space-unit-4x);
      color: var(--pg-text-active-secondary);
    }

    &__form-group {
      display: flex;
      flex-direction: column;
    }

    &__name-mobile-group {
      display: flex;
      flex-direction: column;
      gap: var(--pg-grid-space-unit-4x);
    }

    &__name-group {
      min-width: 328px;
    }

    &__mobile-group {
      min-width: 328px;
    }

    &__name-label.form-label {
      @include typographyV3.pg-font-caption-s;

      color: $pg-gray-90;
    }

    &__mobile-label.form-label {
      @include typographyV3.pg-font-caption-s;

      color: var(--pg-text-active-primary);
    }

    &__success {
      display: flex;
      flex-direction: column;
      gap: var(--pg-grid-space-unit-4x);
    }

    &__success-title-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__success-title {
      @include typographyV3.pg-font-label-l;

      color: var(--pg-text-active-secondary);
    }

    &__success-subtitle {
      @include typographyV3.pg-font-caption-xs;

      color: var(--pg-text-active-secondary);
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.sign-up-view-root {
  .checklist-root {
    padding: 0 15px;
  }

  .pglogin-viewport {
    .generic-input-group-root {
      margin-top: 20px;
    }
  }
}

@include media-breakpoint-up(md) {
  .sign-up-view-root {
    .checklist-root {
      padding: 0;
    }
  }
}

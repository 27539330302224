@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.contact-button-root {
  &.actionable-link,
  &.actionable-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $pg-grid-space-unit-2x;

    &.btn-primary {
      color: $pg-white;
    }

    i,
    img {
      font-size: $pg-grid-space-unit-5x;
      line-height: $pg-grid-space-unit-5x;
      width: 24px;
      height: auto;
    }
  }
}

.contact-card-root {
  border-radius: $pg-border-radius-large;
  border: 1px solid $border-active-primary;
  padding: $pg-grid-space-unit-4x $pg-grid-space-unit-4x $pg-grid-space-unit-2x;
  margin-top: $pg-grid-space-unit-3x;

  .title {
    @include typographyV3.pg-font-label-m;
    color: $text-active-primary;
    font-weight: $pg-font-weight-experiment-heading;
  }

  .content {
    .qr-code-view-root {
      margin-top: $pg-grid-space-unit-4x;
      margin-bottom: $pg-grid-space-unit-2x;
      display: grid;
      grid-template-columns: 160px 1fr;
      grid-gap: $pg-grid-space-unit-3x;

      .qr-code-group {
        display: flex;
        align-items: center;
        justify-content: center;

        &.loading {
          height: 160px;
          background-color: $fill-neutral-primary;
          border-radius: $pg-border-radius-small;

          .spinner-border {
            width: 32px;
            height: 32px;
          }
        }
      }

      .qr-code-description {
        @include typographyV3.pg-font-caption-xs;
        color: $text-active-secondary;
      }
    }

    .hui-alert-root {
      margin-top: $pg-grid-space-unit-4x;
      margin-bottom: $pg-grid-space-unit-2x;
    }

    .contact-button-root {
      @include typographyV3.pg-font-body-m;
      font-size: $pg-font-size-heading-4;
      color: $text-active-primary;
      padding: $pg-grid-space-unit-3x 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;

      .action-text {
        flex-grow: 1;
      }

      .spinner-border {
        width: 32px;
        height: 32px;
      }
    }
  }
}

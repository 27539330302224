.search-input-root {
  .input-group {
    border: 1px solid var(--pg-grey-medium);
    border-radius: 8px;
    overflow: hidden;
    input {
      border: 0;
      border-radius: 7px;
    }
    .input-group-text {
      background-color: transparent;
      padding: 0;
      border: 0;
      border-radius: 7px;
      cursor: pointer;

      .btn {
        border-radius: 0;
      }

      i {
        padding: 0 10px;
      }
    }
  }
}

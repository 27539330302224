@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/themes/pg-v2/mixins.scss' as mixinsV2;
@import 'root/styles/core/default-theme';

.feedback-submitted-view-root {
  &.hui-modal-root .modal-dialog {
    max-width: 610px;
  }

  .modal-dialog .modal-content {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    box-shadow: $pg-box-shadow-soft;
    border-radius: $pg-grid-space-unit-2x;
    min-height: 264px;
  }

  &.feedback-submitted-view--bottomsheet.offcanvas.offcanvas-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    box-shadow: $pg-box-shadow-soft;
    height: 264px;
    border-radius: $pg-grid-space-unit-4x $pg-grid-space-unit-4x 0 0;
    padding: unset;
  }

  .view {
    &__close {
      position: absolute;
      font-size: var(--pg-font-size-heading-2);
      right: $pg-grid-space-unit-6x;
      top: $pg-grid-space-unit-4x;
      color: var(--pg-grey-darker);
      cursor: pointer;
    }

    &__body {
      padding: $pg-grid-space-unit-4x $pg-grid-space-unit-6x $pg-grid-space-unit-6x;
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: $pg-grid-space-unit-2x;
      align-items: center;
    }

    &__checked-icon {
      color: var(--pg-green);
      font-size: calc(3 * var(--pg-font-size-body));
      line-height: var(--pg-line-height-heading-1);
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: $pg-grid-space-unit-2x;
      align-items: center;

      &-heading {
        @include typography.hui-experimental-heading3;
        color: var(--pg-green);
      }

      &-description {
        @include typography.hui-body;
      }
    }

    &__footer {
      padding: $pg-grid-space-unit-4x 0 0;
    }

    &__actionable.btn.btn-outline-secondary {
      @include typography.hui-button;
      @include mixinsV2.hui-button-secondary-outline-light;
      padding: $pg-grid-space-unit-2x $pg-grid-space-unit-4x;
    }
  }

  &.feedback-submitted-view--no-button {
    .modal-dialog .modal-content {
      min-height: 248px;
    }

    &.feedback-submitted-view--bottomsheet.offcanvas.offcanvas-bottom {
      height: 248px;

      .view__footer {
        padding-top: $pg-grid-space-unit-6x;
      }
    }

    .view__footer {
      padding-top: $pg-grid-space-unit-9x;
    }
  }
}

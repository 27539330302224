@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@mixin container($className) {
  @include media-breakpoint-up(xs) {
    #{$className} {
      margin: 0 auto;
      padding-inline: $pg-grid-space-unit-3x;
      max-width: 336px;
    }
  }

  @include media-breakpoint-up(sm) {
    #{$className} {
      max-width: 540px;
    }
  }

  @include media-breakpoint-up(md) {
    #{$className} {
      max-width: 720px;
    }
  }

  @include media-breakpoint-up(lg) {
    #{$className} {
      max-width: 960px;
    }
  }

  @include media-breakpoint-up(xl) {
    #{$className} {
      max-width: 1140px;
    }
  }
}

@mixin hui-link-item {
  text-decoration: none;
  background-color: none;
  border: 0;
  display: flex;
  gap: $pg-grid-space-unit;
  align-items: center;
  color: var(--pg-black);

  // To render NEW badge against diff links. Enforce background color
  .badge {
    background-color: var(--pg-green);
  }
}

@mixin hui-sliding-red-border {
  &:after {
    border-bottom: 2px solid var(--pg-brand-primary);
    content: '';
    display: block;
    transition: width 0.25s ease-in-out;
    width: 0;
  }

  &:hover,
  &.active {
    &:after {
      width: 100%;
    }
  }
}

@mixin hui-nav-outline-button {
  @include typography.hui-body-default-bold;
  border: 1px solid var(--pg-grey-500);
  border-radius: $pg-grid-space-unit-2x;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
  gap: $pg-grid-space-unit-2x;
  text-decoration: none;

  &:hover {
    background-color: var(--pg-grey);
  }

  &.square {
    padding: $pg-grid-space-unit-2x;
  }

  &.wide {
    padding: $pg-grid-space-unit $pg-grid-space-unit-4x;
  }
}

@mixin nav-link-list {
  .nav-link-list {
    display: flex;
    align-items: center;
    margin-right: auto;
    gap: $pg-grid-space-unit-4x;

    .nav-link-item {
      @include hui-link-item;
      word-wrap: unset;

      .nav-link-item-text {
        @include typography.hui-body-default-regular;
      }
    }
  }
}

// Generic Pulse Mixin
@mixin hui-with-pulsar {
  position: relative;

  .pulse-dot {
    background-color: var(--pg-brand-primary);
    border-radius: 50%;
    height: $pg-grid-space-unit-2x;
    width: $pg-grid-space-unit-2x;
    position: absolute;
    right: -2px;
    top: -2px;

    &.highlight::before {
      -webkit-animation: pulsate 1s ease-out;
      -webkit-animation-iteration-count: 3;
      animation: pulsate 1s ease-out;
      animation-iteration-count: 3;
      border: 2px solid var(--pg-brand-primary);
      border-radius: 50%;
      content: '';
      display: block;
      height: 8px;
      width: 8px;
    }
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(1, 1);
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

// Generic Dropdown Mixins
@mixin hui-dropdown {
  position: relative;
}

@mixin hui-dropdown-menu-wrapper {
  position: absolute;
  z-index: $pg-z-index-6;
  margin-top: $pg-grid-space-unit-2x;

  display: none;
  flex-direction: column;
  gap: $pg-grid-space-unit-2x;

  border-radius: $pg-grid-space-unit-2x;
  border-width: 0;
  box-shadow: $pg-box-shadow-soft;

  background-color: white;
  min-width: 160px;
  padding: $pg-grid-space-unit-2x 0;
  text-align: left;

  &-end {
    right: 0;
  }

  &.show {
    display: flex;
  }
}

@mixin hui-dropdown-item-wrapper {
  @include hui-link-item;
  @include typography.hui-body-default-regular;
  padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
  gap: $pg-grid-space-unit-2x;

  &:hover,
  &.active {
    background-color: var(--pg-grey);
  }
}

@mixin hui-dropdown-internal-header {
  @include typography.hui-caption;
  padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
  color: var(--pg-grey-dark);
  text-transform: uppercase;
}

@mixin hui-dropdown-item-separator {
  height: 0;
  margin: $pg-grid-space-unit-2x;
  overflow: hidden;
  border-top: 1px solid var(--pg-grey-medium);
}

@mixin dropdown($selector, $isLink) {
  #{$selector} {
    @include hui-dropdown;

    .dropdown-toggle::after {
      display: none;
    }

    #{$selector}-button {
      @if $isLink {
        @include hui-link-item; // Include link-specific styles
      } @else {
        @include hui-nav-outline-button; // Include button-specific styles
      }

      .pgicon-arrow-down {
        transition: transform 0.15s ease-out;
      }
    }

    &.show {
      #{$selector}-button {
        .pgicon-arrow-down {
          transform: rotateZ(180deg);
        }
      }
    }

    #{$selector}-menu {
      @include hui-dropdown-menu-wrapper;

      &-end {
        right: 0;
      }

      #{$selector}-item {
        @include hui-dropdown-item-wrapper;
      }

      #{$selector}-divider {
        @include hui-dropdown-item-separator;
      }

      #{$selector}-header {
        @include hui-dropdown-internal-header;
      }
    }
  }
}

@mixin dropdown-button($selector) {
  @include dropdown($selector, false);
}

@mixin dropdown-link($selector) {
  @include dropdown($selector, true);
}

// Generic Accordion Mixin
@mixin accordion($selector) {
  #{$selector} {
    #{$selector}-item {
      #{$selector}-header {
        .accordion-button {
          @include hui-link-item;
          @include typography.hui-body-default-regular;
          padding: $pg-grid-space-unit-2x 0;

          outline: none;
          border: none;
          background-color: none;

          &::after {
            display: none;
          }

          #{$selector}-button-text {
            flex: 1 1 0;
          }

          .pgicon-arrow-down {
            transition: transform 0.15s ease-out;
            transform: rotateZ(180deg);
          }

          &.collapsed {
            .pgicon-arrow-down {
              transform: none;
            }
          }
        }
      }

      .accordion-collapse {
        #{$selector}-body {
          height: 0;
          transition: height 0.3s ease-in-out;
        }

        &.show {
          #{$selector}-body {
            height: auto;
          }
        }
      }
    }
  }
}

// Special Mixins
@mixin local-switch-dropdown-mobile-overrides($selector) {
  #{$selector} {
    position: absolute;
    right: $pg-grid-space-unit-4x;
    bottom: $pg-grid-space-unit-4x;

    &.dropup {
      .dropdown-toggle:after {
        display: none;
      }

      #{$selector}-menu {
        bottom: 100%;
        margin-bottom: $pg-grid-space-unit-2x;
        min-width: initial;
        padding: 0;
        overflow: hidden;
        gap: 0;

        #{$selector}-item {
          padding: $pg-grid-space-unit-3x $pg-grid-space-unit-4x;

          &.active {
            background-color: var(--pg-grey-500);
            color: white;
          }
        }
      }
    }

    #{$selector}-button {
      gap: $pg-grid-space-unit;
    }
  }
}

@mixin local-switch-dropdown-desktop-overrides($selector) {
  #{$selector} {
    #{$selector}-button {
      // Otherwise icon becomes smaller.
      gap: 0;
      height: 30px;
    }

    #{$selector}-menu {
      min-width: initial;
      padding: 0;
      overflow: hidden;
      gap: 0;

      #{$selector}-item {
        padding: $pg-grid-space-unit-3x $pg-grid-space-unit-4x;

        &.active {
          background-color: var(--pg-grey-500);
          color: white;
        }
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.in-page-tab-navigation-root {
  position: fixed;
  z-index: calc($pg-global-navbar-z-index - 1);
  box-shadow: $pg-box-shadow-soft;
  border-top: 1px solid var(--pg-grey-medium);
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  background-color: var(--pg-grey-lighter);

  .tab {
    @include typography.hui-caption;
    text-decoration: none;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $pg-grid-space-unit 0;
    color: var(--pg-grey-dark);
    position: relative;

    &:active {
      background-color: var(--pg-grey);
    }

    &.active {
      color: var(--pg-brand-primary-dark);

      &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: var(--pg-brand-primary);
      }
    }

    .icon-wrapper {
      width: 24px;
      height: 24px;

      .tab-icon {
        font-size: 24px;
        line-height: 24px;
      }

      .svg-icon-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    .tab-text {
      margin-top: 2px;
    }

    // the padding property is applied to render the badge properly in Guruland
    .badge {
      position: absolute;
      top: -22px;
      padding: $pg-grid-space-unit $pg-grid-space-unit-2x !important;
    }
  }
}

@include media-breakpoint-up(xl) {
  .in-page-tab-navigation-root {
    top: 0;
    width: auto;
    flex-direction: column;
    box-shadow: none;
    padding-top: $pg-global-navbar-desktop-height;
    border-top: none;
    border-right: 1px solid var(--pg-grey);

    .tab {
      padding: $pg-grid-space-unit-4x $pg-grid-space-unit-2x;
      flex-grow: unset;

      &:hover {
        background-color: var(--pg-grey);

        &:active {
          background-color: var(--pg-grey-medium);
        }
      }

      &.active {
        &::after {
          top: 0;
          left: 0;
          width: 2px;
          height: unset;
          background-color: var(--pg-brand-primary);
        }
      }

      .badge {
        top: 70px;
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;

.action-strip {
  display: flex;

  .btn {
    @include typography.hui-body-default-bold;
    margin-right: 15px;
    padding: 4px 20px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    &.btn-secondary {
      color: var(--pg-white);
    }

    &.btn-outline-secondary {
      border: 1px solid var(--pg-grey-medium);
    }

    &.btn-light {
      @include typography.hui-body-default-regular;
      color: var(--pg-grey-darker);
    }

    .icon-wrapper {
      display: contents;
    }

    svg {
      margin-right: 5px;
    }

    i {
      margin-right: 5px;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.social-segment-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-block: $pg-grid-space-unit-4x;

  .icon-link,
  .icon-text {
    display: flex;
    text-decoration: none;
    color: var(--pg-white);
    height: 100%;
  }

  .icon-link {
    .pgicon-x,
    .pgicon-tiktok {
      width: 16px;
      height: 16px;

      &::before {
        background-color: var(--pg-white);
      }
    }
  }

  .icon-text-social {
    @include typography.hui-body-default-regular;
    color: var(--pg-white);
    height: min-content;
    margin: auto;
  }

  .left-pane {
    display: none;
    flex: 1;
    flex-direction: row;
    align-items: center;
  }

  .right-pane {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: $pg-grid-space-unit-4x;
  }
}

@include media-breakpoint-up(md) {
  .social-segment-root {
    gap: $pg-grid-space-unit-3x;

    .left-pane {
      display: flex;
      gap: $pg-grid-space-unit-6x;

      .icon-link {
        i {
          padding-right: 8px;
        }
      }
    }

    .right-pane {
      justify-content: flex-end;
    }
  }
}

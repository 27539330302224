@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.location-filter-modal-search-root {
  padding-bottom: 28px;

  .input-group {
    display: flex;
    align-content: center;
    height: 36px;
    border: 1px solid var(--pg-grey-dark);
    input {
      @include typography.hui-body-default-regular;
    }
    ::placeholder,
    i {
      color: var(--pg-grey-dark);
    }
  }
}

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.sticky-enquiry-wrapper-root {
  transition: transform 0.5s;

  &.hidden {
    transform: translateY(100%);
  }
}

@include media-breakpoint-up(lg) {
  .sticky-enquiry-wrapper-root {
    display: none;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.multi-level-variant-root {
  .multi-level-body-section {
    width: 100%;
    position: relative;
    .filter-modal-content {
      position: absolute;
      width: 100%;
      transition: transform 0.5s ease;
      &.move-right {
        left: 600px;
        transform: translateX(100%);
      }
      &.move-left {
        right: 600px;
        transform: translateX(-100%);
        max-height: 0;
        overflow: hidden;
      }
    }
  }

  .form-check {
    margin: 0 0 2px;
  }

  .expandable-list {
    width: inherit;
    background: var(--pg-grey);
    input {
      margin-left: 0;
    }
    max-height: 0;
    transition: 0.35s ease;
    overflow-y: auto;

    .clickable {
      margin: 0 0 2px;
      padding: var(--pg-grid-space-unit-3x) var(--pg-grid-space-unit-6x);
    }
  }

  .form-check {
    display: flex;
    align-items: center;
    gap: var(--pg-grid-space-unit-2x);

    .form-check-input {
      margin-top: 0;
      width: 14px;
      height: 14px;
      background: inherit;
    }

    input[type='radio'] {
      &:before {
        left: -1px;
        top: -7px !important;
      }

      &:checked {
        &::before {
          color: var(--pg-white);
        }
      }
    }
  }

  .list-group-item {
    @include typography.hui-body-default-regular;
    border: none;
    padding: 14px 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &.hidden {
      display: none;
    }
  }
}

@include media-breakpoint-up(md) {
  .multi-level-variant-root {
    .list-group-item {
      padding: 14px 15px;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'root/styles/core/default-theme';

.finance-banner-root {
  .finance-banner {
    @include typography.hui-body-default-bold;
    color: var(--pg-teal-dark);
    padding: $pg-grid-space-unit-4x;

    &__link {
      @include typography.hui-body-default-regular;
      color: var(--pg-brand-primary);
      display: inline;
    }

    &__icon {
      margin-right: $pg-grid-space-unit;
      transform: scale(0.584);
      flex-shrink: 0;
      max-height: 24px;
      align-self: flex-start;
    }

    .verified-icon-outer-shell {
      fill: var(--pg-teal-dark);
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.tabs-navigator-placeholder {
  height: 64px;
}

.tabs-navigator-root {
  border-bottom: 1px solid var(--pg-grey-medium);
  background-color: var(--pg-white);

  .tabs-navigator-body {
    display: flex;
    margin: 0 auto;

    & .spacer {
      padding: 0;
    }

    & .nav-link.active {
      margin-bottom: -1px;
      background-color: transparent;
      border-bottom: 0;

      .orange {
        color: var(--pg-orange-light);

        path {
          fill: var(--pg-orange-light);
        }
      }

      .red {
        color: var(--pg-brand-primary);

        path {
          fill: var(--pg-brand-primary);
        }
      }

      .purple {
        color: var(--pg-purple);

        path {
          fill: var(--pg-purple);
        }
      }

      .green {
        color: var(--pg-teal-dark);

        path {
          fill: var(--pg-teal-dark);
        }
      }
    }
  }

  &.sticky {
    position: fixed;
    left: 0;
    width: 100%;
    box-shadow: $pg-box-shadow-soft;
    border-bottom: 0;
  }
}

@include media-breakpoint-down(md) {
  .tabs-navigator-root {
    .tabs-navigator-body {
      padding-left: 14px;
      padding-right: 14px;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.property-preference-card-root {
  &.card {
    border-radius: 8px;
    padding: 15px;
    border-color: var(--pg-grey-medium);
  }

  .card-header {
    background-color: transparent;
    display: flex;
    flex-direction: row-reverse;
    border: 0;
    padding: 0;

    .card-img {
      width: 50px;
      height: 50px;
    }

    .property-preference-card-header {
      flex-grow: 1;

      .card-title {
        @include typography.hui-heading4;
        margin-bottom: 5px;
      }

      .pgicon {
        font-size: 14px;
      }

      .actionable {
        @include typography.hui-body-default-bold;
        color: var(--pg-brand-primary);

        .pgicon {
          margin-left: 0;
        }
      }
    }
  }

  .card-body {
    padding: 0;

    .card-text {
      @include typography.hui-body-default-regular;
      margin: 15px 0 0;
    }

    .preference-input-group {
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      .form-label {
        @include typography.hui-caption;
      }

      .hui-input {
        max-width: 217px;
      }

      .form-error {
        @include typography.hui-caption;
        color: var(--pg-orange-dark);
        margin-top: 5px;
      }
    }

    .save-btn {
      margin-top: 20px;
    }
  }
}

@include media-breakpoint-up(md) {
  .property-preference-card-root {
    .card-header {
      flex-direction: row;

      .card-img {
        width: 40px;
        height: 40px;
      }

      .property-preference-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .card-title {
          padding-left: 12px;
        }
      }
    }
  }
}

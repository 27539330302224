@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';

.login-view-root {
  display: flex;
  flex-direction: column;
  align-items: center;

  .hui-loader-root {
    z-index: $pg-z-index-1;
    position: initial;
    padding-top: $pg-grid-space-unit-4x;
  }

  .generic-input-group-root {
    margin-top: 15px;
  }

  .divider-root {
    margin: 25px 0;
  }

  .social-login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }
}

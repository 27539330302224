@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.agent-past-transactions {
  &-root {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .pg-legal-info-root {
      @include typography.hui-caption;
      padding: 0;
      background: none;
      color: var(--pg-grey-dark);

      .container {
        padding: 0;
      }
    }
  }

  &-heading {
    @include typography.hui-heading3;
    color: var(--pg-black);
    margin: 0;
  }

  &-group-view {
    display: flex;
    gap: 24px;

    & .progress-group-view-root {
      flex: 1 1 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .agent-past-transactions {
    &-group-view {
      flex-direction: column;
    }
  }
}

@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'root/styles/core/default-theme';

.share-profile-card-root {
  background-color: $border-active-secondary;
  border-radius: $pg-border-radius-medium;
  padding: $pg-grid-space-unit-4x;

  .header-group {
    display: flex;
    justify-content: space-between;

    .heading {
      @include typographyV3.pg-font-label-m;
      color: $text-active-primary;
    }

    .profile-edit-btn {
      color: $icon-active-primary;

      img {
        height: 24px;
        width: 24px;
      }
    }
  }

  .description {
    @include typographyV3.pg-font-body-xs;
    color: $pg-grey-darkest;
    margin-top: $pg-grid-space-unit-3x;
  }

  .completion-progress {
    @include typographyV3.pg-font-caption-xs;
    display: flex;
    margin: $pg-grid-space-unit-4x 0 $pg-grid-space-unit-3x 0;

    .hui-progress-root {
      flex: 1 0 auto;
      align-self: center;
      margin-right: $pg-grid-space-unit-2x;

      & .progress {
        background-color: var(--fill-neutral-secondary) !important;
      }
    }
  }

  .divider-root {
    margin-top: $pg-grid-space-unit-2x;
    height: 0;
  }

  .profile-sharing-toggle {
    .toggle-group {
      display: flex;
      justify-content: space-between;
      margin-top: $pg-grid-space-unit-5x;
    }

    .toggle-label {
      @include typographyV3.pg-font-body-s;
      color: $text-active-primary;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

@mixin horizontal-row {
  overflow: scroll;
  flex-wrap: nowrap;
  padding-bottom: $pg-grid-space-unit-4x;
}

.card-group-container-root.container {
  max-width: 100%;
  .card-group-root {
    .horizontal.row {
      padding-bottom: $pg-grid-space-unit-4x;
    }

    .section-header {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin-bottom: $pg-grid-space-unit-2x;

      .section-title {
        @include typography.hui-heading2;
        margin-right: $pg-grid-space-unit-4x;
        margin-bottom: 0;
      }

      .label {
        display: flex;
        &__content {
          @include typography.hui-caption;
          border-radius: 5px;
          color: #fff;
          padding: 0 calc($pg-grid-space-unit-2x + 2px);
          height: 20px;
          margin: auto;
        }
        .bg-label {
          background-color: var(--pg-orange-dark);
          border: 1px solid var(--pg-orange-dark);
        }
      }

      .action-section {
        display: flex;
        position: absolute;
        right: 0;
        height: 100%;
        .actionable-link {
          position: relative;
          margin: auto;
        }
      }

      .actionable-primary {
        font-weight: $pg-font-weight-body-bold;
        font-size: $pg-font-size-body-regular;
        line-height: $pg-line-height-body-regular;
      }
    }

    .actionable-secondary {
      display: none;
      margin: auto;
      width: max-content;
    }
  }
}

@include media-breakpoint-down(md) {
  .card-group-container-root.container {
    .card-group-root {
      .actionable-secondary {
        display: flex;
      }

      .horizontal.row {
        @include horizontal-row;
      }

      .horizontal.row > * {
        max-width: 225px;
        min-width: 225px;
      }

      .section-header {
        .actionable-primary {
          display: none;
        }
      }
    }
  }

  .rlc.container {
    max-width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .card-group-container-root.container {
    max-width: 100%;
    .card-group-root {
      .horizontal-single.row {
        @include horizontal-row;
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;

.pg-login-footer-root {
  @include typography.hui-caption;
  color: var(--pg-grey-dark);
  text-align: center;
  padding: 17px 24px;
  border-top: 1px solid var(--pg-grey-medium);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  a {
    display: inline-block;
    color: var(--pg-brand-primary);
    text-decoration: none;
    &:hover {
      color: var(--pg-brand-primary);
    }
  }
}

@mixin text-clip($line-clamp) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-clamp;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin pin-styles {
  @include typographyV3.pg-font-label-m;
  align-items: center;
  background-color: var(--poi-location-icon-background);
  border-radius: $pg-border-radius-full;
  border: 2px solid var(--border-active-secondary);
  box-shadow: $pg-hard-down-small;
  cursor: pointer;
  display: inline-flex;
  height: 32px;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  width: 32px;
}

@mixin arrow-styles($bottom) {
  &::before {
    background-color: var(--poi-location-icon-background);
    border-color: var(--border-active-secondary);
    border-style: solid;
    border-width: 0 2px 2px 0;
    bottom: $bottom;
    content: '';
    height: 8px;
    position: absolute;
    transform: rotate(45deg);
    width: 8px;
  }
}

@mixin back-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $pg-grid-space-unit-12x;
  height: $pg-grid-space-unit-12x;
  font-size: $pg-grid-space-unit-5x;
  line-height: $pg-grid-space-unit-5x;
  filter: $pg-box-shadow-small;
  border: 1px solid $border-active-primary;
  border-radius: $pg-border-radius-full;
  .svg-icon-wrapper {
    position: absolute;
    width: $pg-grid-space-unit-5x;
    height: $pg-grid-space-unit-5x;

    path {
      fill: transparent !important;
    }
  }
}

@mixin pois-notfound-wrapper-styles($paddingTop) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: $paddingTop;
  padding-bottom: 60px;

  .pois-notfound-icon {
    margin: 0 auto $pg-grid-space-unit-3x;
  }

  .pois-notfound-text {
    @include typographyV3.pg-font-label-s;
  }
}

@mixin hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Edge */
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.hui-page-header {
  &-root {
    background: var(--pg-brand-primary-lightest);
    height: 118px;
  }

  &-wrap {
    display: flex;
    align-items: flex-end;
    height: inherit;
    position: relative;
  }

  &-left-wrap {
    flex: 1 1 0;

    & .heading {
      @include typography.hui-heading2;
      color: var(--pg-black);
      margin: 0;
    }

    & .description {
      @include typography.hui-caption;
      color: var(--pg-black);
    }
  }

  &-image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
  }

  @include media-breakpoint-up(lg) {
    &-image {
      flex: 1 1;
      object-fit: contain;
      object-position: right;
      height: 100%;
      position: relative;
    }
  }

  @include media-breakpoint-up(sm) {
    &-wrap {
      align-items: center;
    }
  }
}

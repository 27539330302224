@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.enquiry-card-root {
  display: flex;
  margin-top: 20px;
  border: 1px solid var(--pg-grey);
  border-radius: 8px;
  background-color: var(--pg-white);
  box-shadow: $pg-box-shadow-subtle;
  overflow: hidden;

  .body-left {
    display: flex;
    flex-grow: 1;
  }

  .divider {
    width: 1px;
    background-color: var(--pg-grey);
  }

  .body-right {
    min-width: 290px;
    display: flex;
  }
}

@include media-breakpoint-down(md) {
  .enquiry-card-root {
    flex-direction: column;

    .divider {
      width: 100%;
      height: 2px;
    }

    .body-right {
      width: 100%;
    }
  }
}

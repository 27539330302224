@import 'root/styles/core/default-theme';

.reasons-popover-modal-root {
  .modal-content {
    padding: unset;
    border: unset;
    background-color: transparent;
  }
}

.reasons-bottomsheet-root {
  &.hui-bottom-sheet.offcanvas-bottom {
    padding: 0;
    background-color: transparent;
    height: auto;

    .bottomsheet-drilldown-popup {
      border-radius: $pg-grid-space-unit-4x $pg-grid-space-unit-4x 0 0;
    }
  }
}

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.cookie-banner-section-root {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $pg-z-index-max;
  width: 100%;
  padding: 16px 16px 24px;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .cookie-banner-root {
    animation: fade-in 0.5s ease-in-out;
  }
}

@include media-breakpoint-up(lg) {
  .cookie-banner-section-root {
    width: 296px;
    padding: 16px;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.hs-agent-section {
  &-root {
    .card-group-container-root {
      margin-top: 12px;
      padding: 0;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-link {
    @include typography.hui-body-default-bold;
    color: var(--pg-brand-primary);
  }
  &-title {
    @include typography.hui-heading3;
    margin-bottom: 12px;
  }

  &-description {
    @include typography.hui-body-default-regular;
    color: var(--pg-grey-darker);
  }
  &-details {
    display: flex;
    overflow: scroll;

    .horizontal-single {
      display: contents;
    }

    &.gated {
      filter: blur(7px);
      pointer-events: none;
    }
  }

  &-viewall-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: $pg-grid-space-unit-3x;
    white-space: nowrap;
  }

  &-side-link {
    display: flex;
    flex-direction: column;
    color: var(--text-active-primary);

    .pgicon-arrow-right-1 {
      font-size: $pg-grid-space-unit-6x;
      padding: $pg-grid-space-unit-3x;
      border-radius: 100px;
      border: 1px solid var(--pg-border-active-primary);
      background: var(--pg-fill-neutral-secondary);
      box-shadow:
        0 2px 4px 0 rgba(40, 44, 46, 0.12),
        0 2px 2px 0 rgba(255, 255, 255, 0.4) inset;
    }
  }
}

@include media-breakpoint-down(lg) {
  .card-group-container-root.container .card-group-root .horizontal-single.row {
    overflow: scroll;
    flex-wrap: nowrap;
    padding-bottom: 16px;
  }
}

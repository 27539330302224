@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.hui-progress-root {
  height: 4px;

  & .progress {
    height: 4px;
    border-radius: 0;

    & .progress-bar {
      transition: background-color 0.3s;
    }
  }

  &.primary-light-lightest {
    & .progress {
      background-color: var(--pg-brand-primary-lightest);

      & .progress-bar {
        background-color: var(--pg-brand-primary-light);
        border-radius: $pg-border-radius-small;
      }
    }
  }

  &.success {
    & .progress {
      // added important here to override the default bootstrap styling which uses !important as well
      background-color: var(--fill-inactive-primary) !important;

      & .progress-bar {
        background: linear-gradient(90.62deg, var(--fill-brand-green) 0%, var(--fill-brand-teal) 100%);
        border-radius: $pg-border-radius-small;
      }
    }
  }
}

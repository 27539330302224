@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.otp-verification-view-root {
  .otp-verification-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 25px;
  }

  .receiving-email-prompt {
    @include typography.hui-body-default-regular;
    color: var(--pg-grey-darker);
    text-align: center;
    margin: 20px 0 0;
  }

  .pglogin-resend-otp-btn {
    align-self: center;
  }

  .hui-alert-root {
    margin-top: 15px;
  }
}

@include media-breakpoint-up(md) {
  .otp-verification-view-root {
    .otp-verification-body {
      padding: unset;
    }
  }
}

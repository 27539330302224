@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'root/styles/core/default-theme';

.agent-info-root {
  display: flex;
  align-items: center;

  &.corporate {
    .avatar-wrapper {
      .avatar-link {
        height: fit-content;
        min-height: 50px;
        border-radius: $pg-grid-space-unit;

        &.no-link {
          cursor: default;
        }

        .avatar {
          height: auto;
          border-radius: $pg-grid-space-unit;
        }
      }
    }
  }

  .avatar-wrapper {
    .avatar-link {
      display: flex;
      align-items: center;
      justify-content: center;

      &.featured-agent {
        border: 2px solid var(--fill-brand-orange);
      }

      &.no-link {
        cursor: default;
      }
    }

    .crown-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      position: absolute;
      top: -4px;
      left: 30px;
      border-radius: 50%;
      background-color: var(--fill-brand-orange);
    }
  }

  .details-wrapper {
    .agent-name-wrapper {
      .no-link {
        cursor: default;
      }
    }
  }

  .avatar-wrapper {
    width: 50px;
    height: 50px;
    position: relative;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    .avatar-link {
      width: 100%;
      height: 100%;
      border: 1px solid var(--pg-grey);
      border-radius: 50%;

      .avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: $pg-soft-down-small;
        object-fit: cover;
      }

      .pgicon-agent {
        font-size: 20px;
        line-height: 20px;
        color: var(--pg-grey-medium);
      }
    }
  }

  .details-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 0;
    flex-grow: 1;
    gap: $pg-grid-space-unit;

    .verification {
      width: fit-content;
    }

    .agent-name-wrapper {
      display: grid;

      .agent-name {
        @include typography.hui-heading4;
        text-decoration: none;

        &.truncate-line {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.no-pointer {
          cursor: default;
        }
      }
    }

    .agent-description {
      @include typography.hui-caption;
      color: var(--pg-grey-dark);
    }

    .agent-rating {
      @include typographyV3.pg-font-caption-xs;
      color: var(--text-active-primary);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      text-decoration: none;
      gap: 0;
      white-space: nowrap;

      &.no-review {
        color: var(--pg-grey-dark);

        .pgicon-star {
          color: var(--pg-grey-dark);
        }

        .rating-text {
          color: var(--pg-grey-dark);
        }
      }

      .pgicon-star {
        font-size: 11px;
        line-height: 11px;
        color: var(--pg-gold);
        margin-right: 4px;

        &::before {
          margin: 0;
        }
      }

      .rating-text {
        margin-left: 8px;
        color: var(--pg-brand-primary);
      }

      .pgicon-arrow-right {
        margin-left: 6px;
        color: var(--pg-brand-primary);
      }
    }

    .agent-certificate,
    .badge-text {
      @include typography.hui-label;
      display: flex;
      align-items: center;
      font-weight: 500;
      color: var(--pg-gold-dark);
      cursor: pointer;
      background-color: unset;

      .hui-image-root {
        width: 12px;
        height: 12px;
        margin-right: 4px;
      }
    }
  }
}

.agent-info-popover {
  .popover-header {
    @include typography.hui-heading5;
    padding: 12px 16px;
    background-color: var(--pg-grey-lighter);
  }

  .popover-body {
    @include typography.hui-body-default-regular;
    padding: 16px;

    .btn {
      margin-top: 8px;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.property-transaction-summary {
  &-root {
    display: flex;
    padding: var(--pg-grid-space-unit-4x);
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--pg-grey-medium);
    background: var(--pg-white);
  }

  &-heading {
    @include typography.hui-heading4;
    margin-bottom: $pg-grid-space-unit-6x;
    color: var(--pg-black);
  }

  &-body {
    display: flex;
    flex-direction: column;
    width: 100%;

    .divider-root {
      .line {
        color: var(--pg-grey-medium);
      }

      &.vertical-divider {
        height: 48px;
        margin: 0 calc(var(--pg-grid-space-unit-4x) * -1);
      }

      &.hdb-section-divider {
        visibility: hidden;
        height: 16px;
      }
    }

    .district-summary {
      display: flex;
      flex-direction: column;

      .property-summary-root {
        flex: unset;
        flex-grow: 1;
      }
    }
  }

  &-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: $pg-grid-space-unit-2x $pg-grid-space-unit-4x;
  }

  @include media-breakpoint-up(md) {
    &-body {
      flex-flow: row nowrap;

      .divider-root {
        height: 48px;

        &.vertical-divider {
          width: 64px;
          height: auto;
          justify-content: center;
          margin: 0;

          .line {
            width: 1px;
            height: 100%;
          }
        }

        &.hdb-section-divider {
          visibility: visible;
          height: 48px;
        }
      }

      .district-summary {
        flex-grow: 2;
      }

      .town-summary {
        flex-grow: 1;
      }
    }

    &-wrap {
      gap: calc($pg-grid-space-unit * 18);
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;

.rating-view-root {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--pg-grey-medium);
  margin-bottom: 0;

  .rating-view-stars {
    display: inline-flex;
    align-items: center;
    gap: 6px;

    i {
      font-size: 10px;
      line-height: 10px;
      width: 10px;
      position: relative;

      &::before {
        margin-left: 0;
      }

      &.filled {
        color: var(--pg-gold);
      }

      &.partially-filled {
        position: relative;

        & > i {
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;

          &::before {
            color: var(--pg-gold);
          }
        }
      }
    }
  }

  figcaption {
    @include typography.hui-caption;
    color: var(--pg-grey-dark);
  }
}

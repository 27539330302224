@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.aem-efv-root {
  padding-bottom: 80px;
  
  a {
    text-decoration: none;
    color: var(--pg-brand-primary);

    &:hover {
      color: var(--pg-brand-primary-light);
    }

    &:active {
      color: var(--pg-brand-primary-dark);
    }
  }

  .aem-efv-body {
    padding: 0 15px;
  }

  .efv-form {
    .hui-alert-root {
      @include typography.hui-body-default-regular;
      color: var(--pg-gold-dark);
      margin-bottom: 24px;
      background-color: var(--pg-gold-lightest);
      border: 1px solid var(--pg-gold-lighter);

      .pgicon {
        font-size: 15px;

        &::before {
          margin: 0;
        }
      }
    }

    .efv-contact-context {
      display: flex;
      align-items: center;
      gap: var(--pg-grid-space-unit-3x);
      padding-bottom: var(--pg-grid-space-unit-5x);
      margin-bottom: var(--pg-grid-space-unit-5x);
      border-bottom: 1px solid var(--border-active-secondary);

      .hui-image-root {
        object-fit: cover;
      }

      .agent-logo {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: 1px solid var(--pg-grey-neutral-40);
      }

      .svg-icon-wrapper {
        margin: var(--pg-grid-space-unit-5x) auto;
      }

      .typography-root {
        color: var(--text-active-primary);
        flex-basis: 0;
        flex-grow: 1;
      }
    }

    .efv-section-title {
      @include typographyV3.pg-font-label-m;
      color: var(--text-active-primary);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn-round {
        padding: var(--pg-grid-space-unit-3x);
        border-color: var(--border-active-primary);

        .hui-image-root {
          width: 24px;
          height: 24px;
        }
      }
    }

    .efv-form-group {
      margin-bottom: var(--pg-grid-space-unit-2x);

      .form-input-group {
        position: relative;

        .input-group-text {
          @include typographyV3.pg-font-caption-xs;
          color: var(--text-inactive-primary);
          position: absolute;
          top: var(--pg-grid-space-unit-3x);
          left: var(--pg-grid-space-unit-4x);
          height: 25px;
          padding: 0 var(--pg-grid-space-unit-2x);
          background-color: var(--pg-white);
          border: 0;
        }
      }

      &.invalid {
        .form-control {
          border-color: var(--pg-orange);
          background-color: var(--pg-orange-lightest);
        }

        .text-danger {
          @include typography.hui-caption;
          color: var(--pg-orange);
          margin-top: $pg-grid-space-unit;
        }
      }

      .efv-label {
        @include typographyV3.pg-font-caption-s;
        color: var(--text-active-primary);
        display: flex;
        justify-content: space-between;

        .actionable-link {
          @include typography.hui-body-default-regular;
          color: var(--pg-brand-primary);

          &:hover {
            color: var(--pg-brand-primary-light);
          }

          &:active {
            color: var(--pg-brand-primary-dark);
          }
        }
      }

      .form-control {
        @include typographyV3.pg-font-body-xs;
        line-height: 22px;
        padding: var(--pg-grid-space-unit-3x) var(--pg-grid-space-unit-4x);

        &:not(:first-child) {
          padding-left: var(--pg-grid-space-unit-15x);
        }

        &:disabled {
          background-color: var(--pg-grey);
          border: 1px solid var(--pg-grey-medium);
          color: var(--text-inactive-primary);
        }
      }

      .intent-badge {
        @include typographyV3.pg-font-body-xs;
        padding: 4px 12px;
        border: 1px solid var(--pg-grey-medium);
        border-radius: 18px;
        margin: 0 4px 12px 0;
        color: var(--text-active-primary);

        &.selected {
          color: var(--pg-white);
          border: 1px solid var(--pg-grey-darker);
          background-color: var(--pg-grey-darker);
        }
      }
    }

    .efv-consent {
      @include typography.hui-body-default-regular;
      margin: 8px 0 16px 0;

      .form-check {
        margin-top: var(--pg-grid-space-unit-4x);
        position: relative;
      }

      .efv-consent-description {
        margin-top: 16px;
      }
    }

    .actionable {
      @include typography.hui-body-default-bold;
      color: $pg-white;
      margin: 8px 0 24px 0;

      &:active {
        color: $pg-white;
      }

      &:disabled {
        color: $text-inactive-primary;
      }
    }
  }

  .aem-efv-footer {
    @include typography.hui-caption;
    border-top: 1px solid var(--pg-grey);
    padding: 17px 24px;
    text-align: center;
  }
}

@include media-breakpoint-up(md) {
  .aem-efv-root {
    .aem-efv-body {
      padding: 0 24px;
    }
  }
}

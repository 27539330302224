@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.contact-agent-card-root {
  border: 1px solid var(--pg-grey-medium);
  border-radius: 8px;
  background-color: var(--pg-white);

  .card-header {
    border-bottom: 1px solid var(--pg-grey);

    .agency {
      @include typography.hui-caption;
      color: var(--pg-grey-darker);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--pg-grey-lighter);
      border-radius: 8px 8px 0 0;
      padding: 12px 16px;

      .agency-avatar {
        width: 48px;
        margin-right: 8px;
      }
    }

    .agent-info-root {
      padding: 16px;
    }
  }

  .card-body {
    padding: 16px 16px 0 16px;

    .actionable-link,
    .actionable-text {
      @include typography.hui-heading5;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: var(--pg-grey-medium);
      margin-bottom: 16px;

      i,
      img {
        font-size: 24px;
        line-height: 24px;
        width: 24px;
        height: auto;
      }
    }

    .share-profile-card {
      margin-bottom: $pg-grid-space-unit-4x;
    }

    .terms-and-policy {
      @include typography.hui-caption;
      margin-bottom: 16px;

      a {
        color: var(--pg-brand-primary);
        text-decoration: none;
      }
    }

    .rich-contact-group {
      transition: height 0.5s;
      height: var(--element-height);
      margin-bottom: $pg-grid-space-unit-4x;
      position: relative;

      @keyframes fade-in {
        0% {
          opacity: 0;
        }
        20% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      @keyframes fade-out {
        0% {
          opacity: 1;
        }
        60% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }

      &.extended {
        .button-group {
          animation-name: fade-out;
          opacity: 0;
          pointer-events: none;
        }

        .extended-view-group {
          animation-name: fade-in;
          opacity: 1;
          pointer-events: initial;
        }
      }

      .button-group {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        animation-name: fade-in;
        animation-duration: 1s;
        opacity: 1;
        margin-top: calc(0px - $pg-grid-space-unit-2x);
      }

      .extended-view-group {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        animation-name: fade-out;
        animation-duration: 1s;
        opacity: 0;
        pointer-events: none;
      }

      .contact-button-root {
        @include typography.hui-body;
        padding: $pg-grid-space-unit-5x $pg-grid-space-unit-6x;
        margin: 0 calc(0px - $pg-grid-space-unit-6x);
        width: calc(100% + $pg-grid-space-unit-12x);
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-gap: $pg-grid-space-unit-3x;
        align-items: center;
        text-decoration: none;
        position: relative;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: $pg-grey-medium;
        }

        &:not(:last-child) {
          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: $pg-grid-space-unit-6x;
            border-bottom: 1px solid var(--pg-grey);
            width: calc(100% - $pg-grid-space-unit-12x);
          }
        }

        .pgicon {
          margin: 0;

          &::before {
            margin: 0;
          }
        }

        .hui-image-root {
          margin: 0;
          width: 24px;
          height: 24px;
        }

        .action-text {
          flex-grow: 1;
        }

        .icon-right {
          grid-column: 3;
        }

        .action-description {
          @include typography.hui-caption;
          color: $pg-grey-darker;
          width: 100%;
          margin-bottom: calc(0px - $pg-grid-space-unit-3x);
          margin-top: $pg-grid-space-unit;
          grid-column: 1 / 4;
        }
      }

      .extended-contact-view-root {
        border-radius: $pg-border-radius-medium;
        border: 1px solid $pg-gray-20;
        padding: $pg-grid-space-unit-4x;

        .view-header {
          @include typography.hui-heading4;
          color: $pg-gray-90;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .btn-default {
            width: 35px;
            height: 35px;
            margin: 0;
            padding: 0;
            font-size: $pg-font-size-heading-3;
            border: none;

            &:active {
              border: none;
            }
          }
        }

        .view-body {
          .qr-code-description {
            @include typography.hui-caption;
            font-weight: 400;
            color: $pg-gray-90;
            margin-top: $pg-grid-space-unit-2x;
          }

          .qr-code-group {
            display: flex;
            justify-content: center;
            margin-top: $pg-grid-space-unit-5x;
            margin-bottom: $pg-grid-space-unit;
          }

          .action-group-root {
            margin-top: $pg-grid-space-unit-2x;

            .contact-button-root {
              padding: $pg-grid-space-unit-5x $pg-grid-space-unit-4x;
              margin: 0 calc(0px - $pg-grid-space-unit-4x);
              width: calc(100% + $pg-grid-space-unit-8x);

              &:not(:last-child) {
                &::after {
                  width: calc(100% - $pg-grid-space-unit-8x);
                }
              }
            }
          }
        }
      }
    }

    .contact-agent-actions {
      i,
      img {
        width: $pg-grid-space-unit-6x;
        height: $pg-grid-space-unit-6x;
      }
    }

    .contact-agent-actions.disabled {
      .btn {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .intent-alert {
      margin-bottom: $pg-grid-space-unit-4x;
    }
  }

  .card-footer {
    padding: 16px;
    border-top: 1px solid var(--pg-grey);

    .live-tour {
      @include typography.hui-caption;
      color: var(--pg-grey-darker);
      display: flex;
      align-items: center;

      .live-tour-badge {
        @include typography.hui-label;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: var(--pg-blue);
        display: inline-flex;
        align-items: center;
        border-radius: 8px;
        padding: 6px 10px;
        margin-right: 8px;
        background-color: var(--pg-blue-lightest);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .contact-agent-card-root {
    .card-header {
      .agent-info-root {
        padding: 24px;
      }

      .agency {
        padding: 12px 24px;
      }
    }

    .card-body {
      padding: $pg-grid-space-unit-4x $pg-grid-space-unit-6x 0;

      .actionable {
        margin-bottom: 24px;
      }

      .profile-edit-btn.actionable {
        margin: 0;
      }
    }

    .card-footer {
      padding: 24px;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.share-disable-arrow {
  &.popover {
    .popover-arrow {
      display: none;
    }
  }
}

.popover.sharing-popover-root {
  max-width: 100vw;
  padding: 15px 20px;
  width: 320px;
  box-shadow: $pg-box-shadow-soft;
  background-color: var(--pg-white);

  .popover-arrow:after {
    top: 2px;
  }

  .title {
    @include typography.hui-body-big-regular;
    margin-bottom: 15px;
  }

  .links-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 15px;
    column-gap: 30px;

    > a {
      @include typography.hui-body-default-bold;
      color: var(--pg-white);
      border-radius: 8px;
      padding: 7px 10px;
      background-color: var(--pg-grey-darker);
      text-decoration: none;
      text-align: center;

      &::before {
        margin-right: 7px;
      }
    }

    .full-width {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .btn-default {
      background-color: var(--pg-grey-darker);

      &:hover {
        background-color: var(--pg-grey-dark);
      }

      &:active {
        background-color: var(--pg-black);
      }
    }

    .btn-whatsapp {
      background-color: var(--pg-btn-whatsapp-bg);

      &:hover {
        background-color: var(--pg-btn-whatsapp-bg-light);
      }

      &:active {
        background-color: var(--pg-btn-whatsapp-bg-dark);
      }
    }

    .btn-facebook {
      background-color: var(--pg-btn-facebook-bg);

      &:hover {
        background-color: var(--pg-btn-facebook-bg-light);
      }

      &:active {
        background-color: var(--pg-btn-facebook-bg-dark);
      }
    }

    .btn-twitter {
      background-color: var(--pg-btn-twitter-bg);

      &:hover {
        background-color: var(--pg-btn-twitter-bg-light);
      }

      &:active {
        background-color: var(--pg-btn-twitter-bg-dark);
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;

.generic-input-group-root {
  .form-group {
    position: relative;
    margin: 0;

    .input-group {
      width: 100%;

      &.input-focus {
        .input-group-text {
          border-color: var(--pg-black);
        }
      }

      .generic-input {
        border-radius: var(--pg-border-radius-small);
        padding-right: 30px;
        -moz-appearance: textfield;

        &:focus {
          border-color: var(--pg-black);
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .input-icon {
      position: absolute;
      top: 7px;
      right: 0;
      padding: 0 10px;
      color: var(--pg-grey-dark);

      &:before {
        font-family: 'pgicons';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
      }

      &.pgicon-warning-o {
        display: none;
      }
    }

    &.error {
      .generic-input {
        background: var(--pg-orange-lightest);
        border: 1px solid var(--pg-orange);
      }

      .input-icon {
        color: var(--pg-orange);
        display: block;
      }

      .input-group-text {
        border-color: var(--pg-orange);
      }
    }
  }
  .acceptance-rules {
    padding-top: 10px;

    .rule-wrapper {
      display: flex;

      .rule-text {
        @include typography.hui-body-default-regular;
        margin-bottom: 0;
      }

      .rule-icon {
        padding-right: 10px;

        &:before {
          font-family: 'pgicons';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }

        &.pgicon-ok-circled {
          color: var(--pg-green);
        }

        &.pgicon-radio {
          color: var(--pg-grey-darker);
        }
      }
    }
  }

  .invalid-feedback {
    display: block;
    @include typography.hui-caption;
    color: var(--pg-orange-dark);
  }

  .generic-input-btn {
    @include typography.hui-body-default-regular;
    width: 100%;
    font-weight: 500;
    color: var(--pg-white);
    margin-top: 10px;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

$profile-banner-bg-image-width: 80px;
$profile-banner-bg-image-size: calc(#{$profile-banner-bg-image-width} + (#{$pg-grid-space-unit-6x} * 2));

.profile-banner-root {
  border-radius: $pg-border-radius-medium;
  padding: $pg-grid-space-unit-6x $profile-banner-bg-image-size $pg-grid-space-unit-6x $pg-grid-space-unit-6x;
  background-position: top $pg-grid-space-unit-6x right $pg-grid-space-unit-6x;
  background-repeat: no-repeat;
  background-size: $profile-banner-bg-image-width;

  @include media-breakpoint-up(sm) {
    background-position: center right $pg-grid-space-unit-6x;
  }

  .profile-banner-text-body {
    .profile-banner-text-title {
      @include typography.hui-heading4;
      margin-bottom: 0;
      color: var(--text-active-primary);
    }

    .profile-banner-text-description {
      @include typography.hui-body-default-regular;
      margin-top: $pg-grid-space-unit-2x;
      margin-bottom: 0;
      color: var(--text-neutral-primary);
    }
  }

  .profile-banner-btn {
    margin-top: $pg-grid-space-unit-4x;
  }

  &.primary-lightest {
    background-color: var(--fill-error-secondary);
  }

  &.white {
    background-color: var(--fill-inactive-primary);
  }

  &.inactive {
    background-color: var(--fill-inactive-primary);
    background-position: top $pg-grid-space-unit-6x right $pg-grid-space-unit-6x;
    border: 1px solid var(--border-inactive-primary);

    .profile-banner-text-title {
      @include typographyV3.pg-font-label-l;
    }
  }

  &.success {
    background-color: var(--fill-success-secondary);
    background-position: top $pg-grid-space-unit-6x right $pg-grid-space-unit-6x;
    border: 1px solid var(--border-success-primary);

    .profile-banner-text-body {
      .profile-banner-text-title {
        @include typographyV3.pg-font-label-l;
        @include media-breakpoint-down(sm) {
          @include typographyV3.pg-font-label-m;
        }
      }

      .profile-banner-text-description {
        @include typographyV3.pg-font-body-s;
        margin-top: $pg-grid-space-unit-3x;

        @include media-breakpoint-down(sm) {
          @include typographyV3.pg-font-body-xs;
        }
      }
    }
  }
}

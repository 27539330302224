@use 'root/styles/core/typography-mixins.scss' as typography;

.save-password-view-root {
  .generic-input-group-root {
    padding-top: 15px;
  }

  .skip-text {
    @include typography.hui-body-default-regular;
    color: var(--pg-brand-primary);
    padding-top: 15px;
  }
}

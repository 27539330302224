@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'root/styles/core/default-theme';

.contact-agent-sheet-root-v2 {
  max-width: 100%;
  z-index: 1026 !important;
  .react-modal-sheet-backdrop {
    z-index: $pg-z-index-4 !important;
    background-color: $pg-transparency-black-40 !important;
  }

  .hui-alert-root {
    a {
      color: inherit;
    }
  }

  .bottom-sheet-alert {
    position: absolute;
    bottom: calc(100% + $pg-grid-space-unit-3x);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: calc(100% - 2 * $pg-grid-space-unit-3x);

    .alert-title {
      @include typographyV3.pg-font-label-m;
    }

    .alert-description {
      @include typographyV3.pg-font-body-xs;
    }
  }

  .react-modal-sheet-drag-indicator {
    background-color: var(--fill-neutral-tertiary) !important;
  }

  .react-modal-sheet-header {
    padding-bottom: $pg-grid-space-unit-3x;
    height: $pg-grid-space-unit-12x !important;
  }

  &.bottom-sheet {
    margin: 0;

    .bottom-sheet-container {
      height: unset !important;
      z-index: $pg-z-index-5 !important;
      border-radius: $pg-grid-space-unit-6x $pg-grid-space-unit-6x 0 0 !important;
      box-shadow: $pg-soft-up-medium !important;

      .bottom-sheet-body {
        padding: 0;

        .share-profile-card {
          margin-bottom: $pg-grid-space-unit-5x;
        }

        .contact-agent-sheet-bottom {
          @include typography.hui-caption;
          padding: 0 $pg-grid-space-unit-4x $pg-grid-space-unit-2x $pg-grid-space-unit-4x;
          border-radius: $pg-border-radius-small $pg-border-radius-small 0 0;
          margin-top: $pg-grid-space-unit-5x;

          a {
            text-decoration: none;
          }

          p {
            margin: 0;
          }
        }

        .contact-agent-sheet-body {
          padding: 0 $pg-grid-space-unit-6x $pg-grid-space-unit-6x;

          .intent-alert {
            position: relative;
            margin: $pg-grid-space-unit-4x 0;
            width: 100%;
          }

          .hui-badges-root {
            margin-bottom: $pg-grid-space-unit-5x;

            .hui-badges-label {
              @include typographyV3.pg-font-label-m;
            }

            .badges-group {
              margin: 0;
              overflow-x: unset;
              flex-wrap: wrap;

              .actionable {
                @include typographyV3.pg-font-body-xs;
              }
            }
          }

          .enquiry-status {
            margin-bottom: $pg-grid-space-unit-5x;

            .content {
              @include typographyV3.pg-font-label-s;
              display: flex;
              align-items: center;

              .status-icon {
                margin-right: $pg-grid-space-unit-2x;
              }

              .description {
                @include typographyV3.pg-font-body-xs;
              }
            }
          }

          .contact-agent-actions {
            display: flex;
            flex-direction: column;
            gap: $pg-grid-space-unit-3x;

            .contact-button-root {
              margin: 0;
            }

            .btn {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0;
            }

            .btn-primary {
              color: var(--pg-white);
            }

            > * {
              @include typographyV3.pg-font-label-m;
              width: 100%;

              i,
              img,
              .svg-icon-wrapper {
                margin-right: $pg-grid-space-unit;
              }

              img {
                font-size: 16px;
                line-height: 16px;
                width: 24px;
                height: 24px;
              }

              .svg-icon-wrapper {
                display: inline-block;
                width: 24px;
                height: 24px;
              }

              .btn-primary {
                color: var(--pg-white);
              }
            }
          }
        }
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;

.verified-contact-input-root {
  .verified-contact-input-title {
    @include typography.hui-body-default-regular;
    color: var(--pg-black);
  }

  .verified-contact-input-edit {
    @include typography.hui-caption;
    color: var(--pg-brand-primary);
    float: right;

    .pgicon-edit {
      margin-right: 5px;
    }
  }

  .verified-contact-input-description {
    @include typography.hui-caption;
    color: var(--pg-grey-dark);
    margin-top: 4px;
  }

  .invalid-feedback {
    display: block;
  }

  .form-group {
    position: relative;
    margin-top: 4px;

    .verified {
      position: absolute;
      right: 10px;
      top: 13px;
      color: var(--pg-green-dark);

      &::before {
        font-size: 24px;
        margin: 0;
      }
    }

    .form-control {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &.error {
      .input-group-text {
        border-color: var(--pg-orange);
      }

      .form-control {
        background-color: var(--pg-orange-lightest);
        border-color: var(--pg-orange);
      }
    }
  }

  .verified-contact-input-error {
    @include typography.hui-caption;
    color: var(--pg-orange);
    margin-top: 4px;
  }

  .verify-btn-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-top: 15px;
  }
}

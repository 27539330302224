@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'root/styles/core/default-theme';
@import 'bootstrap/scss/mixins';

.chip-group {
  background: $fill-neutral-primary;
  gap: calc($pg-grid-space-unit / 4);

  :last-child {
    margin-right: unset !important;
  }

  .btn {
    @include typographyV3.pg-font-body-xs;
    border-radius: $pg-border-radius-full !important;

    &.btn-primary:active {
      background-color: unset;
    }

    .highlight {
      top: 0;
      left: $pg-grid-space-unit;
    }
  }

  .btn-check:checked + .btn {
    box-shadow: $pg-elevation-down-small;
    @include button-variant(
      $background: $fill-static-secondary,
      $border: $border-active-tertiary,
      $color: $text-static-primary,
      $hover-color: $text-static-primary
    );
  }

  .btn-check:not(:checked) + .btn {
    color: $pg-gray-90;
    box-shadow: unset !important;
    @include button-variant(
      $background: rgba(255, 255, 255, 0),
      $border: $border-active-primary,
      $color: $text-active-primary,
      $hover-color: $pg-gray-10
    );

    &.sticky {
      color: $pg-gray-40;
      @include button-variant(
        $background: rgba(255, 255, 255, 0),
        $border: rgba(255, 255, 255, 0),
        $color: $text-active-primary,
        $hover-color: $pg-gray-10
      );
    }
  }
}

@include media-breakpoint-down(sm) {
  .chip-group {
    .btn-check:checked + .btn {
      &:hover {
        background-color: $fill-static-secondary;
      }
    }

    .btn-check:not(:checked) + .btn {
      &:hover {
        background-color: rgba(255, 255, 255, 0);
      }
    }

    &.sticky {
      background-color: rgba(255, 255, 255, 0);
    }
  }
}

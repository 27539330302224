@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.cookie-banner-root {
  border-radius: 8px;
  background: var(--pg-purple-lightest);
  box-shadow: $pg-box-shadow-soft;

  .content {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .text-block {
    @include typography.hui-body-default-regular;
    color: var(--pg-black);
    margin: 0;

    a {
      color: var(--pg-brand-primary);
      text-decoration: none;
    }
  }

  .cookie-banner-action {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }
}

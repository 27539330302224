@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.pg-login-root {
  overflow: hidden;

  .pglogin-viewport {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .pglogin-slide {
    transform: translateX(100%);
    animation: slide-in 0.5s forwards;
    margin-bottom: 96px;
  }

  &.from-left {
    .pglogin-slide {
      transform: translateX(-100%);
    }
  }

  &:not(.from-left) {
    .login-view-root {
      .pglogin-slide {
        transform: unset;
        animation: unset;
      }
    }
  }

  .hui-alert-root {
    margin-top: 15px;
  }
}

@include media-breakpoint-up(md) {
  .pg-login-root {
    .pglogin-viewport {
      padding: unset;
    }
  }
}

@mixin underline-on-hover {
  &:before {
    border-bottom: 2px solid var(--pg-brand-primary);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: auto;
    transition: width 0.25s ease-in-out;
    width: 0;
  }

  &:hover:before {
    width: 100%;
  }
}

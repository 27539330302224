@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/default-theme.scss' as *;

$alert-icon-size: 24px;

.hui-alert-root {
  @include typography.hui-body-default-regular;
  display: flex;

  &.slide-left {
    animation: slide-left 0.5s ease-in-out;

    @keyframes slide-left {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  &.alert {
    margin-bottom: unset;
    border: none;

    .alert-title {
      color: inherit;
    }
  }

  &.alert-danger {
    background-color: var(--fill-error-secondary);
    color: var(--text-error-primary);

    .alert-icon {
      color: var(--icon-error-primary);

      &.svg-icon-wrapper {
        width: $alert-icon-size;
        height: $alert-icon-size;
        path {
          fill: var(--icon-error-primary);
        }
      }
    }
  }

  &.alert-info {
    background-color: var(--fill-neutral-tertiary);
    color: var(--text-active-primary);

    .alert-icon {
      color: var(--icon-active-primary);

      &.svg-icon-wrapper {
        width: $alert-icon-size;
        height: $alert-icon-size;
        path {
          fill: var(--icon-active-primary);
        }
      }
    }
  }

  &.alert-success {
    background-color: var(--fill-success-secondary);
    color: var(--text-success-primary);

    .alert-icon {
      color: var(--icon-success-primary);

      &.svg-icon-wrapper {
        width: $alert-icon-size;
        height: $alert-icon-size;
        path {
          fill: var(--icon-success-primary);
        }
      }
    }
  }

  &.alert-warning {
    background-color: var(--fill-warning-secondary);
    color: var(--text-warning-primary);

    .alert-icon {
      color: var(--icon-warning-primary);

      &.svg-icon-wrapper {
        width: $alert-icon-size;
        height: $alert-icon-size;

        path {
          fill: var(--icon-warning-primary);
        }
      }
    }
  }

  // @deprecated - teal variant is not supported in v3
  &.alert-teal {
    background-color: var(--pg-teal-lightest);
    border-color: var(--pg-teal-dark);
    border-radius: 4px;
    color: var(--pg-teal-dark);
  }

  .alert-icon {
    margin-right: $pg-grid-space-unit-2x;
    font-size: $alert-icon-size;

    &::before {
      margin: 0;
    }

    &.svg-icon-wrapper {
      width: $alert-icon-size;
      height: $alert-icon-size;
      path {
        fill: var(--pg-teal-dark);
      }
    }
  }

  .btn-close {
    padding: $pg-grid-space-unit-4x;

    &::before {
      top: 0;
      right: $pg-grid-space-unit;
      font-size: $alert-icon-size;
      color: var(--icon-active-primary);
    }
  }

  .alert-close-btn {
    margin-left: auto;
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    display: flex;

    button.close-btn-icon {
      margin-left: auto;
      padding: 0 0 0 $pg-grid-space-unit;
      height: fit-content;
      background: none;
      cursor: pointer;
      background-color: unset;
      border: unset;
    }
  }

  // custom alert
  &.alert-education {
    background-color: #{$fill-neutral-quaternary};
    display: flex;
    color: #{$fill-static-primary};

    .alert-heading {
      @include typography.hui-experimental-heading3;
      margin-bottom: var(--pg-grid-space-unit-2x);
      color: #{$fill-static-primary};
    }

    .alert-link,
    .alert-link:hover {
      color: #{$fill-static-primary};
      text-decoration: underline;
    }

    .alert-close-btn {
      button.close-btn-icon {
        svg path {
          fill: #{$fill-static-primary} !important;
        }
      }
    }
  }
}

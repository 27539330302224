@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.progress-group-view-root {
  &.has-borders {
    border: 1px solid var(--pg-grey-medium);
    border-radius: 8px;
    padding: 16px;
  }

  .progress-group-view {
    &-heading {
      @include typography.hui-heading4;
      margin-bottom: 16px;
    }

    &-rows {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      grid-gap: 8px 10px;
      color: var(--pg-grey-darker);

      .hui-progress-root {
        height: 5px;
        .progress {
          height: inherit;
          border-radius: 4px;

          .progress-bar {
            border-radius: inherit;
          }
        }
      }
    }

    &-row {
      &-label,
      &-value {
        @include typography.hui-body-default-regular;
        color: var(--pg-grey-darker);
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .progress-group-view-root {
    .has-borders {
      padding: 24px;
    }
  }
}

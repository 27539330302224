@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';
@import '../styles';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@include media-breakpoint-down(lg) {
  // Tablet + Mobile devices.
  .global-navbar-root {
    padding: $pg-grid-space-unit-3x;

    .top-bar {
      .side-nav-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--pg-white);
        border: none;
        width: 30px;
        height: 30px;
        color: var(--pg-grey-darker);

        .pgicon {
          font-size: $pg-line-height-body-big-mobile;
          line-height: $pg-line-height-body-big-mobile;
          margin: 0;
        }
      }

      .brand {
        .logo-desktop {
          display: none;
        }

        .logo-mobile {
          display: block;
        }
      }

      // Topbar navlist is not needed anymore.
      .nav-link-list {
        display: none;
      }

      .right-button-set {
        // Except notification button, all should be gone.
        .my-activity-link {
          display: none;
        }

        .login-button {
          display: none;
        }

        .my-account-dropdown {
          display: none;
        }

        .locale-switch-dropdown {
          display: none;
        }
      }
    }
  }

  .side-nav-offcanvas-backdrop {
    display: block;
    position: fixed;
    // 2 level down than the topbar
    z-index: calc($pg-global-navbar-z-index - 2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--pg-black);
    opacity: 0;
    transition: opacity 0.15s linear;

    &.show {
      opacity: 0.8;
    }
  }

  .side-nav-offcanvas {
    position: fixed;
    top: $pg-global-navbar-mobile-height;
    bottom: 0;
    // 1 level down than the topbar
    z-index: calc($pg-global-navbar-z-index - 1);

    display: flex;
    flex-direction: column;
    width: 100%;
    left: -100%;
    transition: left 0.3s ease-in-out;
    background-color: var(--pg-white);

    &.show {
      left: 0;
    }

    @include media-breakpoint-up(sm) {
      width: 360px;
    }

    @include dropdown-button('.locale-switch-dropdown');
    @include local-switch-dropdown-mobile-overrides('.locale-switch-dropdown');
  }

  .side-nav-top {
    @include typography.hui-body-default-regular;

    padding: $pg-grid-space-unit-4x;
    display: flex;
    flex-direction: column;

    background-color: var(--pg-brand-primary);
    color: white;

    .my-activity-link {
      display: flex;
      align-items: center;
      gap: $pg-grid-space-unit-2x;
      padding: $pg-grid-space-unit-2x 0;
      @include typography.hui-body-bold;
      color: var(--pg-global-navbar-side-nav-top-color);
    }

    .separator {
      @include hui-dropdown-item-separator;
      margin: $pg-grid-space-unit-2x 0;
      border-color: var(--pg-brand-primary-light);
    }

    .login-block {
      display: flex;
      flex-direction: column;
      gap: $pg-grid-space-unit-3x;

      .separator {
        margin: $pg-grid-space-unit-2x 0 0;
      }

      .login-block-title {
        @include typography.hui-caption;
        color: var(--pg-white);
        margin-bottom: 0;
      }

      .login-button {
        width: 100%;
        color: var(--pg-brand-primary);
        background-color: var(--pg-white);

        &:focus {
          color: var(--pg-brand-primary);
        }
      }
    }

    @include accordion('.my-account-accordion');

    .my-account-accordion {
      @include nav-link-list;

      .my-account-accordion-item {
        .my-account-accordion-header {
          margin: 0;

          .accordion-button {
            color: var(--pg-white);
            background: none;
          }
        }

        .my-account-accordion-body {
          .badge {
            margin-left: $pg-grid-space-unit-2x;
          }
        }
      }

      .nav-link-list {
        flex-direction: column;
        justify-content: flex-start;
        gap: 0;

        .nav-link-item {
          padding: $pg-grid-space-unit-3x $pg-grid-space-unit-6x;
          margin-right: auto;
          .nav-link-item-text {
            color: var(--pg-white);
          }
        }
      }
    }

    .logout-button {
      padding: $pg-grid-space-unit-2x 0;
    }
  }

  .side-nav-top.pg-finance-variant {
    background-color: var(--pg-grey-light);

    .separator {
      border-color: var(--pg-white);
    }

    .login-block .login-button {
      @include typography.hui-body-default-bold;
      color: var(--pg-grey-light);
      border-color: transparent;
      border-radius: $pg-grid-space-unit-2x;
      padding: 6px $pg-grid-space-unit-5x;
    }
  }

  .side-nav-link-list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: calc(100% - 158px);

    .nav-link-list {
      display: flex;
      flex-direction: column;
      padding: $pg-grid-space-unit $pg-grid-space-unit-4x;
      flex: 1 1 0;
    }

    .nav-link-item {
      @include hui-link-item;
      @include typography.hui-body-default-regular;
      padding: $pg-grid-space-unit-3x 0;
      gap: $pg-grid-space-unit-4x;
      position: relative;

      &-svg-icon {
        width: 1em;
        margin-right: 0.2em;
        margin-left: 0.2em;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--pg-grey);
      }

      .badge.bg-success {
        background-color: #07a35d !important; // variable not in available, override bootstrap
      }
    }

    .pgicon-link-ext {
      position: absolute;
      right: 0;
      top: auto;
      bottom: auto;
    }

    .pgicon-link-ext:before {
      width: auto;
      height: auto;
      margin: 0;
    }

    .nav-link-item:not(.agent-link-item) {
      &:not(:last-child) {
        border-bottom: 1px solid var(--pg-grey);
      }
    }

    .agent-link-list {
      background-color: var(--pg-grey);
      padding: $pg-grid-space-unit-3x $pg-grid-space-unit-4x;

      .agent-link-list-title {
        @include typography.hui-heading5;
        color: var(--pg-brand-primary);
        margin-bottom: $pg-grid-space-unit;
      }
    }
  }

  .side-nav-link-list.pg-finance-variant {
    .nav-link-list {
      padding: 0;
      .group-link-list {
        &:not(:last-child) {
          border-bottom: 1px solid var(--pg-grey);
        }
      }
      .group-link-list-title {
        @include typography.hui-heading5;
        color: var(--pg-grey-light);
        padding: $pg-grid-space-unit-4x $pg-grid-space-unit-4x $pg-grid-space-unit;
        margin: 0;
      }
    }

    .nav-link-item {
      padding: $pg-grid-space-unit-4x;
      border-bottom: none;
    }

    .agent-link-list {
      .agent-link-list-title {
        color: var(--pg-grey-light);
      }

      .agent-link-item {
        padding: $pg-grid-space-unit-3x 0;
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;

@import 'root/styles/core/default-theme';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.hui-input-root {
  .form-label.hui-input-title,
  .form-label.hui-input-helptext {
    @include typography.hui-heading-label;
    color: var(--text-active-primary);
  }

  .form-label.hui-input-helptext {
    font-weight: 400;
    color: var(--text-active-secondary);
  }

  .hui-input {
    border: 1px solid var(--border-active-primary);
    border-radius: var(--pg-border-radius-full);
    background-color: var(--fill-neutral-secondary);
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    gap: $pg-grid-space-unit;

    &.input-group {
      flex-wrap: unset;
    }

    .form-control {
      @include typographyV3.pg-font-body-xs;
      border: none;
      margin: 0;
      color: var(--text-active-secondary);
      min-width: 200px;
      background-color: transparent;
      border-radius: unset;
      padding: $pg-grid-space-unit-3x $pg-grid-space-unit-4x;
      &:focus-within {
        caret-color: var(--border-focus-primary);
      }

      @include media-breakpoint-up(sm) {
        min-width: 296px;
      }
    }

    textarea.form-control {
      resize: none;
      border-radius: 0;
    }

    &:has(textarea) {
      border-radius: var(--pg-border-radius-medium);
    }

    &:has(.form-control:focus-within) {
      border-color: var(--border-focus-teritary);
    }

    &:hover {
      background-color: var(--fill-hover-primary);
    }

    &:has(.form-control:focus-within):hover {
      background-color: transparent;
    }

    &:has(.form-control:disabled) {
      border-color: var(--border-inactive-primary);
      background-color: var(--fill-inactive-primary);

      .form-control {
        background-color: var(--fill-inactive-primary);
      }

      svg path,
      i {
        fill: var(--icon-inactive-primary) !important;
        color: var(--icon-inactive-primary);
      }
    }

    &.error {
      border-color: var(--border-error-primary);
      background-color: var(--fill-error-secondary);
      color: var(--text-error-primary);

      .form-control {
        background-color: var(--fill-error-secondary);
      }

      &:has(.form-control:focus-within) {
        border-color: var(--border-error-primary);
      }
    }

    .hui-input-prefix,
    .hui-input-suffix {
      border-radius: var(--pg-border-radius-full);
      background-color: transparent;
      border: none;
      transition: border-color 0.15s ease-in-out;
      margin: 0;
    }

    &.has-suggestions {
      height: 48px;
      cursor: pointer;

      .hover-events {
        cursor: pointer;
      }

      .hui-input-suffix {
        display: flex;
        padding: 0;

        .pgicon-expand:before {
          margin: 0;
          font-size: 24px;
        }

        .pgicon-expand.is-focused {
          transform: rotateZ(180deg);
          transition: 100ms linear;
        }
      }
    }
    &__clear-button {
      cursor: pointer;
      min-width: 24px;
      &:last-child {
        margin-right: $pg-grid-space-unit-3x;
      }
    }
  }

  .hui-input-suggestions {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: $pg-border-radius-large;
    border: 1px solid var(--border-active-primary);
    padding: 0;
    margin-top: $pg-grid-space-unit-2x;
    box-shadow: 0 4px 8px 0 #282c2e1f;
    z-index: -1;

    .hui-dropdown-inner {
      width: 100%;
      margin-top: 0;
      height: 200px;
      position: relative;
      overflow-y: auto;
      padding: $pg-grid-space-unit-4x 0;
      border-radius: unset;
      border: unset;
    }

    &.dropdown-menu {
      .dropdown-item {
        @include typography.hui-body-default-regular;
        padding: $pg-grid-space-unit-3x $pg-grid-space-unit-4x;
        margin: 0;

        .hui-radio-root {
          .form-check {
            padding-left: unset;
          }
        }

        &:hover {
          background-color: var(--pg-grey);
        }
      }
    }
  }

  .hui-input-caption {
    display: flex;
    align-items: flex-start;
    gap: $pg-grid-space-unit;

    .hui-input-helptext {
      float: left;
      color: $pg-grey-dark;
      margin-bottom: 0;
    }

    .hui-input-character-count {
      margin-left: auto;
      color: $pg-grey-dark;
      margin-bottom: 0;
    }
  }
}

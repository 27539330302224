@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/animations-mixins.scss' as animations;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.agent-card-root {
  display: grid;
  border-radius: $pg-border-radius-large;
  box-shadow: $pg-box-shadow-subtle;
  position: relative;
  overflow: hidden;
  background-color: var(--pg-white);
  --agent-card-placeholder-height: 248px;
  --agent-avatar-aspect-ratio: 4/3;
  --overlay-black-60: rgba(44, 44, 44, 0.6);
  --agent-card-border-radius: 100px;

  &.show-placeholder-avatar {
    .agent-card {
      &-left-section {
        .agent-avatar {
          aspect-ratio: var(--agent-avatar-aspect-ratio);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--pg-white);
        }
      }
    }
  }

  &.is-loading {
    p {
      @include animations.hui-animated-loading;
      margin-bottom: $pg-grid-space-unit-2x;
    }

    .agent-card {
      &-left-section {
        min-height: var(--agent-card-placeholder-height);
        @include animations.hui-animated-loading;
      }

      &-loading-text {
        &-1 {
          border-radius: var(--agent-card-border-radius);
          width: min(140px, 100%);
          height: $pg-grid-space-unit-5x;
        }

        &-2 {
          border-radius: var(--agent-card-border-radius);
          width: min(140px, 100%);
          height: $pg-grid-space-unit-5x;
        }

        &-4 {
          border-radius: var(--agent-card-border-radius);
          width: min(220px, 100%);
          height: $pg-grid-space-unit-5x;
          margin-bottom: $pg-grid-space-unit;
        }

        &-5 {
          border-radius: var(--agent-card-border-radius);
          width: min(180px, 100%);
          height: $pg-grid-space-unit-5x;
          margin-bottom: $pg-grid-space-unit;
        }
      }

      &-loading-btn-1 {
        border-radius: var(--agent-card-border-radius);
        width: 100%;
        height: 40px;
        margin: 0;
      }
    }

    .agent-meta-info-fields {
      & > div {
        flex-basis: min(140px, 50%);

        & > .agent-card-loading-text-2 {
          margin-bottom: $pg-grid-space-unit;
          width: min(110px, 100%);
        }
      }
    }
  }

  .agent-card {
    // works as link for the card
    &-overlay-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: $pg-z-index-1;
    }

    &-left-section {
      margin-bottom: 0;
      position: relative;
      aspect-ratio: var(--agent-avatar-aspect-ratio);
      overflow: hidden;

      &-content {
        display: flex;
        position: relative;
        flex-direction: column;
        height: 100%;
      }

      .badge-root.badge-small {
        @include typography.hui-caption;
        text-align: center;
        text-transform: capitalize;
        position: absolute;
        margin: 0;
        border-radius: $pg-border-radius-small;
        background-color: var(--fill-warning-primary);
        color: $text-active-tertiary;
        left: $pg-grid-space-unit-3x;
        top: $pg-grid-space-unit-3x;
        padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
        z-index: $pg-z-index-1;
      }

      .agent-blur-image {
        position: absolute;
        filter: blur(100px);
        background-repeat: no-repeat;
        background-size: cover;
        aspect-ratio: var(--agent-avatar-aspect-ratio);
        background-position: center;
        width: 100%;
      }

      .agent-avatar {
        background: linear-gradient(var(--fill-static-primary) var(--fill-static-primary)), 17%;
        flex-grow: 1;
        display: flex;
        align-items: center;
        margin: 0;
        max-height: calc(100% - 60px);

        img {
          object-fit: cover;
          object-position: top;
          position: relative;
          border-radius: 50%;
          width: auto;
          height: 140px;
          aspect-ratio: 1/1;
          margin: $pg-grid-space-unit-3x auto;
          border: 1px solid var(--fill-neutral-primary);
          box-shadow: (0 2px 4px 0 rgba(40, 44, 46, 0.08));
        }

        .pgicon-agent {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 140px;
          border-radius: var(--pg-border-radius-full, 100px);
          border: 1px solid var(--pg-fill-neutral-primary);
          box-shadow: 0 2px 4px 0 rgba(40, 44, 46, 0.08);
          width: 140px;
          font-size: 45px;
          line-height: 45px;
          color: var(--pg-grey-medium);

          &::before {
            margin: 0;
          }
        }
      }

      .agent-avatar-overlay {
        display: flex;
        align-items: center;
        width: 100%;
        height: auto;
        position: relative;
        padding: $pg-grid-space-unit-3x;
        background: $fill-inactive-primary;

        .agency-info-wrap {
          display: flex;
          align-items: center;
          gap: $pg-grid-space-unit-3x;
          width: calc(100% - 2 * var(--agent-avatar-padding));
          height: $pg-grid-space-unit-9x;
          overflow: hidden;

          .agency-logo {
            margin-bottom: 0;
            border: 1px solid var(--pg-grey);
            height: 30px;
            background-color: var(--pg-white);

            &.show-placeholder-logo {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 $pg-grid-space-unit-3x;
              border-radius: 4px;
            }

            img {
              height: 100%;
            }

            .pgicon-agent {
              font-size: 16px;
              line-height: 16px;
              color: var(--pg-grey-medium);

              &::before {
                margin: 0;
              }
            }
          }

          .agency-info {
            display: flex;
            flex-direction: column;
            overflow: hidden;
          }

          .job-title {
            @include typography.hui-caption;
            color: var(--text-active-secondary);
            text-transform: capitalize;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .agency-name {
            @include typography.hui-caption;
            color: var(--text-active-secondary);
            text-transform: uppercase;
            margin-bottom: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }

    &-right-section {
      padding: $pg-grid-space-unit-3x $pg-grid-space-unit-4x;

      .agent-card-info {
        display: flex;
        gap: $pg-grid-space-unit-2x;
        align-items: center;

        .agent-name {
          @include typography.hui-heading4;
          color: var(--text-active-primary);
          margin-bottom: 0;
          text-overflow: ellipsis;
        }

        .agent-rating-root {
          // to give this link more weightage than the link for the card
          z-index: $pg-z-index-2;
          position: relative;
          display: inline-flex;
          gap: 0;
          align-items: center;
        }
      }

      .divider-root {
        height: 32px;

        .line {
          background-color: var(--border-active-secondary);
        }
      }

      .agent-meta-info-heading {
        @include typography.hui-body-default-regular;
        margin-bottom: $pg-grid-space-unit-2x;
        color: var(--text-neutral-primary);
        // show ellipsis when text overflows 2 lines
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .agent-meta-info-fields {
        display: flex;
        flex-direction: column;
        gap: $pg-grid-space-unit-2x;

        .vertical-divider {
          height: 22px;
          border-left: 1px solid var(--border-active-secondary);
          margin: 0 $pg-grid-space-unit-2x;
        }
      }

      .agent-view-profile-btn {
        @include typography.hui-label;
        color: var(--text-active-primary);
        text-align: center;
        border-radius: var(--agent-card-border-radius);
        border: 1px solid var(--border-active-tertiary);
        background: var(--pg-white);
        box-shadow: (0 2px 4px 0 rgba(40, 44, 46, 0.08));
        padding: $pg-grid-space-unit-2x $pg-grid-space-unit-4x;
        // to give this link more weightage than the link for the card
        z-index: $pg-z-index-2;
        position: relative;
        width: 100%;

        &:hover {
          border-color: var(--pg-grey-dark);
          background-color: var(--pg-grey-dark);
          color: var(--pg-white);
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .agent-card-root:not(.mobile-view) {
    grid-template-columns: minmax(290px, 326px) auto;

    &.is-loading {
      .agent-card {
        &-loading-pill {
          height: 29px;
        }

        &-loading-text {
          &-1 {
            height: 32px;
          }
        }
        &-left-section {
          border-top-right-radius: unset;
        }
      }
    }

    .agent-card-right-section {
      position: relative;

      .agent-view-profile-btn {
        position: absolute;
        bottom: $pg-grid-space-unit-3x;
        right: $pg-grid-space-unit-3x;
        width: unset;
      }
    }
  }
}

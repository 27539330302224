@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';
@import '../styles';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.global-footer-root {
  background: var(--pg-black);
  color: var(--pg-white);
  a,
  a:hover,
  a:active {
    color: var(--pg-white);
    text-decoration: none;
    display: flex;
  }

  .divider {
    border-bottom: 1px solid var(--pg-white);
  }
}

@include container('.footer-container');

// It has to be `only`
@include media-breakpoint-only(xs) {
  .global-footer-root {
    .footer-container {
      max-width: unset;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'root/styles/core/default-theme';

.new-home-alert-card-group-root {
  transform: height 0.3s ease-in;

  &.rendered {
    height: 154px;
  }

  .card-group-title {
    @include typographyV3.pg-font-label-l;
    margin-bottom: $pg-grid-space-unit-2x;
  }

  .card-group-body {
    display: flex;
    gap: $pg-grid-space-unit-4x;
    overflow-x: auto;
    // this is to ensure the shadow is visible
    padding: $pg-grid-space-unit-2x $pg-grid-space-unit-4x $pg-grid-space-unit-4x $pg-grid-space-unit-4x;
    // this is to make it aligned to title
    margin: calc($pg-grid-space-unit-2x * -1) calc($pg-grid-space-unit-4x * -1) 0 calc($pg-grid-space-unit-4x * -1);
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.hui-hero-banner-root {
  position: relative;
  text-align: center;

  &:not(.background-image-variant) {
    background-color: var(--pg-brand-primary-high);

    .hui-hero-banner {
      &-heading,
      &-sub-heading {
        color: var(--pg-white);
      }

      &-heading {
        margin-top: var(--pg-grid-space-unit-10x);
      }
    }
  }

  .hui-hero-banner {
    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding-top: $pg-grid-space-unit-4x;
    }

    &-heading {
      @include typography.hui-heading1;
      margin-bottom: $pg-grid-space-unit-2x;
    }

    &-sub-heading {
      @include typography.hui-heading4;
      margin-bottom: $pg-grid-space-unit-4x;
    }

    &-cta {
      display: block;
    }
  }
}

@include media-breakpoint-up(sm) {
  .hui-hero-banner-root {
    .hui-hero-banner {
      &-overlay {
        padding-top: $pg-grid-space-unit-6x;
      }

      &-cta {
        display: inline-block;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .hui-hero-banner-root {
    text-align: start;

    &:not(.background-image-variant) {
      height: 270px;

      .hui-hero-banner {
        &-heading {
          margin-top: 0;
        }
      }
    }

    .hui-hero-banner {
      &-overlay {
        padding-top: $pg-grid-space-unit-12x;
      }
    }
  }
}

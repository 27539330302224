@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.location-filter-modal-footer-root {
  &.modal-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    padding-bottom: 0;

    .actionable {
      margin-top: 23px;
      margin-bottom: 24px;
    }
  }
}

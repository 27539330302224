@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.home-alert-card-root {
  text-decoration: none;
  width: calc((100% - 30px) / 3);
  min-width: 198px;

  .home-alert-card {
    &:hover {
      background: var(--pg-grey-lighter);
    }

    &:active {
      background: var(--pg-grey-darker);
      .property-area,
      .info {
        color: var(--pg-white);
      }
    }

    padding: $pg-grid-space-unit-3x;
    gap: $pg-grid-space-unit-3x;
    background: var(--pg-white);
    box-shadow: $pg-box-shadow-soft;
    border-radius: $pg-border-radius-medium;
    border: 1px;

    .card-title {
      @include typographyV3.pg-font-caption-xs;
      background: var(--pg-green);
      border-radius: $pg-border-radius-small;
      text-transform: uppercase;
      color: var(--pg-white);
      align-self: flex-start;
      padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
      margin-bottom: 0;
    }

    .property-area {
      @include typographyV3.pg-font-label-m;
      color: var(--pg-black);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: $pg-grid-space-unit-3x;

      .pgicon::before {
        margin-left: 0;
        height: $pg-grid-space-unit-4x;
        width: $pg-grid-space-unit-4x;
      }
    }

    .property-details {
      .property-info {
        color: var(--pg-grey-dark);
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: $pg-grid-space-unit;
      }

      .pgicon::before {
        margin-left: 0;
        height: $pg-grid-space-unit-4x;
        width: $pg-grid-space-unit-4x;
      }

      .info {
        span {
          @include typographyV3.pg-font-body-xs;
        }

        .info-item {
          display: inline-flex;
          gap: $pg-grid-space-unit;
        }
      }

      .info + .info {
        &::before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 1px;
          height: 20px;
          margin: 0 5px;
          background-color: $border-active-secondary;
        }

        > span:not(:first-child) {
          margin-left: 5px;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .home-alert-card-root {
    min-width: 250px;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;

.social-share-header-root {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding-bottom: 24px;

  .social-share-header--back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 24px;
    width: 24px;
    font-size: 24px;
    cursor: pointer;
  }

  .social-share-header--title {
    @include typography.hui-heading3;
    margin: 0;
    align-items: center;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';

.agent-metadata-root {
  @include typography.hui-caption;
  display: flex;
  align-items: center;
  gap: $pg-grid-space-unit-2x;
  color: var(--pg-grey-dark);
}

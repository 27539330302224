@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.shortlist-popover-root {
  &.popover {
    padding: 20px 15px 20px 15px;
    .popover-arrow {
      display: none;
    }
  }

  .sharing-popover-title {
    @include typography.hui-heading4;
  }

  .sharing-popover-actions {
    .shortlist-share-view,
    .shortlist-share-edit {
      background-color: unset;
      border-color: unset;
      color: unset;
      border: unset;
      display: contents;
      padding: 0;
    }

    .sharing-popover-action-tile {
      .sharing-popover-action-heading {
        @include typography.hui-body-default-bold;
        display: flex;
      }
      .sharing-popover-action-description {
        @include typography.hui-caption;
        display: flex;
      }
      margin-top: 15px;
      padding: 15px;
      background: var(--pg-white);
      border: 1px solid var(--pg-grey);
      border-radius: 5px;
      display: block;
    }
  }
}

.shortlist-share-button {
  &.btn {
    padding: 0;
  }

  &:hover {
    .shortlist-sharing {
      .shortlist-sharing-root {
        background-color: (--pg-grey-medium);
      }

      .shortlist-sharing-root-plus {
        background-color: var(--pg-grey);
      }
    }
  }

  &:focus,
  &:active {
    .shortlist-sharing {
      .shortlist-sharing-root {
        background-color: var(--pg-grey-darker);
      }

      .shortlist-sharing-root-plus {
        background-color: var(--pg-grey);
        border-color: var(--pg-grey-darker);
      }
    }
  }

  .shortlist-sharing {
    position: relative;
    display: inline-block;

    .shortlist-sharing-root {
      position: absolute;
      left: 0;
      top: 0;
      z-index: $pg-z-index-neg-1;

      background-color: var(--pg-grey);
      border-radius: 18px;
      height: 36px;
      width: 36px;
      line-height: 36px;
      vertical-align: middle;
      text-align: center;

      .shortlist-sharing-icon {
        color: var(--pg-white);
      }
    }

    .shortlist-sharing-root-plus {
      margin-left: 22px;
      background-color: var(--pg-white);
      border: 1px solid var(--pg-grey-medium);
      border-radius: 18px;
      height: 36px;
      width: 36px;
      line-height: 36px;
      vertical-align: middle;
      text-align: center;
    }
  }
}

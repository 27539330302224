@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.location-filter-modal-header-root {
  &.modal-header {
    display: flex;
    border-bottom: 1px solid var(--pg-grey);
    padding: 0 24px 0 24px;
    height: 76px;
    align-items: center;

    .secondary {
      button {
        padding: 0;
        color: var(--pg-grey-darker);
        display: flex;
        position: relative;
        .header-icon {
          position: absolute;
          top: 30%;
          i {
            margin-left: 0;
          }
        }
        .header-text,
        .header-subtext {
          color: var(--text-active-tertiary);
        }

        .header-text {
          display: inline-grid;
          padding-left: 32px;

          .header-subtext {
            font-size: 12px;
            margin-bottom: 0;
            display: flex;
          }
        }
      }
    }

    .reset-button {
      color: var(--text-active-tertiary);
    }

    .wrapper {
      display: flex;
      align-items: center;
    }
    .modal-title,
    .secondary-button {
      span {
        @include typography.hui-body-default-regular;
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.hsv-root {
  .divider-root {
    height: 1px;
  }
  .modal-dialog {
    .modal-content {
      .modal-body {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        width: 100%;

        .hui-progress-root {
          margin-bottom: $pg-grid-space-unit-5x;
        }

        .slide-from {
          &-left {
            transform: translateX(100%);
            animation: slide-in 0.5s forwards;
          }
          &-right {
            transform: translateX(-100%);
            animation: slide-in 0.5s forwards;
          }
        }

        .hsv-body {
          padding-bottom: 20px;

          .hsv-address-root {
            .pg-login-masthead-title {
              margin-top: $pg-grid-space-unit-4x;
            }

            .pg-login-masthead-description {
              margin-top: $pg-grid-space-unit;
            }
          }
        }

        .hsv-footer {
          &-root {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: flex-end;
          }
          &-desc {
            @include typography.hui-caption;
            color: var(--pg-grey-darker);
            padding: 12px;
          }
        }
      }
    }
  }

  .hsv-form {
    gap: $pg-grid-space-unit-4x;
    margin-top: $pg-grid-space-unit-4x;

    .hsv-label {
      @include typography.hui-caption;

      small {
        color: var(--pg-grey-dark);
        font-style: italic;
      }
    }
    .hsv-intent-root {
      .badge {
        @include typography.hui-body-default-regular;
        background-color: var(--pg-white);
        color: var(--pg-black);
        border: 1px solid var(--pg-grey-medium);

        &.selected {
          background-color: var(--pg-black);
          color: var(--pg-white);
          border: 1px solid var(--pg-black);
        }
      }
    }

    .input-group {
      .form-control {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .hsv-verified-icon {
      position: absolute;
      right: 8px;
      top: calc(50% - 10px);
      z-index: $pg-z-index-6;
      font-size: 20px;
      width: 20;
      height: 20;
      color: var(--pg-green-dark);
      &::before {
        margin: 0;
      }
    }
  }

  .resend-otp-btn {
    margin-top: 15px;
  }
}

@include media-breakpoint-up(sm) {
  .hsv-root {
    .modal-dialog {
      max-height: 618px;
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'root/styles/core/default-theme';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.contact-agent-modal-root {
  .modal-dialog {
    width: 100%;
    max-width: 450px;
    margin: auto;
    height: auto;
  }

  .modal-title {
    @include typography.hui-label-l-normal;
    color: var(--pg-white);
  }

  .contact-agent-card-v3-root {
    border: none;

    .agent-info-root {
      padding: 0 $pg-grid-space-unit-4x $pg-grid-space-unit-4x;
    }

    .terms-and-policy {
      padding-bottom: $pg-grid-space-unit-6x;
    }

    .card-body {
      border: 0;
      padding: $pg-grid-space-unit-4x 0 0;
    }
  }
}

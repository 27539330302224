@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/core/typography-mixins-v3.scss' as typographyV3;
@import 'root/styles/core/bootstrap';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.agent-badge-root {
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background-color: var(--fill-success-primary);
  padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
  width: min-content;
  border-radius: $badge-border-radius;

  &.no-text {
    padding: $pg-grid-space-unit;
    border-radius: 50%;
  }

  .hui-image-root {
    width: 21px;
    height: 21px;
    z-index: 1;
  }

  .badge-text {
    @include typographyV3.pg-font-caption-xs;
    color: var(--pg-white);
    padding-left: $pg-grid-space-unit;
    white-space: nowrap;
  }
}

.agent-info-popover {
  .popover-header {
    @include typography.hui-heading5;
    padding: 12px 16px;
    background-color: var(--pg-grey-lighter);
  }

  .popover-body {
    @include typography.hui-body-default-regular;
    padding: 16px;

    .btn {
      margin-top: 8px;
    }
  }
}

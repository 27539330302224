@use 'root/styles/core/typography-mixins.scss' as typography;
@use 'root/styles/foundation/common/navbar.scss' as navbar;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.hui-ticker-text-root {
  @include typography.hui-body-default-regular;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  overflow: hidden;
  color: var(--pg-grey-dark);
  z-index: $pg-z-index-1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &__container {
    overflow: hidden;
    height: 22px;
    padding: 0 $pg-grid-space-unit-4x;

    &__text {
      display: inline;
      float: left;
      margin: 0;
      padding: 0;
    }

    &__list {
      overflow-y: hidden;
      padding: 0;
      margin-left: $pg-grid-space-unit;
      padding-left: $pg-grid-space-unit;
      margin-top: 0;
      text-align: left;
      list-style: none;

      -webkit-animation-name: change;
      -webkit-animation-duration: 22s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 22s;
      animation-iteration-count: infinite;

      &__item {
        padding: 0;
        margin: 0;
      }
    }
  }
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {
  0%,
  8.5%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%,
  21% {
    transform: translate3d(0, -20%, 0);
  }
  25%,
  33.5% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%,
  46% {
    transform: translate3d(0, -60%, 0);
  }
  50%,
  58.5% {
    transform: translate3d(0, -80%, 0);
  }
  62.5%,
  71% {
    transform: translate3d(0, -60%, 0);
  }
  75%,
  83.5% {
    transform: translate3d(0, -40%, 0);
  }
  87.5%,
  96% {
    transform: translate3d(0, -20%, 0);
  }
}

@-o-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes change {
  0%,
  8.5%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%,
  21% {
    transform: translate3d(0, -20%, 0);
  }
  25%,
  33.5% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%,
  46% {
    transform: translate3d(0, -60%, 0);
  }
  50%,
  58.5% {
    transform: translate3d(0, -80%, 0);
  }
  62.5%,
  71% {
    transform: translate3d(0, -60%, 0);
  }
  75%,
  83.5% {
    transform: translate3d(0, -40%, 0);
  }
  87.5%,
  96% {
    transform: translate3d(0, -20%, 0);
  }
}

@-moz-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {
  0%,
  8.5%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%,
  21% {
    transform: translate3d(0, -20%, 0);
  }
  25%,
  33.5% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%,
  46% {
    transform: translate3d(0, -60%, 0);
  }
  50%,
  58.5% {
    transform: translate3d(0, -80%, 0);
  }
  62.5%,
  71% {
    transform: translate3d(0, -60%, 0);
  }
  75%,
  83.5% {
    transform: translate3d(0, -40%, 0);
  }
  87.5%,
  96% {
    transform: translate3d(0, -20%, 0);
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%,
  8.5%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  12.5%,
  21% {
    transform: translate3d(0, -20%, 0);
  }
  25%,
  33.5% {
    transform: translate3d(0, -40%, 0);
  }
  37.5%,
  46% {
    transform: translate3d(0, -60%, 0);
  }
  50%,
  58.5% {
    transform: translate3d(0, -80%, 0);
  }
  62.5%,
  71% {
    transform: translate3d(0, -60%, 0);
  }
  75%,
  83.5% {
    transform: translate3d(0, -40%, 0);
  }
  87.5%,
  96% {
    transform: translate3d(0, -20%, 0);
  }
}

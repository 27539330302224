@import 'root/styles/core/default-theme';

.drill-down-menu {
  min-width: 325px;
  background-color: var(--pg-white);
  color: var(--pg-black);
  box-shadow: $pg-box-shadow-soft;
  border-radius: $pg-grid-space-unit-2x;
  transition: all 0.2s linear;
  overflow: hidden;

  &--header {
    padding: $pg-grid-space-unit-5x $pg-grid-space-unit-6x;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-family: $pg-font-heading;

    &-noback {
      margin-left: 0;
    }

    &-title {
      margin-left: 0;
      flex: 1;
      transition: all 0.2s linear;
    }

    .pgicon::before {
      margin: 0;
    }
  }

  &--error {
    padding: 0 $pg-grid-space-unit-6x;

    &-container {
      padding-bottom: $pg-grid-space-unit-4x;
    }

    &-content {
      padding: $pg-grid-space-unit-4x;
      display: flex;
      gap: $pg-grid-space-unit-2x;
      align-items: center;
      background-color: var(--pg-gold-lightest);
    }

    &-alert {
      font-size: var(--pg-font-size-heading-2);
      color: var(--pg-gold-dark);

      &::before {
        margin-left: unset !important;
        margin-right: unset !important;
      }
    }

    &-text {
      color: var(--pg-gold-dark);
      flex: 1;
      margin-bottom: unset;
    }
  }

  &--back,
  &--close {
    font-size: var(--pg-font-size-heading-2);
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &--back + &--header-title {
    margin-left: $pg-grid-space-unit-2x;
  }

  &--header-title + &--close {
    margin-left: $pg-grid-space-unit-2x;
  }

  &--content {
    padding-top: $pg-grid-space-unit-4x;
    min-height: 80px;
    font-family: $pg-font-heading;
    font-weight: $pg-font-weight-body-bold;
    font-size: var(--pg-font-size-heading-5);
  }

  &--item-container {
    display: flex;
    flex-direction: column;
  }

  &--item {
    padding: 0 $pg-grid-space-unit-6x;

    &:last-child {
      margin-bottom: $pg-grid-space-unit-6x;
    }

    &:hover {
      & .drill-down-menu--item-status-ok--hover {
        display: inline;
      }
    }

    &:hover:not(&-focus):not(&-selected) {
      background-color: var(--pg-grey-lighter);
      cursor: pointer;

      & .drill-down-menu--item-content {
        border-bottom: 1px solid var(--pg-grey-lighter);
      }
    }

    &-status-ok {
      display: none;
    }

    &-status-ok--visble {
      display: inline;
    }

    &-focus,
    &-selected {
      color: var(--pg-white);
      background-color: var(--pg-black);

      & .drill-down-menu--item-content {
        border-bottom: 1px solid var(--pg-black);
      }
    }

    &-content {
      padding: $pg-grid-space-unit-3x 0;
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--pg-grey);
    }

    &-title {
      flex: 1;
    }

    &-status {
      height: 24px;
      font-size: var(--pg-font-size-heading-2);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-loader {
      font-size: 16px;
      margin-right: $pg-grid-space-unit;
    }
  }
}

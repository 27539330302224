@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.agent-profile-info-root {
  width: 100%;

  .agent-profile-avatar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .avatar-figure {
      width: 120px;
      height: 120px;

      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 1px solid var(--pg-grey);
        background: var(--pg-white);
      }

      .agent-verification-badge {
        bottom: $pg-grid-space-unit-6x;
      }
    }
  }

  .agent-profile-description-container {
    flex-grow: 1;
    margin-top: $pg-grid-space-unit-2x;
    padding: 0;

    .agent-profile-name {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .name {
        @include typography.hui-heading1;
        margin-bottom: 0;
        flex: 1;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
      }
    }

    .agent-profile-headline {
      .headline {
        @include typography.hui-heading4;
      }
    }

    .agent-profile-position {
      display: flex;
      margin-top: $pg-grid-space-unit-2x;

      .agency-logo-figure {
        display: flex;
        align-items: center;
        max-width: 60px;
        margin: 0;
        margin-right: $pg-grid-space-unit-4x;

        .agency-logo {
          max-height: 44px;
          width: 100%;
        }
      }

      .agent-profile-designation > div {
        @include typography.hui-heading5;
      }
    }

    .agent-profile-specification {
      margin-top: $pg-grid-space-unit-3x;

      .trait {
        @include typography.hui-body-default-regular;
        color: var(--pg-grey-darker);

        a {
          font-weight: 500;
          text-decoration: none;
          color: var(--pg-brand-primary);
        }
      }

      .trait:not(:last-child) {
        &::after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          margin: 0 $pg-grid-space-unit-2x;
          background-color: var(--pg-grey-medium);
        }
      }
    }

    .agent-profile-social-links {
      display: flex;
      margin-top: $pg-grid-space-unit-6x;

      .sharing-action-btn {
        display: flex;
        height: 40px;
        padding: $pg-grid-space-unit-3x;
        margin-right: $pg-grid-space-unit-4x;
        box-shadow: $pg-box-shadow-soft;

        &:hover {
          background: var(--fill-hover-primary);
        }
      }

      .vertical-divider {
        border-left: 1px solid var(--pg-grey-medium);
        margin-right: $pg-grid-space-unit-4x;
      }

      .share-button {
        background-color: var(--pg-white);
        border: var(--bs-btn-border-width) solid var(--border-active-tertiary);
        padding: $pg-grid-space-unit-3x;
      }

      i {
        display: flex;
        color: var(--pg-black);
        height: $pg-grid-space-unit-4x;
        width: $pg-grid-space-unit-4x;
        justify-content: center;
        margin: 0;
      }

      .actionable-link,
      .actionable {
        background-color: var(--pg-white);
        box-shadow: $pg-box-shadow-subtle;
        border-radius: 50%;

        &:hover {
          border-radius: 50%;
        }
      }
    }

    .agent-profile-rating {
      margin-top: $pg-grid-space-unit-6x;

      .agent-rating-root {
        margin-right: $pg-grid-space-unit-2x;
        margin-bottom: $pg-grid-space-unit-2x;
        display: inline-flex;
        gap: 0;
        align-items: center;
      }

      .specialties {
        @include typography.hui-heading5;
        padding: $pg-grid-space-unit $pg-grid-space-unit-3x;
        border-radius: 16px;
        background: var(--pg-grey);
        color: var(--pg-black);
        text-align: center;
        margin-right: $pg-grid-space-unit-2x;
      }

      .specialties:last-child {
        margin-right: 0;
      }
    }
  }

  .rate-agent-action-btn {
    text-align: center;
  }

  @include media-breakpoint-up(sm) {
    display: block;

    .agent-profile-avatar {
      float: left;
      flex-direction: column;
      margin-right: $pg-grid-space-unit-6x;
      min-height: 150px;
    }

    .agent-profile-description-container {
      margin-top: 0;

      .agent-profile-name {
        .name {
          @include typography.hui-heading1;
          margin-top: 0;
          -webkit-line-clamp: 2;
        }
      }

      .agent-profile-actions {
        text-align: right;

        .rate-agent-action-btn {
          margin-right: $pg-grid-space-unit-2x;
        }
      }

      .agent-profile-position {
        margin-top: $pg-grid-space-unit-4x;

        .figure {
          margin: 0;
        }
      }

      .agent-profile-specification {
        display: flex;
        flex-wrap: wrap;
        margin-top: $pg-grid-space-unit-2x;
      }

      .agent-profile-social-links {
        margin-top: $pg-grid-space-unit-2x;
        padding: $pg-grid-space-unit-2x 0;
      }

      .agent-profile-rating {
        margin-top: $pg-grid-space-unit-2x;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;

    .agent-profile-avatar {
      figure {
        width: 140px;
        height: 140px;
      }
    }

    .agent-profile-description-container {
      .agent-profile-name {
        .name {
          @include typography.hui-heading1;
        }
      }
    }
  }
}

.popover {
  .popover-body {
    .agent-badge-btn {
      margin-top: $pg-grid-space-unit-2x;
    }
  }
}

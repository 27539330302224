@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.bedroom-filter-root {
  @include typography.hui-body-default-bold;

  .filter-root {
    .dropdown-menu {
      min-width: 344px;
    }

    .dropdown-toggle:active {
      color: var(--pg-white);
    }

    .bedroom-select-body {
      width: auto;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 0;

      .bedroom-item {
        padding-right: 5px;
      }
    }

    .bedroom-type {
      color: var(--pg-grey-darker);
      border: 1px solid var(--pg-grey-darker);
      border-radius: 8px;
      padding: 7px 20px;
      margin-top: 8px;
      background-color: var(--pg-white);

      &:hover {
        background-color: var(--pg-grey);
      }

      &.checked {
        background-color: var(--pg-grey-darker);
        color: var(--pg-white);
      }
    }

    .entire-unit-checkbox,
    .room-checkbox {
      &.form-check .form-check-label {
        @include typography.hui-body-default-bold;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .bedroom-filter-root {
    .filter-root {
      .dropdown-menu {
        max-width: 344px;

        .bedroom-select-body {
          justify-content: space-between;

          .bedroom-item {
            padding-right: 0;
          }
        }
      }
    }
  }
}

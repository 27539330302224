@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.otp-verification-modal {
  .resend-otp-btn {
    margin-top: 15px;
  }
}

@include media-breakpoint-up(md) {
  .otp-verification-modal {
    &.hui-modal-root {
      .modal-dialog {
        height: auto;

        .modal-body {
          padding-bottom: 40px;
        }
      }
    }
  }
}

.hui-image-root {
  position: relative;
  display: block;
  width: 100%;

  .hui-spinner-root {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    margin: auto;
  }

  // for card's image standardised style
  &.hui-card-image {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 4 / 3;
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.location-filter-modal-root {
  .modal-dialog {
    height: 100%;
    width: 100%;
    .modal-content {
      height: 100%;
      .modal-header {
        justify-content: space-between;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .location-filter-modal-root {
    .modal-dialog {
      height: 90%;
    }
    &.singleLevel {
      .modal-dialog {
        max-width: 926px;
      }
    }
    &.multiLevel {
      .modal-dialog {
        max-width: 736px;
      }
    }
  }
}

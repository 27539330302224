@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.agent-past-transactions-summary-root {
  color: var(--pg-black);
  background-color: var(--pg-grey-lighter);
  padding: 24px;
  border-radius: 8px;

  .divider-root {
    height: unset;
    margin: 24px 0;
  }

  .agent-past-transactions-summary {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;

    &-total,
    &-text,
    &-disclaimer {
      margin: 0;
    }

    &-total {
      @include typography.hui-heading2;
    }

    &-text {
      @include typography.hui-heading4;
    }

    .actionable {
      color: var(--pg-brand-primary);
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6px;

      &:hover,
      &:active,
      &:focus {
        color: var(--pg-brand-primary);
      }
    }

    &-disclaimer {
      @include typography.hui-body-default-regular;
      color: var(--pg-grey-darker);
    }
  }
}

@include media-breakpoint-up(md) {
  .agent-past-transactions-summary-root {
    .agent-past-transactions-summary {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.floor-area-range-filter-root {
  // Override the default width of the dropdown menu because floor area size is a bit longer than price range
  &.range-filter-root {
    .filter-root {
      .dropdown-menu {
        max-width: 100vw;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .floor-area-range-filter-root {
    &.range-filter-root {
      .filter-root {
        .dropdown-menu {
          max-width: 400px;
        }
      }
    }
  }
}

@use 'root/styles/core/typography-mixins.scss' as typography;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'root/styles/core/default-theme';

.agent-rating-root {
  @include typography.hui-heading5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
  border: 1px solid var(--pg-grey-medium);
  background-color: var(--pg-white);
  border-radius: 16px;
  text-decoration: none;

  &.disabled-view {
    .pgicon-star,
    .agent-rating-score,
    .agent-rating-text {
      color: var(--pg-grey-dark);
    }
  }

  .pgicon-star {
    font-size: 12px;
    line-height: 12px;
    color: var(--pg-gold);
    margin-right: $pg-grid-space-unit;

    &::before {
      margin: 0;
    }
  }

  .agent-rating-score {
    margin-right: $pg-grid-space-unit;
    color: var(--pg-black);
  }

  .agent-rating-text {
    color: var(--pg-brand-primary);
  }

  .pgicon-arrow-right {
    font-size: 16px;
    line-height: 16px;
    color: var(--text-active-primary);

    &::before {
      margin: 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .agent-rating-root {
    padding: $pg-grid-space-unit $pg-grid-space-unit-2x;
  }
}

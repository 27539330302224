@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.pg-login-modal-root {
  .modal-body-content {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@include media-breakpoint-up(md) {
  .pg-login-modal-root {
    .modal-body-content {
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      grid-gap: var(--pg-grid-space-unit-4x);
    }
  }
}
